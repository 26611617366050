import { useMutation } from "@apollo/client";
import React, { FormEvent, useState } from "react";

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

import {
  ImportConversions,
  ImportConversionsVariables
} from "../../generated/ImportConversions";
import ImportConversionsMutation from "../../mutations/ImportConversionsMutation";
import CsvDropzone from "../CsvDropzone";

const CsvForm = () => {
  const classes = useStyles();

  const [csvFile, setCsvFile] = useState<File | undefined>();
  const [override, setOverride] = useState(false);
  const [date, setDate] = useState<string>();
  const [uploadComplete, setUploadComplete] = useState(false);

  const [
    importConversions,
    { loading: loadingImportConversions }
  ] = useMutation<ImportConversions, ImportConversionsVariables>(
    ImportConversionsMutation,
    { onCompleted: () => setUploadComplete(true) }
  );

  const resetOverride = () => {
    setOverride(false);
    setDate(undefined);
    setUploadComplete(false);
  };

  const onDropCsvFile = (file: File | undefined) => {
    resetOverride();
    setCsvFile(file);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables = {
      csvFile,
      date: date || new Date().toISOString(),
      override
    };

    importConversions({
      update: (cache, data) => {
        data.data?.importConversions?.clicksUpdated?.map(click =>
          cache.evict({ id: `Click:${click.id}` })
        );
      },
      variables
    });
  };

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.header} variant="h5">
        Import CSV
      </Typography>

      <form className={classes.form} onSubmit={onSubmit}>
        <FormControl>
          <CsvDropzone
            complete={uploadComplete}
            disabled={loadingImportConversions}
            fileName={csvFile?.name}
            onDropCsvFile={onDropCsvFile}
          />
        </FormControl>

        <div>
          <FormControl>
            <InputLabel htmlFor="date" shrink={true}>
              Date
            </InputLabel>
            <Input
              id="date"
              onChange={event => setDate(event.target.value)}
              type="date"
              value={date || ""}
            />
          </FormControl>
        </div>

        <div className={classes.override}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={override}
                onChange={event => setOverride(event.target.checked)}
              />
            }
            label="Override"
          />
        </div>

        <Button
          className={classes.button}
          disabled={
            !csvFile ||
            loadingImportConversions ||
            uploadComplete
          }
          type="submit"
          variant="contained"
        >
          Submit
        </Button>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    width: "fit-content"
  },
  checkbox: {
    marginRight: spacing(3)
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "fit-content"
  },
  header: {
    alignItems: "center",
    display: "flex",
    paddingBottom: spacing()
  },
  input: {
    paddingBottom: spacing(2)
  },
  override: {
    display: "flex",
    paddingBottom: spacing(3),
    paddingTop: spacing(2)
  },
  paper: {
    padding: spacing(3)
  }
}));

export default CsvForm;
