import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Creatives as ICreativesQuery,
  Creatives_creatives
} from "../../generated/Creatives";
import CreativesQuery from "../../queries/CreativesQuery";

import CreativeList from "../../components/CreativeList";
import { Offer_offer_offer_brand } from "../../generated/Offer";
import { DestinationLinkOffer } from "../../utils/destinationLinkPreview/types";

export interface ICreativeListContainerProps {
  offer: DestinationLinkOffer;
  brand: Offer_offer_offer_brand;
}

const CreativeListContainer = ({
  offer,
  brand
}: ICreativeListContainerProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);
  const offerId = offer.id;
  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;
  const { loading, error, data } = useQuery<ICreativesQuery>(CreativesQuery, {
    variables: { after, before, offerId }
  });

  const creativesData = (() => {
    if (data && data.creatives) {
      return data.creatives;
    } else {
      const defaultData: Creatives_creatives = {
        __typename: "CreativesConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = creativesData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = creativesData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <CreativeList
        creatives={creativesData.edges.map(edge => edge.node)}
        hasNextPage={creativesData.pageInfo.hasNextPage}
        hasPreviousPage={creativesData.pageInfo.hasPreviousPage}
        brand={brand}
        offer={offer}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  }
}));

export default CreativeListContainer;
