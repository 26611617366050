import { IGlobalStateElement } from "./MonthlyPaymentsListContainer";

export const updateGlobal = (
  dict: Map<string, IGlobalStateElement>,
  key: string,
  value: IGlobalStateElement
) => {
  let current = dict.get(key);
  if (current) {
    for (const element in value) {
      if ({}.hasOwnProperty.call(value, element)) {
        current[element] = value[element];
      }
    }
    return new Map(dict.set(key, current));
  }
  current = value;
  return new Map(dict.set(key, current));
};
