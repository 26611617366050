export class DateTransformer {
  private static months = [
    { name: "January", number: "01" },
    { name: "February", number: "02" },
    { name: "March", number: "03" },
    { name: "April", number: "04" },
    { name: "May", number: "05" },
    { name: "June", number: "06" },
    { name: "July", number: "07" },
    { name: "August", number: "08" },
    { name: "September", number: "09" },
    { name: "October", number: "10" },
    { name: "November", number: "11" },
    { name: "December", number: "12" }
  ];
  private readonly optionsCount: number;
  private readonly currentMonth: number;
  private readonly year: number;
  constructor(monthsCount: number, month: number, year: number) {
    this.optionsCount = monthsCount;
    this.currentMonth = month;
    this.year = year;
  }
  public getDateOptions() {
    let monthsCount = this.optionsCount;
    let currentMonth = this.currentMonth;
    let year = this.year;
    const options = [];
    while (monthsCount >= 0) {
      if (currentMonth < 0) {
        currentMonth = DateTransformer.months.length - 1;
        year -= 1;
      }
      options.push(`${DateTransformer.months[currentMonth].name}-${year}`);
      currentMonth--;
      monthsCount--;
    }
    return options;
  }
  public getDate(month: string) {
    const [monthName, year] = month.split("-");
    return `${year}-${
      DateTransformer.months.find(
        staticMonth => staticMonth.name === monthName
      )!.number
    }-01`;
  }
}
