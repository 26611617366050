import { gql } from "@apollo/client";

const ReactivateSubagreementMutation = gql`
  mutation ReactivateSubagreement($subagreementId: ID!) {
    reactivateSubagreement(subagreementId: $subagreementId) {
      subagreement {
        status
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default ReactivateSubagreementMutation;
