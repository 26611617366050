import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { AccountPool_accountPool_accountPool_variables } from "../../generated/AccountPool";
import {
  Accounts as IAccountsQuery,
  Accounts_accounts
} from "../../generated/Accounts";
import AccountsQuery from "../../queries/AccountsQuery";

import AccountForm from "../AccountForm";
import AccountListItem from "../AccountListItem";

export interface IAccountListProps {
  accountPoolId: number;
  accountPoolName: string;
  accountPoolVariables: AccountPool_accountPool_accountPool_variables[];
}

const AccountList = ({
  accountPoolId,
  accountPoolName,
  accountPoolVariables
}: IAccountListProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const [accountCreateOpen, setAccountCreateOpen] = useState(false);

  const { loading, error, data } = useQuery<IAccountsQuery>(AccountsQuery, {
    variables: { accountPoolId, after, before }
  });

  const accountsData = (() => {
    if (data && data.accounts) {
      return data.accounts;
    } else {
      const defaultData: Accounts_accounts = {
        __typename: "AccountsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = accountsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = accountsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}

      <div className={classes.header}>
        <Typography variant="h5">Accounts</Typography>

        <Button
          color="primary"
          onClick={() => setAccountCreateOpen(true)}
          variant="contained"
        >
          <AddIcon className={classes.buttonIcon} />
          Account
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Username</TableCell>
            <TableCell>Assigned User</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {accountsData.edges.map(edge => (
            <AccountListItem
              accountPoolId={accountPoolId}
              accountPoolName={accountPoolName}
              accountPoolVariables={accountPoolVariables}
              id={edge.node.id}
              key={edge.node.id}
              user={{ displayId: edge.node.user.displayId, id: edge.node.user.id, name: edge.node.user.name }}
              username={edge.node.username}
              variables={edge.node.variables}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button
                    disabled={!accountsData.pageInfo.hasPreviousPage}
                    onClick={onPreviousPage}
                  >
                    {"<"}
                  </Button>
                  <Button
                    disabled={!accountsData.pageInfo.hasNextPage}
                    onClick={onNextPage}
                  >
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <AccountForm
        accountPoolId={accountPoolId}
        accountPoolName={accountPoolName}
        accountPoolVariables={accountPoolVariables}
        onClose={() => setAccountCreateOpen(false)}
        open={accountCreateOpen}
        type="create"
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  breadcrumb: {
    cursor: "pointer",
    paddingBottom: spacing()
  },
  buttonIcon: {
    marginRight: spacing()
  },
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing()
  },
  paginationTableCell: {
    padding: 0
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  tableRow: {
    cursor: "pointer"
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default AccountList;
