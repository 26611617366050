import { gql } from "@apollo/client";

const AdvertiserQuery = gql`
  query Advertiser($advertiserId: Int!) {
    advertiser(advertiserId: $advertiserId) {
      error {
        errorType
      }
      advertiser {
        brands {
          id
          logoBanner
          logoIcon
          name
          trackingLink
        }
        email
        id
        name
        phoneNumber
      }
    }
  }
`;

export default AdvertiserQuery;
