import React, { useEffect, useRef, useState } from "react";

import {
  Backdrop,
  CircularProgress,
  makeStyles,
  Paper,
  Popper,
  Typography
} from "@material-ui/core";

interface IProgressAlert {
  message?: string;
  open: boolean;
}

const ProgressAlert = ({ message, open }: IProgressAlert) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const circularProgressRef = useRef(null);

  useEffect(() => {
    if (circularProgressRef) {
      setAnchorEl(circularProgressRef.current);
    }
  }, [circularProgressRef]);

  return (
    <Backdrop className={`${classes.backdrop} ${classes.zIndex}`} open={open}>
      <CircularProgress ref={circularProgressRef} />
      <Popper className={classes.zIndex} open={!!message} anchorEl={anchorEl}>
        <Paper className={classes.paper}>
          <Typography>{message}</Typography>
        </Paper>
      </Popper>
    </Backdrop>
  );
};

const useStyles = makeStyles(({ spacing, zIndex }) => ({
  backdrop: {
    color: "#fff"
  },
  paper: {
    padding: spacing(2)
  },
  zIndex: {
    zIndex: zIndex.modal + 1
  }
}));

export default ProgressAlert;
