import { gql } from "@apollo/client";

const ApproveUser = gql`
  mutation ApproveUser($userId: String!) {
    approveUser(userId: $userId) {
      user {
        approved
        id
      }
    }
  }
`;

export default ApproveUser;
