import React, { ChangeEvent, useState } from "react";

import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@material-ui/core";

const SmartPixelGenerator = () => {
  const classes = useStyles();

  const [actionType, setActionType] = useState<string>();
  const [includePlayerId, setIncludePlayerId] = useState(false);
  const [method, setMethod] = useState<string>();

  const handleSelect = (setFunction: (value?: string) => void) => (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    setFunction(event.target.value as string);
  };

  let smartPixelUrl = `${process.env.REACT_APP_TRACKING_URI}/convert/<CLICK_ID>?`;
  if (actionType) {
    smartPixelUrl += `type=${actionType}`;

    if (actionType === "deposit") {
      smartPixelUrl += "&amount=<AMOUNT>&currency=<CURRENCY>";
    }
  }
  if (method) {
    smartPixelUrl += `&method=${method}`;
  }
  if (includePlayerId) {
    smartPixelUrl += "&player_id=<PLAYER_ID>";
  }

  const smartPixel = (() => {
    switch (method) {
      case "image":
        return `<img src="${smartPixelUrl}" width="1" height="1" border="0" />`;
      case "iframe":
        return `<iframe src="${smartPixelUrl}" width="1" height="1" frameborder="0" />`;
      default:
        return smartPixelUrl;
    }
  })();

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Smart Pixel Generator</Typography>
      </div>

      <div className={classes.form}>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="method-select-label">Method</InputLabel>
          <Select
            id="method-select"
            labelId="method-select-label"
            onChange={handleSelect(setMethod)}
            value={method || ""}
          >
            <MenuItem value="postback">Server to Server / Postback</MenuItem>
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="iframe">iFrame</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id="action-type-select-label">Action Type</InputLabel>
          <Select
            id="action-type-select"
            labelId="action-type-select-label"
            onChange={handleSelect(setActionType)}
            value={actionType || ""}
          >
            <MenuItem value="install">Install</MenuItem>
            <MenuItem value="registration">Registration</MenuItem>
            <MenuItem value="deposit">Deposit</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={includePlayerId}
                onChange={() => setIncludePlayerId(!includePlayerId)}
              />
            }
            label="Include Player ID"
          />
        </FormControl>
      </div>

      <Divider />

      <div className={classes.subHeader}>
        <Typography variant="h6">Generated Code:</Typography>
      </div>
      <Typography variant="subtitle2">{smartPixel}</Typography>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  form: {
    display: "flex",
    flexDirection: "column"
  },
  formControl: { paddingBottom: spacing(2) },
  header: { paddingBottom: spacing() },
  paper: { padding: spacing(3) },
  subHeader: {
    paddingBottom: spacing(),
    paddingTop: spacing()
  }
}));

export default SmartPixelGenerator;
