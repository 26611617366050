import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface ISidenavSubId {
  navOpen: boolean;
  userType: "admin" | "partner";
}

const SidenavSubId = ({ navOpen, userType }: ISidenavSubId) => {
  const classes = useStyles();

  const location = useLocation();

  const [subIdOpen, setSubIdOpen] = useState(
    location.pathname.includes(`${userType}-subid`)
  );

  const renderPopoverMenu = () => (
    <>
      <MenuItem>
        <ListItemText primary="SubID" />
      </MenuItem>

      <MenuItem
        button
        className={classes.nestedListItem}
        component={Link}
        selected={location.pathname.includes(`/reports/${userType}-subid`)}
        to={`/reports/${userType}-subid`}
      >
        <ListItemText primary="Individual" />
      </MenuItem>
      <MenuItem
        button
        className={classes.nestedListItem}
        component={Link}
        selected={location.pathname.includes(
          `/reports/${userType}-subid-dropdown`
        )}
        to={`/reports/${userType}-subid-dropdown`}
      >
        <ListItemText primary="Drilldown" />
      </MenuItem>
    </>
  );

  return navOpen ? (
    <>
      <ListItem
        button
        className={classes.nestedListItem}
        id={`SUBIDS-${userType}`}
        onClick={() => navOpen && setSubIdOpen(!subIdOpen)}
      >
        {!navOpen && (
          <ListItemIcon>
            <ChevronRightIcon />
          </ListItemIcon>
        )}
        <ListItemText primary="SubID" />
        {subIdOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>

      <Collapse in={navOpen && subIdOpen}>
        <List component="div" disablePadding>
          <ListItem
            button
            className={classes.subNestedListItem}
            component={Link}
            selected={location.pathname.includes(`/reports/${userType}-subid`)}
            to={`/reports/${userType}-subid`}
          >
            <ListItemText primary="Individual" />
          </ListItem>
          <ListItem
            button
            className={classes.subNestedListItem}
            component={Link}
            selected={location.pathname.includes(
              `/reports/${userType}-subid-dropdown`
            )}
            to={`/reports/${userType}-subid-dropdown`}
          >
            <ListItemText primary="Drilldown" />
          </ListItem>
        </List>
      </Collapse>
    </>
  ) : (
    renderPopoverMenu()
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  nestedListItem: {
    paddingLeft: spacing(4)
  },
  subNestedListItem: {
    paddingLeft: spacing(8)
  }
}));

export default SidenavSubId;
