import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  makeStyles
} from "@material-ui/core";

import { BulkQueryExportData as IBulkQueryExportData } from "../../generated/BulkUserExport";
import BulkUserExport from "../../queries/BulkUserExportQuery";
import ConfirmationAlert from "../ConfirmationAlert";

interface IBulkUserExportProps {
  onClose: () => void;
  open: boolean;
}

const BulkUserExportModal = ({ onClose, open }: IBulkUserExportProps) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [linkState, setLink] = useState<string | undefined>();

  const [getBulkJob, { data: jobData, loading }] = useLazyQuery<
    IBulkQueryExportData
  >(BulkUserExport, {
    onCompleted: () => {
      setLink(jobData?.bulkUserExport?.downloadLink || undefined);
    }
  });

  const handleClose = () => {
    if (loading) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };
  const onCloseReset = () => {
    onClose();
  };

  useEffect(() => {
    if (open && !loading && !linkState) {
      getBulkJob();
    }
  }, [open, loading, linkState, getBulkJob]);

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <div className={`${classes.dialogContainer} ${classes.column}`}>
          <DialogTitle>User Export Job</DialogTitle>
          <DialogContent
            className={`${classes.centeredContent} ${classes.dialogContainer}`}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={`${classes.dialogBox}`}
            >
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  {linkState ? (
                    <Button color="primary">
                      <Link href={linkState} target="_blank">
                        Download
                      </Link>
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      onClick={() => {
                        setLink("");
                        handleClose();
                      }}
                    >
                      Something went wrong...
                      <br />
                      Refresh and try again.
                      <br />
                      Close
                    </Button>
                  )}
                </div>
              )}
            </Box>
          </DialogContent>
        </div>
      </Dialog>
      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Job in Progress"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  centeredContent: {
    display: "flex",
    margin: "auto"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  dialogBox: {
    paddingBottom: spacing(2)
  },
  dialogContainer: {
    padding: spacing(2)
  }
}));

export default BulkUserExportModal;
