import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce/lib";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Offers as IOffersQuery,
  Offers_offers,
  Offers_offers_edges_node_brand
} from "../../generated/Offers";
import { PartnerViewId as IPartnerViewId } from "../../generated/PartnerViewId";
import OffersQuery from "../../queries/OffersQuery";
import PartnerIdQuery from "../../queries/PartnerViewIdQuery";

import OfferList from "../../components/OfferList";

interface IOfferListContainerProps {
  brand?: Offers_offers_edges_node_brand;
}

const OfferListContainer = ({ brand }: IOfferListContainerProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { data: partner, loading: partnerLoading } = useQuery<IPartnerViewId>(
    PartnerIdQuery
  );

  const { loading, error, data, refetch } = useQuery<IOffersQuery>(
    OffersQuery,
    {
      skip: partnerLoading,
      variables: {
        after,
        before,
        brandId: brand?.id,
        userId: partner?.partnerViewId
      }
    }
  );

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value?: string) => {
    debouncedRefetch({ after, before, brandId: brand?.id, search: value });
  };

  const offersData = (() => {
    if (data && data.offers) {
      return data.offers;
    } else {
      const defaultData: Offers_offers = {
        __typename: "OffersConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = offersData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = offersData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <OfferList
        brand={brand}
        handleSearch={handleSearch}
        hasNextPage={offersData.pageInfo.hasNextPage}
        hasPreviousPage={offersData.pageInfo.hasPreviousPage}
        offers={offersData.edges.map(edge => edge.node)}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  partnerView: {
    backgroundColor: "#79B9EA",
    color: "white",
    display: "flex",
    justifyContent: "center"
  }
}));

export default OfferListContainer;
