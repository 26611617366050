import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Adjustments as IAdjustmentsQuery,
  Adjustments_adjustments
} from "../../generated/Adjustments";
import AdjustmentsQuery from "../../queries/AdjustmentsQuery";

import AdjustmentList from "../../components/AdjustmentList";

const AdjustmentListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { loading, error, data } = useQuery<IAdjustmentsQuery>(
    AdjustmentsQuery,
    { variables: { after, before } }
  );

  const adjustmentsData = (() => {
    if (data && data.adjustments) {
      return data.adjustments;
    } else {
      const defaultData: Adjustments_adjustments = {
        __typename: "AdjustmentsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = adjustmentsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = adjustmentsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <AdjustmentList
        adjustments={adjustmentsData.edges.map(edge => edge.node)}
        hasNextPage={adjustmentsData.pageInfo.hasNextPage}
        hasPreviousPage={adjustmentsData.pageInfo.hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  }
}));

export default AdjustmentListContainer;
