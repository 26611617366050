import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField
} from "@material-ui/core";

import { CreateOfferVariables } from "../../generated/CreateOffer";
import { UpdateOfferVariables } from "../../generated/UpdateOffer";
import CreateOfferMutation from "../../mutations/CreateOfferMutation";
import UpdateOfferMutation from "../../mutations/UpdateOfferMutation";

import { VerticalType } from "../../generated/globalTypes";

import { Brands_brands_edges_node } from "../../generated/Brands";
import {
  Offers_offers_edges_node,
  Offers_offers_edges_node_brand
} from "../../generated/Offers";

import ConfirmationAlert from "../ConfirmationAlert";
import DestinationLink from "../DestinationLink";
import InputAccountPoolSearch from "../InputAccountPoolSearch";
import InputBrandSearch from "../InputBrandSearch";

import verticalFormatter from "../../utils/verticalFormatter";

interface IOfferFormProps {
  brand?: Offers_offers_edges_node_brand;
  offer?: Offers_offers_edges_node;
  onClose: () => void;
  onExited: () => void;
  open: boolean;
  type: "create" | "update";
}

const OfferForm = ({
  brand,
  offer,
  onClose,
  onExited,
  open,
  type
}: IOfferFormProps) => {
  const emptyStringValue = type === "update" ? null : undefined;

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [accountPoolIdState, setAccountPoolId] = useState(
    offer?.accountPool?.id || emptyStringValue
  );
  const [
    advertiserAccountRequiredState,
    setAdvertiserAccountRequired
  ] = useState(offer?.advertiserAccountRequired || false);
  const [advertiserIdState, setAdvertiserId] = useState(brand?.advertiser.id);
  const [brandIdState, setBrandId] = useState(brand?.id || emptyStringValue);
  const [mobileOptimizedState, setMobileOptimized] = useState(
    offer?.mobileOptimized || false
  );
  const [nameState, setName] = useState(offer?.name || emptyStringValue);
  const [overviewState, setOverview] = useState(
    offer?.overview || emptyStringValue
  );
  const [playerBonusesState, setPlayerBonuses] = useState(
    offer?.playerBonuses || emptyStringValue
  );
  const [termsState, setTerms] = useState(offer?.terms || emptyStringValue);
  const [trackingLinkState, setTrackingLink] = useState(
    offer?.trackingLink || emptyStringValue
  );
  const [trackingLinkAppendState, setTrackingLinkAppend] = useState(
    offer?.trackingLinkAppend || emptyStringValue
  );
  const [verticalState, setVerticals] = useState<VerticalType[]>(
    offer?.verticals || []
  );

  const [brandState, setBrandState] = useState(brand);
  const [visibleState, setVisible] = useState(offer?.visible || false);

  const [upsertBrand, { loading }] = useMutation(
    type === "create" ? CreateOfferMutation : UpdateOfferMutation
  );
  useEffect(() => {
    setAccountPoolId(offer?.accountPool?.id);
    setAdvertiserAccountRequired(offer?.advertiserAccountRequired || false);
    setAdvertiserId(brand?.advertiser.id);
    setBrandId(brand?.id || emptyStringValue);
    setAccountPoolId(offer?.accountPool?.id || emptyStringValue);
    setMobileOptimized(offer?.mobileOptimized || false);
    setName(offer?.name || emptyStringValue);
    setOverview(offer?.overview || emptyStringValue);
    setPlayerBonuses(offer?.playerBonuses || emptyStringValue);
    setTerms(offer?.terms || emptyStringValue);
    setTrackingLink(offer?.trackingLink || emptyStringValue);
    setTrackingLinkAppend(offer?.trackingLinkAppend || emptyStringValue);
    setVerticals(offer?.verticals || []);
    setVisible(offer?.visible || false);
  }, [brand, emptyStringValue, offer, open]);

  const handleBrandSelectSearch = (
    brandSelected: Brands_brands_edges_node | undefined
  ) => {
    setDirty(true);
    setAdvertiserId(brandSelected?.advertiser.id);
    setBrandId(brandSelected?.id);
    setBrandState(brandSelected);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVerticals(event.target.value as VerticalType[]);
  };

  const handleAccountPoolSelectSearch = (
    accountPoolSelectedId: number | undefined
  ) => {
    setDirty(true);
    setAccountPoolId(accountPoolSelectedId);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    let variables: CreateOfferVariables | UpdateOfferVariables;

    if (type === "create") {
      variables = {
        accountPoolId: accountPoolIdState || null,
        advertiserAccountRequired: advertiserAccountRequiredState,
        brandId: brandIdState!,
        mobileOptimized: mobileOptimizedState,
        name: nameState!,
        overview: overviewState,
        playerBonuses: playerBonusesState,
        terms: termsState,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState,
        verticals: verticalState,
        visible: visibleState
      };
    } else {
      variables = {
        accountPoolId: accountPoolIdState || null,
        advertiserAccountRequired: advertiserAccountRequiredState,
        mobileOptimized: mobileOptimizedState,
        name: nameState,
        offerId: offer!.id,
        overview: overviewState,
        playerBonuses: playerBonusesState,
        terms: termsState,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState,
        verticals: verticalState,
        visible: visibleState
      };
    }

    upsertBrand({ refetchQueries: ["Offers"], variables }).then(() => {
      setDirty(false);
      onClose();
    });
  };

  const setInputState = (setFunction: (value?: string | null) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value || emptyStringValue);
  };

  const dialogTitle =
    type === "create" ? "Create Offer" : `Update ${offer?.name}`;
  const dialogText =
    type === "create"
      ? `Create a new offer for a brand. Partners can browse offers and
         drive traffic to them. Tracking links will be sent data on a click
         being tracked.`
      : `Editing information for this offer.`;
  const verticals = Object.values(VerticalType).filter(
    vertical => vertical !== "default"
  );

  return (
    <>
      <Dialog onClose={handleClose} onExited={onExited} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <InputBrandSearch
              className={classes.formControl}
              defaultValue={brand}
              disabled={!!brand || type === "update"}
              label={type === "create" ? "Search for brand..." : "Brand"}
              onSelect={handleBrandSelectSearch}
            />

            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel htmlFor="vertical-id">Vertical</InputLabel>
              <Select
                input={<Input />}
                inputProps={{ id: "vertical-id" }}
                MenuProps={MenuProps}
                multiple
                onChange={handleChange}
                required
                renderValue={selected =>
                  (selected as VerticalType[]).join(", ")
                }
                value={verticalState}
              >
                {type === "create" && <option value="" />}
                {verticals.map(vertical => {
                  const displayName = verticalFormatter(vertical);
                  return (
                    <MenuItem key={vertical} value={vertical}>
                      <Checkbox
                        checked={verticalState.indexOf(vertical) > -1}
                      />
                      <ListItemText primary={displayName} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              className={classes.formControl}
              id="overview"
              fullWidth
              label="Overview"
              multiline
              onChange={setInputState(setOverview)}
              rowsMax="4"
              value={overviewState || ""}
            />

            <TextField
              className={classes.formControl}
              id="player-bonuses"
              fullWidth
              label="Player Bonuses"
              multiline
              onChange={setInputState(setPlayerBonuses)}
              rowsMax="4"
              value={playerBonusesState || ""}
            />

            <TextField
              className={classes.formControl}
              id="terms"
              fullWidth
              label="Terms"
              multiline
              onChange={setInputState(setTerms)}
              rowsMax="4"
              value={termsState || ""}
            />

            <DestinationLink
              trackingLink={trackingLinkState}
              trackingLinkAppend={trackingLinkAppendState}
              type={type}
              brand={type === "create" ? brandState : brand}
              trackingLinkState={trackingLinkState}
              trackingLinkAppendState={trackingLinkAppendState}
              setTrackingLink={setTrackingLink}
              setTrackingLinkAppend={setTrackingLinkAppend}
              setInputState={setInputState}
              onCloseReset={onCloseReset}
            />

            <FormControl className={classes.formControl} fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    checked={advertiserAccountRequiredState}
                    onChange={() =>
                      setAdvertiserAccountRequired(
                        !advertiserAccountRequiredState
                      )
                    }
                  />
                }
                label="Advertiser Account Required"
              />
            </FormControl>

            {advertiserAccountRequiredState && (
              <InputAccountPoolSearch
                advertiserId={advertiserIdState}
                className={classes.formControl}
                defaultValue={offer?.accountPool || undefined}
                disabled={!advertiserIdState}
                label={
                  accountPoolIdState
                    ? "AccountPool"
                    : "Search for account pool..."
                }
                onSelect={handleAccountPoolSelectSearch}
              />
            )}

            <FormControl className={classes.formControl} fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mobileOptimizedState}
                    onChange={() => setMobileOptimized(!mobileOptimizedState)}
                  />
                }
                label="Mobile Optimized"
              />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleState}
                    onChange={() => setVisible(!visibleState)}
                  />
                }
                label="Visible"
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              {type.toUpperCase()}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default OfferForm;
