import { DestinationLinkBrand } from "../../components/DestinationLink/DestinationLink";
import { Offer_offer_offer as IOffer } from "../../generated/Offer";

export default class DestinationLinkPreviewHelper {
  public generatePath(
    field: "trackingLink" | "trackingLinkAppend",
    offer?: Partial<IOffer>,
    brand?: DestinationLinkBrand
  ) {
    let specificPath = "";
    if (!offer) {
      specificPath = `/brands/${brand?.id}/details`;
    } else if (offer) {
      specificPath = offer[field]
        ? `/offers/${offer.id}/details`
        : brand?.[field]
        ? `/brands/${brand?.id}/details`
        : "";
    }
    return specificPath;
  }

  public generateHelpText(
    field: "trackingLink" | "trackingLinkAppend",
    offer?: Partial<IOffer>,
    brand?: DestinationLinkBrand
  ): string {
    let helperText = "";
    if (!offer) {
      helperText = brand?.[field] ? `Brand Default: ${brand?.[field]}` : "";
    } else if (offer) {
      helperText = offer[field]
        ? `Offer Default: ${offer[field]}`
        : brand?.[field]
        ? `Brand Default: ${brand?.[field]}`
        : "";
    }
    return helperText;
  }

  public generateDestinationLink(
    trackingLink?: string | null,
    trackingLinkAppend?: string | null,
    offer?: Partial<IOffer>,
    brand?: DestinationLinkBrand
  ): string {
    const link =
      trackingLink ||
      offer?.trackingLink ||
      offer?.brand?.trackingLink ||
      brand?.trackingLink;
    if (link) {
      const append =
        trackingLinkAppend ||
        offer?.trackingLinkAppend ||
        offer?.brand?.trackingLinkAppend ||
        brand?.trackingLinkAppend;
      return link + (append ? append : "");
    }
    return "";
  }
}
