const getCurrencySymbol = (currency: string) => {
  switch (currency) {
    case "BTC":
      return "₿";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "USD":
      return "$";
  }
};

export default getCurrencySymbol;
