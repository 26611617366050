import { useQuery } from "@apollo/client";
import React, { KeyboardEvent, useState } from "react";

import { LinearProgress, makeStyles } from "@material-ui/core";

import { SortOrder, UserSortFields } from "../../generated/globalTypes";
import { Users as IUsersQuery } from "../../generated/Users";
import UsersQuery from "../../queries/UsersQuery";

import UserList from "../../components/UserList";

const UserListContainer = () => {
  const classes = useStyles();

  const [pageApproved, setPageApproved] = useState(0);
  const [pageUnapproved, setPageUnapproved] = useState(0);
  const [search, setSearch] = useState<string>();
  const [tabIndex, setTabIndex] = useState(0);
  const [sortAttribute, setSortAttribute] = useState<UserSortFields>(
    UserSortFields.CREATED_AT
  );
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.ASCENDING);

  const onChangeSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    // On "Enter" key press
    if (event.keyCode === 13) {
      setSearch((event.target as HTMLInputElement).value);
    }
  };

  const onChangeSort = (attribute: UserSortFields, order: SortOrder) => {
    let sortReference = order;

    sortReference =
      sortReference === SortOrder.ASCENDING
        ? SortOrder.DESCENDING
        : SortOrder.ASCENDING;
    setSortAttribute(attribute);
    setSortOrder(sortReference);
  };

  const { loading, error, data } = useQuery<IUsersQuery>(UsersQuery, {
    variables: {
      approved: !tabIndex,
      page: tabIndex ? pageUnapproved : pageApproved,
      search,
      sortAttribute,
      sortOrder
    }
  });

  const userData = (() => {
    if (!loading && data && data.users) {
      return data.users;
    } else {
      return { length: 0, total: 0, users: [] };
    }
  })();

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <UserList
        currentPage={tabIndex ? pageUnapproved : pageApproved}
        onChangePage={tabIndex ? setPageUnapproved : setPageApproved}
        onChangeSearch={onChangeSearch}
        onChangeSort={onChangeSort}
        onChangeTab={setTabIndex}
        onSetSortOrder={setSortOrder}
        sortAttribute={sortAttribute}
        sortOrder={sortOrder}
        tabIndex={tabIndex}
        {...userData}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  }
}));

export default UserListContainer;
