import { VerticalType } from "../generated/globalTypes";

export interface IVerticalMap {
  [key: string]: VerticalType;
}

export const verticalsMap: IVerticalMap = {
  Bingo: VerticalType.BINGO,
  Casino: VerticalType.CASINO,
  Crypto: VerticalType.CRYPTO,
  Esports: VerticalType.ESPORTS,
  Fantasy: VerticalType.FANTASY,
  Forex: VerticalType.FOREX,
  "Horse Racing": VerticalType.HORSE_RACING,
  "Live Dealer": VerticalType.LIVE_DEALER,
  Lottery: VerticalType.LOTTERY,
  Other: VerticalType.OTHER,
  Poker: VerticalType.POKER,
  Slots: VerticalType.SLOTS,
  Sportsbook: VerticalType.SPORTSBOOK,
  Sweepstakes: VerticalType.SWEEPSTAKES,
  "Table Games": VerticalType.TABLE_GAMES,
  Virtual: VerticalType.VIRTUAL
};
