import React from "react";

import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";

import { Offer_offer_offer_defaultAgreement_qualifications } from "../../generated/Offer";

const capitalize = (qualificationType: string) => {
  return (
    qualificationType.charAt(0).toUpperCase() +
    qualificationType.slice(1).toLowerCase()
  );
};

export interface IAgreementQualificationsCard {
  qualifications: Offer_offer_offer_defaultAgreement_qualifications[];
}

const AgreementQualificationsCard = ({
  qualifications
}: IAgreementQualificationsCard) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        Qualifications
      </Typography>
      <Table>
        <TableBody>
          {qualifications.length ? (
            qualifications.map((qualification, index) => {
              const qualificationTypeArray = qualification.qualificationType.split(
                "_"
              );
              return (
                <TableRow key={index}>
                  <TableCell>
                    <strong>{`${capitalize(
                      qualificationTypeArray[0]
                    )} ${capitalize(qualificationTypeArray[1])} ${
                      qualificationTypeArray[2]
                        ? capitalize(qualificationTypeArray[2])
                        : ""
                    }`}</strong>
                  </TableCell>
                  <TableCell>{qualification.amount}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell align={"center"}>N/A</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: spacing(),
    padding: spacing(2)
  }
}));

export default AgreementQualificationsCard;
