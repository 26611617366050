import React from "react";
import { IntercomProvider } from "react-use-intercom";

import { ApolloProvider } from "../../utils/apolloProvider";
import { Auth0Provider } from "../../utils/auth0Provider";

import Layout from "../Layout";

const App = () => {
  return (
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirect_uri={window.location.origin}
    >
      <ApolloProvider>
        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID!}>
          <Layout />
        </IntercomProvider>
      </ApolloProvider>
    </Auth0Provider>
  );
};

export default App;
