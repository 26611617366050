import { gql } from "@apollo/client";

const UpdateAccountPoolMutation = gql`
  mutation UpdateAccountPool(
    $accountPoolId: Int!
    $description: String
    $name: String
    $variables: [CreateAccountPoolVariableInput!]
  ) {
    updateAccountPool(
      accountPoolId: $accountPoolId
      input: { description: $description, name: $name, variables: $variables }
    ) {
      accountPool {
        description
        id
        name
        variables {
          name
          description
        }
      }
    }
  }
`;

export default UpdateAccountPoolMutation;
