import { format } from "date-fns";

// Converts an ISO datetime string to the current timezone for our date pickers
export const muiDatetimeFormatter = (datetime?: string | null) => {
  const convertedDatetime = datetime ? new Date(datetime) : null;
  const formattedDatetime = convertedDatetime
    ? format(convertedDatetime, "yyyy-MM-dd'T'HH:mm")
    : null;
  return formattedDatetime;
};
