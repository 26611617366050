import { gql } from "@apollo/client";

const CreateAdjustmentMutation = gql`
  mutation CreateAdjustment(
    $adjustmentAt: DateTime!
    $amount: Float!
    $creatorId: ID!
    $currency: Currency!
    $note: String
    $partnerId: ID!
    $subagreementId: ID
  ) {
    createAdjustment(
      input: {
        adjustmentAt: $adjustmentAt
        amount: $amount
        creatorId: $creatorId
        currency: $currency
        note: $note
        partnerId: $partnerId
        subagreementId: $subagreementId
      }
    ) {
      adjustment {
        id
        adjustmentAt
        amount
        creator {
          id
          displayId
          name
        }
        currency
        note
        partner {
          id
          displayId
          name
        }
        subagreement {
          id
          offer {
            id
            name
          }
        }
      }
    }
  }
`;

export default CreateAdjustmentMutation;
