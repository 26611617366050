import { gql } from "@apollo/client";

const WebhooksQuery = gql`
  query Webhooks($after: ID, $before: ID, $limit: Int = 50, $offerId: ID) {
    webhooks(after: $after, before: $before, limit: $limit, offerId: $offerId) {
      edges {
        node {
          id
          actionType
          httpBody
          httpMethod
          url
          user {
            displayId
            id
            name
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

export default WebhooksQuery;
