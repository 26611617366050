import { gql } from "@apollo/client";

const UpdateReferrerMutation = gql`
  mutation UpdateReferrer(
    $referrerEarningsPercentage: Int
    $referrerId: ID
    $referrerRevsharePercentage: Int
    $userId: String!
  ) {
    updateReferrer(
      input: {
        referrerEarningsPercentage: $referrerEarningsPercentage
        referrerId: $referrerId
        referrerRevsharePercentage: $referrerRevsharePercentage
      }
      userId: $userId
    ) {
      user {
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default UpdateReferrerMutation;
