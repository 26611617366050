import { useQuery } from "@apollo/client";
import React, { useState } from "react";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Subagreements as ISubagreementsQuery,
  Subagreements_subagreements
} from "../../generated/Subagreements";
import SubagreementsQuery from "../../queries/SubagreementsQuery";

import SubagreementList from "../../components/SubagreementList";
import { Offer_offer_offer_defaultAgreement } from "../../generated/Offer";
import { PartnerViewId as IPartnerViewId } from "../../generated/PartnerViewId";
import PartnerViewIdQuery from "../../queries/PartnerViewIdQuery";
import { IVerticalMap } from "../../utils/verticalsMap";

export interface ISubagreementListContainerProps {
  accountPoolId?: number;
  defaultAgreement: Offer_offer_offer_defaultAgreement;
  defaultAgreementId: number;
  offerActive: boolean;
  offerId: number;
  verticals: IVerticalMap;
}

const SubagreementListContainer = ({
  accountPoolId,
  defaultAgreement,
  defaultAgreementId,
  offerActive,
  offerId,
  verticals
}: ISubagreementListContainerProps) => {
  const classes = useStyles();

  const [after, setAfter] = useState<string>();
  const [before, setBefore] = useState<string>();

  const { data: partner, loading: partnerLoading } = useQuery<IPartnerViewId>(
    PartnerViewIdQuery
  );

  const { loading, error, data, refetch } = useQuery<ISubagreementsQuery>(
    SubagreementsQuery,
    {
      errorPolicy: "all",
      skip: partnerLoading,
      variables: { after, before, offerId, userId: partner?.partnerViewId }
    }
  );

  const subagreementsData = (() => {
    if (data && data.subagreements) {
      return data.subagreements;
    } else {
      const defaultData: Subagreements_subagreements = {
        __typename: "SubagreementsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    setAfter(subagreementsData.pageInfo.endCursor || undefined);
    setBefore(undefined);
  };

  const onPreviousPage = () => {
    setAfter(undefined);
    setBefore(subagreementsData.pageInfo.startCursor || undefined);
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && !data && <div className={classes.errorBar} />}
      <SubagreementList
        accountPoolId={accountPoolId}
        defaultAgreement={defaultAgreement}
        defaultAgreementId={defaultAgreementId}
        hasNextPage={subagreementsData.pageInfo.hasNextPage}
        hasPreviousPage={subagreementsData.pageInfo.hasPreviousPage}
        offerActive={offerActive}
        offerId={offerId}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        refetch={() => refetch({ offerId })}
        subagreements={subagreementsData.edges.map(edge => edge.node)}
        verticals={verticals}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  }
}));

export default SubagreementListContainer;
