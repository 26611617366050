import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { useAuth0 } from "../../utils/auth0Provider";

import { Advertisers_advertisers_edges_node } from "../../generated/Advertisers";
import AdvertiserForm from "../AdvertiserForm";

interface IAdvertiserListProps {
  advertisers: Advertisers_advertisers_edges_node[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const AdvertiserList = ({
  advertisers,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage
}: IAdvertiserListProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { hasPermission } = useAuth0();
  const createAdvertisers = hasPermission("manage:advertisers");

  const [advertiserCreateOpen, setAdvertiserCreateOpen] = useState(false);

  const onAdvertiserClick = (advertiserId: number) => () => {
    history.push(`/advertisers/${advertiserId}`);
  };

  const onAdvertiserCreateClose = () => {
    setAdvertiserCreateOpen(false);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5">Advertisers</Typography>
          {createAdvertisers && (
            <Button
              color="primary"
              onClick={() => setAdvertiserCreateOpen(!advertiserCreateOpen)}
              variant="contained"
            >
              <AddIcon className={classes.buttonIcon} />
              Advertiser
            </Button>
          )}
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellId}>ID</TableCell>
              <TableCell className={classes.cellName}>Name</TableCell>
              <TableCell className={classes.cellEmail}>Email</TableCell>
              <TableCell className={classes.cellPhoneNumber}>
                Phone Number
              </TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {advertisers.map(advertiser => (
              <TableRow
                className={classes.tableRow}
                hover
                key={advertiser.id}
                onClick={onAdvertiserClick(advertiser.id)}
              >
                <TableCell className={classes.cellId}>
                  {advertiser.id}
                </TableCell>
                <TableCell>{advertiser.name}</TableCell>
                <TableCell>{advertiser.email}</TableCell>
                <TableCell>{advertiser.phoneNumber}</TableCell>
                <TableCell className={classes.cellAction}>
                  <Checkbox checked={advertiser.active} disabled />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      {createAdvertisers && (
        <AdvertiserForm
          onClose={onAdvertiserCreateClose}
          open={advertiserCreateOpen}
          type="create"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: {
    marginRight: spacing()
  },
  cellAction: { width: "5%" },
  cellEmail: { width: "30%" },
  cellId: {
    textAlign: "right",
    width: "5%"
  },
  cellName: { width: "30%" },
  cellPhoneNumber: { width: "30%" },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: {
    padding: 0
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  tableRow: {
    cursor: "pointer"
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default AdvertiserList;
