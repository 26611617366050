import { gql } from "@apollo/client";

const UpdatePaymentDetails = gql`
  fragment PaymentDetailsImplementation on PaymentDetailsImplementation {
    payableCurrency
    payeeName
    companyRegistrationNumber
    currency
    paymentMethod
    taxCountry
    vat
    ... on PaymentDetailsBankWire {
      bankName
      bankAccountNumber
      bankAddress1
      bankAddress2
      bankCity
      bankState
      bankCountry
      bankPostalCode
      beneficiaryName
      beneficiaryAddress1
      beneficiaryAddress2
      beneficiaryCity
      beneficiaryState
      beneficiaryCountry
      beneficiaryPostalCode
      routingNumber
      branchCode
      sortCode
      swiftBicCode
      ibanNumber
      reference
    }
    ... on PaymentDetailsBtcTransfer {
      walletAddress
    }
    ... on PaymentDetailsCheck {
      recipientName
      recipientAddress1
      recipientAddress2
      recipientCity
      recipientState
      recipientCountry
      recipientPostalCode
    }
    ... on PaymentDetailsEcoPayz {
      ecoPayzEmail
    }
    ... on PaymentDetailsNeteller {
      netellerId
      netellerEmail
      netellerName
    }
    ... on PaymentDetailsPaypal {
      paypalEmail
      paypalName
    }
    ... on PaymentDetailsPlayerAccount {
      playerAccountHolderName
      playerAccountNumber
    }
    ... on PaymentDetailsSkrill {
      skrillEmail
      skrillAccountNumber
      skrillAccountHolderName
      skrillAddress1
      skrillAddress2
      skrillCity
      skrillState
      skrillCountry
      skrillPostalCode
    }
  }
  mutation UpdatePaymentDetails(
    $userId: String!
    $currency: UserCurrency
    $payeeName: String!
    $paymentMethod: PaymentMethod!
    $accountId: String
    $accountEmail: String
    $accountNumber: String
    $branchCode: String
    $accountAddress1: String
    $accountAddress2: String
    $accountCity: String
    $accountState: String
    $accountCountry: String
    $accountPostalCode: String
    $reference: String
    $routingNumber: String
    $sortCode: String
    $swiftBicCode: String
    $accountHolderName: String
    $accountHolderAddress1: String
    $accountHolderAddress2: String
    $accountHolderCity: String
    $accountHolderState: String
    $accountHolderCountry: String
    $accountHolderPostalCode: String
    $companyRegistrationNumber: String
    $ibanNumber: String
    $taxCountry: String
    $vat: String
  ) {
    updatePaymentDetails(
      input: {
        currency: $currency
        payeeName: $payeeName
        paymentMethod: $paymentMethod
        accountId: $accountId
        accountEmail: $accountEmail
        accountNumber: $accountNumber
        branchCode: $branchCode
        accountAddress1: $accountAddress1
        accountAddress2: $accountAddress2
        accountCity: $accountCity
        accountState: $accountState
        accountCountry: $accountCountry
        accountPostalCode: $accountPostalCode
        reference: $reference
        routingNumber: $routingNumber
        sortCode: $sortCode
        swiftBicCode: $swiftBicCode
        accountHolderName: $accountHolderName
        accountHolderAddress1: $accountHolderAddress1
        accountHolderAddress2: $accountHolderAddress2
        accountHolderCity: $accountHolderCity
        accountHolderState: $accountHolderState
        accountHolderCountry: $accountHolderCountry
        accountHolderPostalCode: $accountHolderPostalCode
        companyRegistrationNumber: $companyRegistrationNumber
        ibanNumber: $ibanNumber
        taxCountry: $taxCountry
        vat: $vat
      }
      userId: $userId
    ) {
      user {
        id
        paymentDetails {
          paymentDetailsImplementation {
            ...PaymentDetailsImplementation
          }
        }
      }
    }
  }
`;

export default UpdatePaymentDetails;
