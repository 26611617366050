import { gql } from "@apollo/client";

const MonthlyPaymentSummariesQuery = gql`
  query monthlyPaymentSummaries(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $month: DateTime!
    $isExport: Boolean!
    $paymentStatus: MonthlyPaymentStatus
  ) {
    monthlyPaymentSummaries(
      month: $month
      after: $after
      before: $before
      limit: $limit
      isExport: $isExport
      paymentStatus: $paymentStatus
    ) {
      edges {
        node {
          partnerId
          partnerName
          partnerDisplayId
          cpaTotal
          referralCost
          revshareTotal
          adjustmentsTotal
          rolloverTotal
          month
          status
          total
          partnerTotal
          currency
          partnerCurrency
          externalComment
          internalComment
          paymentDetails
          paymentMethod
          updatedById
          updatedAt
          updatedBy {
            name
            displayId
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
    monthlyPaymentSettings(month: $month) {
      monthlyPaymentSetting {
        month
        ringfenceCommissionType
        brandBundle
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default MonthlyPaymentSummariesQuery;
