import { ApolloQueryResult, useMutation } from "@apollo/client";
import clsx from "clsx";
import React, { MouseEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import LogsIcon from "../Icons/Logs";

import { useAuth0 } from "../../utils/auth0Provider";

import { DeactivateSubagreement as IDeactivateSubagreementMutation } from "../../generated/DeactivateSubagreement";
import {
  QualificationType,
  SubagreementStatus
} from "../../generated/globalTypes";
import { Offer_offer_offer_defaultAgreement } from "../../generated/Offer";
import { ReactivateSubagreement as IReactivateSubagreementMutation } from "../../generated/ReactivateSubagreement";
import {
  Subagreements as ISubagreementsQuery,
  Subagreements_subagreements_edges_node
} from "../../generated/Subagreements";
import DeactivateSubagreementMutation from "../../mutations/DeactivateSubagreementMutation";
import ReactivateSubagreementMutation from "../../mutations/ReactivateSubagreementMutation";

import { IVerticalMap } from "../../utils/verticalsMap";
import ConfirmationAlert from "../ConfirmationAlert";
import RevsharePercentage from "../RevsharePercentage";
import SubagreementForm from "../SubagreementForm";
import TiersPopover from "../TiersPopover";
import { DefaultTiers } from "../TiersPopover/TiersPopover";

export interface ISubagreementListProps {
  accountPoolId?: number;
  defaultAgreement: Offer_offer_offer_defaultAgreement;
  defaultAgreementId: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  offerActive: boolean;
  offerId: number;
  onNextPage: () => void;
  onPreviousPage: () => void;
  refetch: () => Promise<ApolloQueryResult<ISubagreementsQuery>>;
  subagreements: Subagreements_subagreements_edges_node[];
  verticals: IVerticalMap;
}

const SubagreementList = ({
  accountPoolId,
  defaultAgreement,
  defaultAgreementId,
  hasNextPage,
  hasPreviousPage,
  offerActive,
  offerId,
  onNextPage,
  onPreviousPage,
  refetch,
  subagreements,
  verticals
}: ISubagreementListProps) => {
  const classes = useStyles();
  const history = useHistory();

  const { hasPermission } = useAuth0();
  const readNetworkDetails = hasPermission("read:agreements:network_details");
  const reactivateSubagreements = hasPermission("manage:subagreements");
  const deactivateSubagreements = hasPermission("manage:subagreements");

  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);
  const [subagreementFormOpen, setSubagreementFormOpen] = useState(false);
  const [subagreementEdit, setSubagreementEdit] = useState<
    Subagreements_subagreements_edges_node
  >();
  const [subagreementUpdateStatus, setSubagreementUpdateStatus] = useState<
    Subagreements_subagreements_edges_node
  >();

  const [deactivateSubagreement, { loading: deactivateLoading }] = useMutation<
    IDeactivateSubagreementMutation
  >(DeactivateSubagreementMutation, { errorPolicy: "all" });

  const [reactivateSubagreement, { loading: reactivateLoading }] = useMutation<
    IReactivateSubagreementMutation
  >(ReactivateSubagreementMutation, { errorPolicy: "all" });

  const onLogsClick = (path: string) => () => {
    history.push(path);
  };

  const onActiveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveConfirmationOpen(true);
  };

  const onConfirmationNegative = () => {
    setActiveConfirmationOpen(false);
    setSubagreementUpdateStatus(undefined);
  };

  const onConfirmationPositive = () => {
    setActiveConfirmationOpen(false);
    subagreementUpdateStatus?.status === SubagreementStatus.ACTIVE
      ? deactivateSubagreement({
          variables: { subagreementId: subagreementUpdateStatus.id }
        })
      : reactivateSubagreement({
          variables: { subagreementId: subagreementUpdateStatus!.id }
        });
  };

  const activeConfirmationPositiveAction =
    subagreementUpdateStatus?.status === SubagreementStatus.ACTIVE
      ? "Deactivate"
      : "Reactivate";
  const activeConfirmationContent = `${activeConfirmationPositiveAction} subagreement for ${subagreementUpdateStatus?.user.name} (${subagreementUpdateStatus?.user.displayId})?`;
  const activeConfirmationTitle = `${activeConfirmationPositiveAction} Subagreement`;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h5">Subagreements</Typography>
        <Button
          color="primary"
          onClick={() => setSubagreementFormOpen(true)}
          variant="contained"
        >
          <AddIcon className={classes.buttonIcon} />
          Subagreement
        </Button>
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Partner ID</TableCell>
            <TableCell>Partner Name</TableCell>
            {readNetworkDetails && (
              <>
                <TableCell />
                <TableCell>Network Type</TableCell>
                <TableCell>Network CPA Action</TableCell>
                <TableCell>Network CPA</TableCell>
                <TableCell>Network RS %</TableCell>
                <TableCell>Network Tiers</TableCell>
              </>
            )}
            <TableCell />
            <TableCell>Partner Type</TableCell>
            <TableCell>Partner CPA Action</TableCell>
            <TableCell>Partner CPA</TableCell>
            <TableCell>Partner RS %</TableCell>
            <TableCell>Partner Spend %</TableCell>
            <TableCell>Partner Tiers</TableCell>
            <TableCell />
            <TableCell>Min Deposit</TableCell>
            <TableCell>Last Updated</TableCell>
            <TableCell />
            <TableCell>Logs</TableCell>
            <TableCell>Active</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {subagreements.map(subagreement => {
            const active = subagreement.status === SubagreementStatus.ACTIVE;
            const affiliateDetails = subagreement.affiliateDetails;
            const networkDetails = subagreement.networkDetails;
            const updatedAtDate = new Date(subagreement.updatedAt);
            const minDepositQualification = subagreement.qualifications.find(
              qualification =>
                qualification.qualificationType ===
                QualificationType.MIN_DEPOSIT
            );

            return (
              <TableRow key={subagreement.id}>
                <TableCell>
                  <div
                    className={clsx(
                      classes.dot,
                      active && offerActive
                        ? classes.dotActive
                        : classes.dotInactive
                    )}
                  />
                </TableCell>

                <TableCell>{subagreement.user.displayId}</TableCell>
                <TableCell>{subagreement.user.name}</TableCell>

                {readNetworkDetails && (
                  <>
                    <TableCell />

                    {/* {subagreement.id === defaultAgreementId ? (
                      <TableCell colSpan={5}>
                        <div className={classes.chipContainer}>
                          <Chip label="Default" />
                        </div>
                      </TableCell>
                    ) : ( */}
                    <>
                      <TableCell className={classes.capitalize}>
                        {networkDetails?.commissionType.toLowerCase()}
                      </TableCell>
                      {networkDetails &&
                      networkDetails.__typename !== "AgreementRevshare" &&
                      networkDetails.__typename !== "AgreementSpend" ? (
                        <>
                          <TableCell className={classes.capitalize}>
                            {networkDetails.actionType.toLowerCase()}
                          </TableCell>
                          <TableCell>
                            {networkDetails.cpaAmount} {networkDetails.currency}
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell />
                          <TableCell />
                        </>
                      )}

                      {networkDetails &&
                      networkDetails.__typename !== "AgreementCpa" &&
                      networkDetails.__typename !== "AgreementSpend" ? (
                        <TableCell>
                          <RevsharePercentage
                            revsharePercentages={
                              networkDetails.revsharePercentages
                            }
                            verticals={verticals}
                          />
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                      {subagreement.networkTiers &&
                      subagreement.networkTiers.length ? (
                        <TableCell>
                          <TiersPopover
                            commissionType={networkDetails?.commissionType!}
                            currency={networkDetails?.currency!}
                            tiers={
                              (subagreement.networkTiers as unknown) as DefaultTiers
                            }
                          />
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                    </>
                    {/* )} */}
                  </>
                )}

                <TableCell />

                {/* {subagreement.id === defaultAgreementId ? (
                  <TableCell colSpan={6}>
                    <div className={classes.chipContainer}>
                      <Chip label="Default" />
                    </div>
                  </TableCell>
                ) : ( */}
                <>
                  <TableCell className={classes.capitalize}>
                    {affiliateDetails.commissionType?.toLowerCase()}
                  </TableCell>

                  {affiliateDetails.__typename !== "AgreementRevshare" &&
                  affiliateDetails.__typename !== "AgreementSpend" ? (
                    <>
                      <TableCell className={classes.capitalize}>
                        {affiliateDetails.actionType.toLowerCase()}
                      </TableCell>
                      <TableCell>
                        {affiliateDetails.cpaAmount} {affiliateDetails.currency}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell />
                      <TableCell />
                    </>
                  )}

                  {affiliateDetails.__typename !== "AgreementCpa" &&
                  affiliateDetails.__typename !== "AgreementSpend" ? (
                    <TableCell>
                      <RevsharePercentage
                        revsharePercentages={
                          affiliateDetails.revsharePercentages
                        }
                        verticals={verticals}
                      />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}

                  {affiliateDetails.__typename === "AgreementSpend" ? (
                    <TableCell>{affiliateDetails.spendPercentage}%</TableCell>
                  ) : (
                    <TableCell />
                  )}
                  {subagreement.partnerTiers.length ? (
                    <TableCell>
                      <TiersPopover
                        commissionType={affiliateDetails?.commissionType!}
                        currency={affiliateDetails?.currency!}
                        tiers={
                          (subagreement.partnerTiers as unknown) as DefaultTiers
                        }
                      />
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                </>
                {/* )} */}

                <TableCell />

                {/* {subagreement.id === defaultAgreementId ? (
                  <TableCell colSpan={1}>
                    <div className={classes.chipContainer}>
                      <Chip label="Default" />
                    </div>
                  </TableCell>
                ) : ( */}
                <TableCell>
                  {minDepositQualification?.amount || "N/A"}
                </TableCell>
                {/* )} */}

                <TableCell>{updatedAtDate.toLocaleDateString()}</TableCell>

                <TableCell>
                  <IconButton
                    onClick={() => {
                      setSubagreementEdit(subagreement);
                      setSubagreementFormOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={onLogsClick(
                      `/offers/${offerId}/subagreements/${subagreement.user.id}`
                    )}
                  >
                    <LogsIcon />
                  </IconButton>
                </TableCell>

                <TableCell>
                  <Checkbox
                    checked={active}
                    disabled={
                      !offerActive ||
                      !(reactivateSubagreements && deactivateSubagreements) ||
                      deactivateLoading ||
                      reactivateLoading
                    }
                    onClick={event => {
                      setSubagreementUpdateStatus(subagreement);
                      onActiveClick(event);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell className={classes.paginationTableCell} colSpan={1000}>
              <Toolbar className={classes.toolbar}>
                <ButtonGroup>
                  <Button disabled={!hasPreviousPage} onClick={onPreviousPage}>
                    {"<"}
                  </Button>
                  <Button disabled={!hasNextPage} onClick={onNextPage}>
                    {">"}
                  </Button>
                </ButtonGroup>
              </Toolbar>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>

      <SubagreementForm
        accountId={subagreementEdit?.account?.id}
        accountPoolId={accountPoolId}
        affiliateDetails={subagreementEdit?.affiliateDetails}
        defaultAgreement={defaultAgreement}
        networkDetails={subagreementEdit?.networkDetails || undefined}
        networkTiers={subagreementEdit?.networkTiers || undefined}
        offerActive={offerActive}
        offerId={offerId}
        onClose={() => setSubagreementFormOpen(false)}
        onExited={() => setSubagreementEdit(undefined)}
        open={subagreementFormOpen}
        partnerTiers={subagreementEdit?.partnerTiers}
        qualifications={subagreementEdit?.qualifications}
        refetch={refetch}
        user={subagreementEdit?.user}
        ringfenceRevshare={subagreementEdit?.ringfenceRevshare}
      />

      <ConfirmationAlert
        content={activeConfirmationContent}
        onNegative={onConfirmationNegative}
        onPositive={onConfirmationPositive}
        open={activeConfirmationOpen}
        positiveAction={activeConfirmationPositiveAction}
        title={activeConfirmationTitle}
      />
    </div>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonIcon: { marginRight: spacing() },
  capitalize: { textTransform: "capitalize" },
  chipContainer: {
    display: "flex",
    justifyContent: "center"
  },
  container: { paddingTop: spacing() },
  dot: {
    borderRadius: 5,
    float: "right",
    height: 10,
    width: 10
  },
  dotActive: { backgroundColor: palette.primary.main },
  dotInactive: { backgroundColor: palette.grey[500] },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default SubagreementList;
