import { useMutation, useQuery } from "@apollo/client";
import React, { FormEvent, useState } from "react";

import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  CreateAdjustment,
  CreateAdjustmentVariables
} from "../../generated/CreateAdjustment";
import { Currency } from "../../generated/globalTypes";
import { UserSubagreements as IUserSubagreementsQuery } from "../../generated/UserSubagreements";
import CreateAdjustmentMutation from "../../mutations/CreateAdjustmentMutation";
import UserSubagreementsQuery from "../../queries/UserSubagreementsQuery";

import { useAuth0 } from "../../utils/auth0Provider";

import ConfirmationAlert from "../ConfirmationAlert";
import InputUserSearch from "../InputUserSearch";

const AdjustmentForm = () => {
  const emptyStringValue = undefined;
  const notOfferSpecific = "Not Offer Specific";

  const classes = useStyles();

  const { user } = useAuth0();

  const [confirmOpen, setConfirmOpen] = useState(false);

  const [partnerId, setPartnerId] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [currencyState, setCurrency] = useState<Currency>();
  const [date, setDate] = useState<string>();
  const [note, setNote] = useState<string>();
  const [subagreementId, setSubagreementId] = useState<string>();

  const { data: subagreementData } = useQuery<IUserSubagreementsQuery>(
    UserSubagreementsQuery,
    {
      skip: !partnerId,
      variables: { userId: partnerId }
    }
  );

  const [createAdjustment, { loading }] = useMutation<
    CreateAdjustment,
    CreateAdjustmentVariables
  >(CreateAdjustmentMutation, { refetchQueries: ["Adjustments"] });

  const reset = () => {
    setConfirmOpen(false);
    setPartnerId(emptyStringValue);
    setAmount(emptyStringValue);
    setCurrency(emptyStringValue);
    setDate(emptyStringValue);
    setNote(emptyStringValue);
    setSubagreementId(emptyStringValue);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setConfirmOpen(true);
  };

  const onSubmit = () => {
    const variables: CreateAdjustmentVariables = {
      adjustmentAt: date,
      amount: amount!,
      creatorId: user!.sub,
      currency: currencyState!,
      note,
      partnerId: partnerId!,
      subagreementId:
        subagreementId === notOfferSpecific || !subagreementId
          ? emptyStringValue
          : subagreementId
    };

    createAdjustment({ variables }).then(async () => {
      reset();
    });
  };

  const toolTips = {
    date:
      "Select the date you would like the adjustment to be applied. You can only adjust unclosed months",
    offer: `Select an offer you would like to connect this adjustment to. If none, select "Not Offer Specific"`
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit}>
        <InputUserSearch
          className={`${classes.formControl} ${classes.formInput}`}
          onSelect={setPartnerId}
        />

        <Tooltip arrow placement="right" title={toolTips.offer}>
          <FormControl
            className={`${classes.formControl} ${classes.formInput}`}
            disabled={!partnerId || loading}
          >
            <InputLabel htmlFor="adjustment-offer">Offer</InputLabel>
            <Select
              inputProps={{ id: "adjustment-offer" }}
              native
              onChange={event =>
                setSubagreementId(
                  (event.target.value as string) || emptyStringValue
                )
              }
              value={subagreementId || ""}
            >
              <option value="" />
              <option>{notOfferSpecific}</option>
              {subagreementData?.subagreements?.edges.map(edge => (
                <option key={edge.node.id} value={edge.node.id}>
                  {`${edge.node.offer.name} (${edge.node.offer.id})`}
                </option>
              ))}
            </Select>
          </FormControl>
        </Tooltip>

        <TextField
          className={`${classes.formControl} ${classes.formInput}`}
          disabled={loading}
          id="adjustment-amount"
          label="Amount"
          onChange={event => setAmount(parseFloat(event.target.value))}
          required
          type="number"
          value={amount === 0 || amount ? amount : ""}
        />

        <FormControl
          className={`${classes.formControl} ${classes.formInput}`}
          disabled={loading}
        >
          <InputLabel>Currency</InputLabel>
          <Select
            inputProps={{ id: "adjustment-currency" }}
            native
            onChange={event => setCurrency(event.target.value as Currency)}
            required
            value={currencyState || ""}
          >
            <option value="" />
            {Object.keys(Currency).map(currency => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>
        </FormControl>

        <Tooltip arrow placement="right" title={toolTips.date}>
          <TextField
            className={`${classes.formControl} ${classes.formInput}`}
            disabled={loading}
            id="adjustment-date"
            InputLabelProps={{ shrink: true }}
            label="Date"
            onChange={event => setDate(event.target.value)}
            required
            type="date"
            value={date || ""}
          />
        </Tooltip>

        <TextField
          className={`${classes.formControl} ${classes.formNote}`}
          disabled={loading}
          fullWidth
          id="adjustment-note"
          label="Notes"
          multiline
          onChange={event => setNote(event.target.value)}
          rows="2"
          value={note || ""}
        />

        <Button
          className={classes.button}
          color="primary"
          disabled={loading}
          type="submit"
          variant="contained"
        >
          Create
        </Button>
      </form>

      <ConfirmationAlert
        content={`Are you sure you want to create an adjustment of ${amount} ${currencyState} to be applied on ${date}?`}
        onNegative={() => setConfirmOpen(false)}
        onPositive={onSubmit}
        open={confirmOpen}
        positiveAction="Create"
        title="Confirm Adjustment"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    display: "flex",
    margin: "auto"
  },
  form: {
    padding: spacing(2, 0, 2, 4),
    width: "fit-content"
  },
  formControl: { paddingBottom: spacing() },
  formInput: {
    display: "flex",
    marginRight: "auto",
    width: 400
  },
  formNote: { width: 700 }
}));

export default AdjustmentForm;
