import {
  ApolloClient,
  ApolloProvider as BaseApolloProvider,
  concat,
  InMemoryCache
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import React, { ReactNode } from "react";

import { useAuth0 } from "./auth0Provider";

interface IApolloProviderProps {
  children: ReactNode;
}

export const ApolloProvider = ({ children }: IApolloProviderProps) => {
  const { getTokenSilently, isAuthenticated } = useAuth0();

  const cache = new InMemoryCache({
    possibleTypes: {
      AgreementDetails: [
        "AgreementClawback",
        "AgreementCpa",
        "AgreementHybrid",
        "AgreementRevshare"
      ]
    },
    typePolicies: {
      MonthlyPayment: {
        keyFields: ["id", "month"]
      }
    }
  });

  const authLink = setContext(async () => ({
    headers: isAuthenticated
      ? {
          "X-ACCESS-TOKEN": await getTokenSilently()
        }
      : undefined
  }));

  const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GATEWAY_URI
  });

  const apolloClient = new ApolloClient({
    cache,
    link: concat(authLink, uploadLink)
  });

  return (
    <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
  );
};
