import { gql } from "@apollo/client";

const CreativesQuery = gql`
  query Creatives($after: ID, $before: ID, $limit: Int! = 50, $offerId: Int!) {
    creatives(
      after: $after
      before: $before
      limit: $limit
      offerId: $offerId
    ) {
      edges {
        cursor
        node {
          active
          id
          imageHeight
          imageUrl
          imageWidth
          languageCode
          name
          trackingLink
          trackingLinkAppend
          visible
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default CreativesQuery;
