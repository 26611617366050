import { useQuery } from "@apollo/client";
import React, { ChangeEvent } from "react";
import { useDebouncedCallback } from "use-debounce";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import {
  AccountPools as IAccountPoolsQuery,
  AccountPools_accountPools_edges_node,
  AccountPoolsVariables
} from "../../generated/AccountPools";
import { Offers_offers_edges_node_accountPool } from "../../generated/Offers";

import AccountPoolsQuery from "../../queries/AccountPoolsQuery";

interface IInputAccountPoolSearch {
  advertiserId?: number;
  className: string;
  defaultValue?: Offers_offers_edges_node_accountPool;
  disabled: boolean;
  label: string;
  onSelect: (id: number | undefined) => void;
}

const InputAccountPoolSearch = ({
  advertiserId,
  className,
  defaultValue,
  disabled,
  label,
  onSelect
}: IInputAccountPoolSearch) => {
  const { data, loading, refetch } = useQuery<
    IAccountPoolsQuery,
    AccountPoolsVariables
  >(AccountPoolsQuery, {
    variables: { advertiserId: advertiserId?.toString(), limit: 50 }
  });

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value: string) => {
    debouncedRefetch({
      advertiserId: advertiserId?.toString(),
      limit: 50,
      search: value || undefined
    });
  };

  const handleSelect = (accountPool?: AccountPools_accountPools_edges_node) => {
    if (accountPool) {
      onSelect(accountPool.id);
    } else {
      onSelect(undefined);
      handleSearch("");
    }
  };

  const options = data?.accountPools?.edges.map(edge => edge.node) || [];

  return (
    <Autocomplete
      className={className}
      defaultValue={defaultValue as AccountPools_accountPools_edges_node}
      disabled={disabled}
      getOptionLabel={option => option.name}
      loading={loading}
      onChange={(
        _: ChangeEvent<{}>,
        accountPool: AccountPools_accountPools_edges_node | null
      ) => handleSelect(accountPool || undefined)}
      onInputChange={(_, search) => handleSearch(search)}
      options={options}
      renderInput={params => <TextField {...params} fullWidth label={label} />}
      noOptionsText={"No account pools available"}
    />
  );
};

export default InputAccountPoolSearch;
