import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import OfferShow from "../../components/OfferShow";
import { Offer as IOfferQuery } from "../../generated/Offer";
import OfferQuery from "../../queries/OfferQuery";
import { verticalsMap as verticals } from "../../utils/verticalsMap";

const OfferShowContainer = () => {
  const { offerId: rawOfferId } = useParams<{ offerId: string }>();
  const offerId = parseInt(rawOfferId as string, 10);

  const classes = useStyles();

  const { data, loading, refetch } = useQuery<IOfferQuery>(OfferQuery, {
    errorPolicy: "all",
    variables: { offerId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  // TODO: Clean this check for the right data up
  if (!data || !data.offer || data.offer.error || !data.offer.offer) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return (
    <OfferShow
      accountPoolId={data.offer.offer.accountPool?.id}
      {...data.offer.offer}
      offerRefetch={() => refetch({ offerId })}
      verticalNames={data.offer.offer.verticals}
      verticals={verticals}
    />
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default OfferShowContainer;
