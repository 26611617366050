import { gql } from "@apollo/client";

const UserSubagreementsQuery = gql`
  query UserSubagreements(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $offerId: Int
    $userId: ID
  ) {
    subagreements(
      after: $after
      before: $before
      limit: $limit
      offerId: $offerId
      userId: $userId
    ) {
      edges {
        cursor
        node {
          id
          offer {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default UserSubagreementsQuery;
