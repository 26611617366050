import { gql } from "@apollo/client";

const DeactivateCreativeMutation = gql`
  mutation DeactivateCreative($creativeId: Int!) {
    deactivateCreative(creativeId: $creativeId) {
      creative {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default DeactivateCreativeMutation;
