import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import AccountPoolShow from "../../components/AccountPoolShow";
import { AccountPool as IAccountPoolQuery } from "../../generated/AccountPool";
import AccountPoolQuery from "../../queries/AccountPoolQuery";

const AccountPoolShowContainer = () => {
  const { accountPoolId: rawAccountPoolId } = useParams<{
    accountPoolId: string;
  }>();
  const accountPoolId = parseInt(rawAccountPoolId as string, 10);

  const classes = useStyles();

  const { loading, error, data } = useQuery<IAccountPoolQuery>(
    AccountPoolQuery,
    { variables: { accountPoolId } }
  );

  if (loading) {
    return <LinearProgress />;
  }

  if (
    error ||
    !data ||
    !data.accountPool ||
    data.accountPool.error ||
    !data.accountPool.accountPool
  ) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return <AccountPoolShow accountPool={data.accountPool.accountPool} />;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default AccountPoolShowContainer;
