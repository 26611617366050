import { gql } from "@apollo/client";

const UpdateWebhook = gql`
  mutation UpdateWebhook(
    $webhookId: ID!
    $actionType: WebhookActionType
    $httpBody: String
    $httpMethod: WebhookHttpMethod
    $url: String
  ) {
    updateWebhook(
      webhookId: $webhookId
      input: { actionType: $actionType, httpBody: $httpBody, httpMethod: $httpMethod, url: $url }
    ) {
      webhook {
        id
        actionType
        url
        user {
          id
        }
        offer {
          id
        }
      }
    }
  }
`;

export default UpdateWebhook;
