import React, { useState } from "react";

import { useMutation } from "@apollo/client";
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ColorPicker from "material-ui-color-picker";

import { AllowedPaymentMethods_allowedPaymentMethods } from "../../generated/AllowedPaymentMethods";
import { Currency } from "../../generated/globalTypes";
import { InstanceSettings_instanceSettings } from "../../generated/InstanceSettings";
import {
  UpdateAllowedPaymentMethod,
  UpdateAllowedPaymentMethodVariables
} from "../../generated/UpdateAllowedPaymentMethod";
import {
  UpdateInstanceSettings,
  UpdateInstanceSettingsVariables
} from "../../generated/UpdateInstanceSettings";
import UpdateAllowedPaymentMethodMutation from "../../mutations/UpdateAllowedPaymentMethodMutation";
import UpdateInstanceSettingsMutation from "../../mutations/UpdateInstanceSettingsMutation";
import Dropzone from "../Dropzone";

export interface IInstanceSettingsList {
  allowedPaymentMethods: AllowedPaymentMethods_allowedPaymentMethods[];
  settings: InstanceSettings_instanceSettings;
}

const InstanceSettingsList = ({
  allowedPaymentMethods,
  settings
}: IInstanceSettingsList) => {
  const classes = useStyles();

  const [displayNameState, setDisplayNameState] = useState(
    settings.displayName
  );
  const [titleState, setTitleState] = useState(settings.title);
  const [paymentCurrencyState, setPaymentCurrencyState] = useState(
    settings.paymentCurrency
  );
  const [themePrimaryColorState, setThemePrimaryColorState] = useState(
    settings.themePrimaryColor
  );
  const [themePrimaryTextColorState, setThemePrimaryTextColorState] = useState(
    settings.themePrimaryTextColor
  );
  const [themeSecondaryColorState, setThemeSecondaryColorState] = useState(
    settings.themeSecondaryColor
  );
  const [
    themeSecondaryTextColorState,
    setThemeSecondaryTextColorState
  ] = useState(settings.themeSecondaryTextColor);

  const [logoState, setLogoState] = useState<string | undefined>();

  const [faviconState, setFaviconState] = useState<string | undefined>();

  const [shouldRefresh, setShouldRefresh] = useState(false);

  const [primaryColorError, setPrimaryColorError] = useState(false);
  const [primaryTextError, setPrimaryTextError] = useState(false);
  const [secondaryColorError, setSecondaryColorError] = useState(false);
  const [secondaryTextError, setSecondaryTextError] = useState(false);

  const [updateInstanceSettings, { loading }] = useMutation<
    UpdateInstanceSettings,
    UpdateInstanceSettingsVariables
  >(UpdateInstanceSettingsMutation);

  const checkRefresh = () => {
    if (shouldRefresh) {
      window.location.reload();
    }
  };

  const fixColorState = (
    colorInput: string,
    setCodeState: React.Dispatch<React.SetStateAction<string>>,
    setErrorState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/;
    if (colorInput.split("(")[0].toLowerCase() === "rgba") {
      if (!rgbaRegex.test(colorInput)) {
        setErrorState(true);
      }
    } else if (colorInput.split("")[0] !== "#") {
      setCodeState("#" + colorInput);
    }
    if (colorInput.split("")[0] === "#" && !hexRegex.test(colorInput)) {
      setErrorState(true);
    }
  };

  const handleSubmit = async () => {
    const variables: UpdateInstanceSettingsVariables = {
      displayName: displayNameState,
      favicon: faviconState,
      logo: logoState,
      paymentCurrency: paymentCurrencyState,
      themePrimaryColor: themePrimaryColorState,
      themePrimaryTextColor: themePrimaryTextColorState,
      themeSecondaryColor: themeSecondaryColorState,
      themeSecondaryTextColor: themeSecondaryTextColorState,
      title: titleState
    };

    if (
      !primaryColorError &&
      !primaryTextError &&
      !secondaryColorError &&
      !secondaryTextError
    ) {
      await updateInstanceSettings({
        awaitRefetchQueries: true,
        refetchQueries: ["InstanceSettings"],
        variables
      }).then(() => checkRefresh());
    }
  };

  const [usdOpen, setUsdOpen] = useState(false);
  const [eurOpen, setEurOpen] = useState(false);
  const [gbpOpen, setGbpOpen] = useState(false);

  const [updateAllowedPaymentMethod] = useMutation<
    UpdateAllowedPaymentMethod,
    UpdateAllowedPaymentMethodVariables
  >(UpdateAllowedPaymentMethodMutation);

  const eurMethods = allowedPaymentMethods
    .filter(method => method.currency === Currency.EUR)
    .sort((a, b) => (a.paymentMethod > b.paymentMethod ? 1 : -1));
  const gbpMethods = allowedPaymentMethods
    .filter(method => method.currency === Currency.GBP)
    .sort((a, b) => (a.paymentMethod > b.paymentMethod ? 1 : -1));
  const usdMethods = allowedPaymentMethods
    .filter(method => method.currency === Currency.USD)
    .sort((a, b) => (a.paymentMethod > b.paymentMethod ? 1 : -1));

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Instance Settings</Typography>

        <Button
          color="primary"
          disabled={loading}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </Button>
      </div>

      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Value</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell>Display Name</TableCell>
            <TableCell>
              <TextField
                onChange={event => setDisplayNameState(event.target.value)}
                value={displayNameState}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Browser Title</TableCell>
            <TableCell>
              <TextField
                onChange={event => setTitleState(event.target.value)}
                value={titleState}
              />
            </TableCell>
          </TableRow>

          {/* TODO: Make this a drop down selection */}
          <TableRow>
            <TableCell>Payment Currency</TableCell>
            <TableCell>
              <Select
                onChange={event =>
                  setPaymentCurrencyState(event.target.value as Currency)
                }
                value={paymentCurrencyState}
              >
                {Object.values(Currency).map(value => {
                  return (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Theme Primary Color</TableCell>
            <TableCell>
              <ColorPicker
                InputProps={{
                  style: { color: "black" },
                  value: themePrimaryColorState
                }}
                onChange={setThemePrimaryColorState}
                value={themePrimaryColorState}
                error={primaryColorError}
                helperText={primaryColorError ? "Invalid Color" : ""}
                onFocus={() => setPrimaryColorError(false)}
                onBlur={() =>
                  fixColorState(
                    themePrimaryColorState,
                    setThemePrimaryColorState,
                    setPrimaryColorError
                  )
                }
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Theme Primary Text Color</TableCell>
            <TableCell>
              <ColorPicker
                InputProps={{
                  style: { color: "black" },
                  value: themePrimaryTextColorState
                }}
                onChange={setThemePrimaryTextColorState}
                value={themePrimaryTextColorState}
                error={primaryTextError}
                helperText={primaryTextError ? "Invalid Color" : ""}
                onFocus={() => setPrimaryTextError(false)}
                onBlur={() =>
                  fixColorState(
                    themePrimaryTextColorState,
                    setThemePrimaryTextColorState,
                    setPrimaryTextError
                  )
                }
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Theme Secondary Color</TableCell>
            <TableCell>
              <ColorPicker
                InputProps={{
                  style: { color: "black" },
                  value: themeSecondaryColorState
                }}
                onChange={setThemeSecondaryColorState}
                value={themeSecondaryColorState}
                error={secondaryColorError}
                helperText={secondaryColorError ? "Invalid Color" : ""}
                onFocus={() => setSecondaryColorError(false)}
                onBlur={() =>
                  fixColorState(
                    themeSecondaryColorState,
                    setThemeSecondaryColorState,
                    setSecondaryColorError
                  )
                }
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Theme Secondary Text Color</TableCell>
            <TableCell>
              <ColorPicker
                InputProps={{
                  style: { color: "black" },
                  value: themeSecondaryTextColorState
                }}
                onChange={setThemeSecondaryTextColorState}
                value={themeSecondaryTextColorState}
                error={secondaryTextError}
                helperText={secondaryTextError ? "Invalid Color" : ""}
                onFocus={() => setSecondaryTextError(false)}
                onBlur={() =>
                  fixColorState(
                    themeSecondaryTextColorState,
                    setThemeSecondaryTextColorState,
                    setSecondaryTextError
                  )
                }
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Logo</TableCell>
            <TableCell>
              <Dropzone
                id="logo-icon"
                onDropBase64={base64 => {
                  setShouldRefresh(true);
                  setLogoState(base64);
                }}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Favicon</TableCell>
            <TableCell>
              <Dropzone
                id="favicon-icon"
                onDropBase64={base64 => {
                  setShouldRefresh(true);
                  setFaviconState(base64);
                }}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>NGR Formula</TableCell>
            <TableCell>
              <TextField
                disabled={true}
                value={settings.ngrFormula || ""}
                multiline
                fullWidth={true}
                rowsMax={Infinity}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <div className={classes.header}>
        <Typography variant="h5">Allowed Payment Options</Typography>
      </div>

      <List>
        <ListItem
          button
          className={classes.lineItemHeader}
          divider
          onClick={() => setUsdOpen(!usdOpen)}
        >
          USD
          {usdOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={usdOpen}>
          <FormGroup className={classes.formGroup}>
            {usdMethods.map(method => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={method.active}
                    onChange={() =>
                      updateAllowedPaymentMethod({
                        variables: {
                          active: !method.active,
                          allowedPaymentMethodId: method.id
                        }
                      })
                    }
                  />
                }
                key={method.id}
                label={method.paymentMethod}
              />
            ))}
          </FormGroup>
        </Collapse>
        <ListItem
          button
          className={classes.lineItemHeader}
          divider
          onClick={() => setEurOpen(!eurOpen)}
        >
          EUR
          {eurOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={eurOpen}>
          <FormGroup className={classes.formGroup}>
            {eurMethods.map(method => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={method.active}
                    onChange={() =>
                      updateAllowedPaymentMethod({
                        variables: {
                          active: !method.active,
                          allowedPaymentMethodId: method.id
                        }
                      })
                    }
                  />
                }
                key={method.id}
                label={method.paymentMethod}
              />
            ))}
          </FormGroup>
        </Collapse>
        <ListItem
          button
          className={classes.lineItemHeader}
          divider
          onClick={() => setGbpOpen(!gbpOpen)}
        >
          GBP
          {gbpOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={gbpOpen}>
          <FormGroup className={classes.formGroup}>
            {gbpMethods.map(method => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={method.active}
                    onChange={() =>
                      updateAllowedPaymentMethod({
                        variables: {
                          active: !method.active,
                          allowedPaymentMethodId: method.id
                        }
                      })
                    }
                  />
                }
                key={method.id}
                label={method.paymentMethod}
              />
            ))}
          </FormGroup>
        </Collapse>
      </List>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formGroup: {
    padding: `0 ${spacing(2)}px`
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  lineItemHeader: {
    justifyContent: "space-between"
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  table: {
    marginBottom: spacing()
  }
}));

export default InstanceSettingsList;
