import { gql } from "@apollo/client";

const UpdateUserMutation = gql`
  mutation UpdateUser(
    $company: String
    $currency: UserCurrency
    $email: String
    $name: String
    $phoneNumber: String
    $roleId: String
    $userId: String!
  ) {
    updateUser(
      input: {
        company: $company
        currency: $currency
        email: $email
        name: $name
        phoneNumber: $phoneNumber
        roleId: $roleId
      }
      userId: $userId
    ) {
      user {
        company
        currency
        email
        id
        name
        phoneNumber
        roles {
          id
          name
        }
      }
      error {
        errorType
      }
    }
  }
`;

export default UpdateUserMutation;
