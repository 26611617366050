import React from "react";

import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";

import { CommissionType, Currency } from "../../generated/globalTypes";
import {
  Offer_offer_offer_defaultAgreement_networkTiers,
  Offer_offer_offer_defaultAgreement_partnerTiers
} from "../../generated/Offer";
import getCurrencySymbol from "../../utils/getCurrencySymbol";

export type DefaultTiers =
  | Offer_offer_offer_defaultAgreement_partnerTiers[]
  | Offer_offer_offer_defaultAgreement_networkTiers[];
export interface ITiers {
  commissionType: CommissionType;
  currency: Currency;
  tiers: DefaultTiers;
}

const Tiers = ({ commissionType, currency, tiers }: ITiers) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const tierLevels: DefaultTiers[] = [];
  tiers.forEach(tier => {
    const tierIndex = tier.level - 1;
    if (!tierLevels[tierIndex]) {
      tierLevels[tierIndex] = [];
    }
    tierLevels[tierIndex].push(tier);
  })

  return (
    <>
      <IconButton
        className={classes.icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <ListIcon />
      </IconButton>

      <Popover
        id="tiers-mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {tierLevels.map((tierLevel, index) => (
          <List dense key={index}>
            <ListItem>
              <ListItemText primary={`Tier ${index + 1}`} />
            </ListItem>

            <ListItem>
              <ListItemText secondary="Thresholds" />
            </ListItem>

            {tierLevel.map(tier => (
              <>
                <ListItem>
                  <ListItemText
                    secondary={`${tier.thresholdType} - ${tier.threshold}`}
                    style={{ marginLeft: 10 }}
                  />
                </ListItem>
              </>
            ))}

            <ListItem>
              <ListItemText secondary="Commissions" />
            </ListItem>

            {commissionType !== CommissionType.REVSHARE && (
              <ListItem>
                <ListItemText
                  secondary={`CPA: ${getCurrencySymbol(currency)}${tierLevel[0].cpa}`}
                  style={{ marginLeft: 10 }}
                />
              </ListItem>
            )}
            {commissionType !== CommissionType.CPA &&
              commissionType !== CommissionType.SPEND && (
                <ListItem>
                  <ListItemText
                    secondary={`revshare: ${tierLevel[0].revsharePercentages.default}`}
                    style={{ marginLeft: 10 }}
                  />
                </ListItem>
            )}
          </List>
        ))}
      </Popover>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    borderRadius: 0,
    margin: spacing(0, 1),
    padding: 0
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  popover: {
    pointerEvents: "none"
  }
}));

export default Tiers;
