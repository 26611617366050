import { gql } from "@apollo/client";

const OfferUsersQuery = gql`
  query OfferUsers($offerId: Int!, $search: String) {
    subagreements(offerId: $offerId, search: $search) {
      edges {
        cursor
        node {
          id
          user {
            displayId
            id
            name
          }
        }
      }
    }
  }
`;

export default OfferUsersQuery;
