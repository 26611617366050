import { gql } from "@apollo/client";

const CreateAdvertiserMutation = gql`
  mutation CreateAdvertiser(
    $email: String
    $name: String!
    $phoneNumber: String
  ) {
    createAdvertiser(
      input: { email: $email, name: $name, phoneNumber: $phoneNumber }
    ) {
      advertiser {
        id
      }
    }
  }
`;

export default CreateAdvertiserMutation;
