import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { Brand_brand_brand as IBrand } from "../../generated/Brand";
import { Offer_offer_offer as IOffer } from "../../generated/Offer";
import { Offer_offer_offer_brand as IOfferBrand } from "../../generated/Offer";
import { Offers_offers_edges_node_brand as IOfferNodeBrand } from "../../generated/Offers";

import DestinationLinkPreviewHelper from "../../utils/destinationLinkPreview/helper";
import DestinationLinkPreview from "../DestinationLinkPreview";

export type DestinationLinkBrand = IBrand | IOfferBrand | IOfferNodeBrand;

interface IDestinationLink {
  trackingLink: string | null | undefined;
  trackingLinkAppend: string | null | undefined;
  type?: string;
  trackingLinkState: any;
  trackingLinkAppendState: any;
  setTrackingLink: any;
  setTrackingLinkAppend: any;
  offer?: Partial<IOffer>;
  brand?: DestinationLinkBrand;
  setInputState: (value?: any) => any;
  onCloseReset?: any;
}

const DestinationLink = ({
  trackingLink,
  trackingLinkAppend,
  setInputState,
  trackingLinkState,
  trackingLinkAppendState,
  setTrackingLink,
  setTrackingLinkAppend,
  onCloseReset,
  offer,
  brand
}: IDestinationLink) => {
  const helper = new DestinationLinkPreviewHelper();
  const classes = useStyles();
  const history = useHistory();
  const changeRoute = (newPath: string, isEnable: boolean) => {
    if (isEnable) {
      onCloseReset();
      history.push(`${newPath}`);
    }
  };
  const helperText = helper.generateHelpText("trackingLink", offer, brand);
  const helperTextAppend = helper.generateHelpText(
    "trackingLinkAppend",
    offer,
    brand
  );
  return (
    <div>
      <TextField
        id="tracking-link"
        fullWidth
        label="Destination Link"
        onChange={setInputState(setTrackingLink)}
        value={trackingLinkState || ""}
      />
      <TextField
        id="default-tracking-link"
        fullWidth
        disabled
        className={classes.formControl}
        InputProps={{
          classes: { input: "MuiFormHelperText-root" },
          disableUnderline: true,
          margin: "none",
          style: { fontSize: 14 }
        }}
        onClick={changeRoute.bind(
          null,
          helper.generatePath("trackingLink", offer, brand),
          !!helperText
        )}
        value={helperText}
      />
      <TextField
        id="tracking-link-append"
        fullWidth
        label="Append to Destination Link"
        onChange={setInputState(setTrackingLinkAppend)}
        value={trackingLinkAppendState || ""}
      />
      <TextField
        id="default-tracking-link-append"
        fullWidth
        disabled
        className={classes.formControl}
        InputProps={{
          classes: { input: "MuiFormHelperText-root" },
          disableUnderline: true,
          style: { fontSize: 14 }
        }}
        onClick={changeRoute.bind(
          null,
          helper.generatePath("trackingLinkAppend", offer, brand),
          !!helperTextAppend
        )}
        value={helperTextAppend}
      />
      <DestinationLinkPreview
        destinationLink={helper.generateDestinationLink(
          trackingLink,
          trackingLinkAppend,
          offer,
          brand
        )}
      />
    </div>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default DestinationLink;
