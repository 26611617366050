import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
interface IDestinationLink {
  destinationLink: string;
}
const DestinationLinkPreview = ({ destinationLink }: IDestinationLink) => {
  const classes = useStyles();
  return (
    <TextField
      id="destination"
      fullWidth
      label="Destination link"
      disabled
      className={classes.formControl}
      variant="filled"
      value={destinationLink}
    />
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));
export default DestinationLinkPreview;
