import { gql } from "@apollo/client";

const ReactivateUserMutation = gql`
  mutation ReactivateUser($userId: String!) {
    reactivateUser(userId: $userId) {
      user {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default ReactivateUserMutation;
