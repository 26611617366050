import { gql } from "@apollo/client";

const AdvertisersQuery = gql`
  query Advertisers(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $search: String
  ) {
    advertisers(
      after: $after
      before: $before
      limit: $limit
      search: $search
    ) {
      edges {
        cursor
        node {
          active
          email
          id
          name
          phoneNumber
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default AdvertisersQuery;
