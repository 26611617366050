import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import { useAuth0 } from "../../utils/auth0Provider";

import { Advertiser_advertiser_advertiser_brands } from "../../generated/Advertiser";

import AdvertiserForm from "../AdvertiserForm";
import BrandForm from "../BrandForm";

interface IAdvertiserShowProps {
  brands: Advertiser_advertiser_advertiser_brands[];
  email: string | null;
  id: number;
  name: string;
  phoneNumber: string | null;
}

const AdvertiserShow = ({
  brands,
  email,
  id,
  name,
  phoneNumber
}: IAdvertiserShowProps) => {
  const classes = useStyles();

  const history = useHistory();

  const { hasPermission } = useAuth0();
  const createBrands = hasPermission("manage:brands");
  const updateAdvertisers = hasPermission("manage:advertisers");

  const [advertiserUpdateOpen, setAdvertiserUpdateOpen] = useState(false);
  const [brandCreateOpen, setBrandCreateOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const onAdvertiserUpdateClose = () => {
    setAdvertiserUpdateOpen(false);
  };

  const onBrandClick = (brandId: number) => () => {
    history.push(`/brands/${brandId}/details`);
  };

  const onBrandCreateOpen = () => {
    setBrandCreateOpen(true);
  };

  const onBrandCreateClose = () => {
    setBrandCreateOpen(false);
  };

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <Typography variant="h4">{name}</Typography>

          {updateAdvertisers && (
            <IconButton onClick={() => setAdvertiserUpdateOpen(true)}>
              <EditIcon />
            </IconButton>
          )}
        </div>

        <AppBar color="default" elevation={0} position="static">
          <Tabs
            onChange={(_, value: number) => setTabIndex(value)}
            value={tabIndex}
          >
            <Tab label="Details" />
            <Tab label="Brands" />
          </Tabs>
        </AppBar>

        <div className={classes.tabContent} hidden={tabIndex !== 0}>
          <Typography>Email: {email}</Typography>
          <Typography>Phone Number: {phoneNumber}</Typography>
        </div>

        <div className={classes.tabContent} hidden={tabIndex !== 1}>
          <div className={classes.brandsHeader}>
            <Typography variant="h5">Brands</Typography>
            <Button
              color="primary"
              onClick={onBrandCreateOpen}
              variant="contained"
            >
              <AddIcon className={classes.buttonIcon} />
              Brand
            </Button>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellId}>ID</TableCell>
                <TableCell className={classes.cellLogo} />
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {brands.map(brand => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={brand.id}
                  onClick={onBrandClick(brand.id)}
                >
                  <TableCell className={classes.cellId}>{brand.id}</TableCell>
                  <TableCell className={classes.cellLogo}>
                    {brand.logoIcon && (
                      <img alt="" height={60} src={brand.logoIcon} />
                    )}
                  </TableCell>
                  <TableCell>{brand.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Paper>

      {updateAdvertisers && (
        <AdvertiserForm
          advertiserId={id}
          email={email}
          name={name}
          onClose={onAdvertiserUpdateClose}
          open={advertiserUpdateOpen}
          phoneNumber={phoneNumber}
          type="update"
        />
      )}

      {createBrands && (
        <BrandForm
          advertiser={{ __typename: "Advertiser", id, name }}
          onClose={onBrandCreateClose}
          open={brandCreateOpen}
          type="create"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  brandsHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  buttonIcon: { marginRight: spacing() },
  cellId: {
    textAlign: "right",
    width: "5%"
  },
  cellLogo: {
    textAlign: "center",
    width: "10%"
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(2)
  },
  tabContent: { padding: spacing(2) },
  tableRow: { cursor: "pointer" }
}));

export default AdvertiserShow;
