import { gql } from "@apollo/client";

const AssignSubagreementMutation = gql`
  mutation AssignSubagreement(
    $accountId: Int
    $affiliateDetails: AgreementDetailsInput
    $networkDetails: AgreementDetailsInput
    $networkTiers: [CreateTierInput!]
    $offerId: Int!
    $partnerTiers: [CreateTierInput!]
    $qualifications: [CreateQualificationInput!]
    $userId: ID!
    $ringfenceRevshare: Boolean
    $startAt: DateTime
    $endAt: DateTime
  ) {
    assignSubagreement(
      input: {
        accountId: $accountId
        affiliateDetails: $affiliateDetails
        networkDetails: $networkDetails
        networkTiers: $networkTiers
        offerId: $offerId
        partnerTiers: $partnerTiers
        qualifications: $qualifications
        userId: $userId
        ringfenceRevshare: $ringfenceRevshare
        startAt: $startAt
        endAt: $endAt
      }
    ) {
      subagreement {
        id
        affiliateDetails {
          commissionType
          currency
          ... on AgreementClawback {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementCpa {
            actionType
            cpaAmount
          }
          ... on AgreementHybrid {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementRevshare {
            revsharePercentages
          }
          ... on AgreementSpend {
            spendAccessToken
            spendPercentage
          }
          tierType
        }
        networkDetails {
          commissionType
          currency
          ... on AgreementClawback {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementCpa {
            actionType
            cpaAmount
          }
          ... on AgreementHybrid {
            actionType
            cpaAmount
            revsharePercentages
          }
          ... on AgreementRevshare {
            revsharePercentages
          }
          tierType
        }
        qualifications {
          amount
          id
          qualificationType
          windowType
        }
        networkTiers {
          cpa
          id
          payType
          revsharePercentages
          threshold
          thresholdType
          level
          windowType
        }
        partnerTiers {
          cpa
          id
          payType
          revsharePercentages
          threshold
          thresholdType
          level
          windowType
        }
        ringfenceRevshare
        updatedAt
        status
        startAt
        endAt
        user {
          id
          name
        }
      }
    }
  }
`;

export default AssignSubagreementMutation;
