import { gql } from "@apollo/client";

const UpdateAdvertiserMutation = gql`
  mutation UpdateAdvertiser(
    $advertiserId: Int!
    $email: String
    $name: String
    $phoneNumber: String
  ) {
    updateAdvertiser(
      advertiserId: $advertiserId
      input: { email: $email, name: $name, phoneNumber: $phoneNumber }
    ) {
      error {
        errorType
      }
    }
  }
`;

export default UpdateAdvertiserMutation;
