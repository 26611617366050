import React, { useState } from "react";

import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { UserCurrency } from "../../generated/globalTypes";
import { User_user_user_paymentDetails_paymentDetailsImplementation } from "../../generated/User";

import PaymentDetailsCard from "../PaymentDetailsCard";
import PaymentDetailsForm from "../PaymentDetailsForm";
import PersonalDetailsForm from "../PersonalDetailsForm";

interface ISettingsProps {
  company: string | null;
  currency: UserCurrency | null;
  email: string;
  name: string;
  paymentDetails: User_user_user_paymentDetails_paymentDetailsImplementation | null;
  phoneNumber: string | null;
}

const Settings = ({
  company,
  currency,
  email,
  name,
  paymentDetails,
  phoneNumber
}: ISettingsProps) => {
  const classes = useStyles();

  const [personalDetailsFormOpen, setPersonalDetailsFormOpen] = useState(false);
  const [paymentDetailsFormOpen, setPaymentDetailsFormOpen] = useState(false);
  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h4">Settings</Typography>
        </div>

        <div className={classes.personal}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">Personal</Typography>
            <Button
              className={classes.editButton}
              onClick={() => setPersonalDetailsFormOpen(true)}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </div>

          <Typography>{`Email: ${email}`}</Typography>
          <Typography>{`Name: ${name}`}</Typography>
          <Typography>{`Company: ${company || "N/A"}`}</Typography>
          <Typography>{`Phone Number: ${phoneNumber || "N/A"}`}</Typography>
          <Typography>{`Display Currency: ${currency || "N/A"}`}</Typography>
        </div>

        <div className={classes.sectionHeader}>
          <Typography variant="h6">Payment Details</Typography>
          <Button
            className={classes.editButton}
            onClick={() => setPaymentDetailsFormOpen(true)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </div>

        {paymentDetails ? (
          <PaymentDetailsCard {...paymentDetails} />
        ) : (
          <Typography>N/A</Typography>
        )}
      </Paper>

      <PersonalDetailsForm
        company={company}
        currency={currency}
        email={email}
        name={name}
        onClose={() => setPersonalDetailsFormOpen(false)}
        open={personalDetailsFormOpen}
        phoneNumber={phoneNumber}
      />

      <PaymentDetailsForm
        {...paymentDetails}
        onClose={() => setPaymentDetailsFormOpen(false)}
        open={paymentDetailsFormOpen}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  editButton: { marginLeft: spacing(3) },
  header: { paddingBottom: spacing() },
  paper: { padding: spacing(3) },
  personal: { paddingBottom: spacing(2) },
  sectionHeader: { alignItems: "center", display: "flex" }
}));

export default Settings;
