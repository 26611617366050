import { gql } from "@apollo/client";

const AllowedPaymentMethodsQuery = gql`
  query AllowedPaymentMethods {
    allowedPaymentMethods {
      active
      currency
      id
      paymentMethod
    }
  }
`;

export default AllowedPaymentMethodsQuery;
