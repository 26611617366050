import { useLazyQuery } from "@apollo/client";
import { FormControl, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React from "react";
import { useDebouncedCallback } from "use-debounce";
import {
  OfferUsers,
  OfferUsers_subagreements_edges_node
} from "../../generated/OfferUsers";
import OfferUsersQuery from "../../queries/OfferUsersQuery";

interface IPartnersSearchBox {
  offerId: number;
  createAllTracking: boolean | undefined;
  setUserId: (value: string) => void;
}

const PartnersSearchBox = ({
  offerId,
  createAllTracking,
  setUserId
}: IPartnersSearchBox) => {
  const classes = useStyles();
  let users: OfferUsers_subagreements_edges_node[] | undefined;
  const [getUsers, { called, data: subagreementsData, loading }] = useLazyQuery<
    OfferUsers
  >(OfferUsersQuery, { variables: { offerId } });

  users = subagreementsData?.subagreements?.edges.map(edge => edge.node) || [];
  const [debouncedUserSearch] = useDebouncedCallback(getUsers, 2000);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: option => (option as any).user.name + (option as any).user.displayId
  });
  return (
    <FormControl className={classes.formControl} fullWidth>
      <Autocomplete
        options={users}
        autoComplete={true}
        getOptionLabel={option => `${(option as any).user.name}`}
        filterOptions={filterOptions}
        renderOption={option => (
          <React.Fragment>
            {`${(option as any).user.name} (${(option as any).user.displayId})`}
          </React.Fragment>
        )}
        loading={loading}
        id="Partners"
        noOptionsText={called ? "No Options" : "Loading…"}
        onChange={(event, value) => {
          if (value) {
            setUserId((value as any).user.id);
          }
        }}
        onInputChange={(event, search) => {
          if (createAllTracking) {
            debouncedUserSearch({
              variables: { offerId, search }
            });
          }
        }}
        onFocus={() => {
          if (createAllTracking && users?.length === 0) {
            debouncedUserSearch({
              variables: { offerId }
            });
          }
        }}
        renderInput={params => (
          <TextField {...params} label="Partners" fullWidth margin="normal" />
        )}
      />
    </FormControl>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: { paddingBottom: spacing(2) }
}));

export default PartnersSearchBox;
