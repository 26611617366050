import { gql } from "@apollo/client";

const ReactivateBrandMutation = gql`
  mutation ReactivateBrand($brandId: Int!) {
    reactivateBrand(brandId: $brandId) {
      brand {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default ReactivateBrandMutation;
