import { gql } from "@apollo/client";

const DeactivateSubagreementMutation = gql`
  mutation DeactivateSubagreement($subagreementId: ID!) {
    deactivateSubagreement(subagreementId: $subagreementId) {
      subagreement {
        status
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default DeactivateSubagreementMutation;
