import clsx from "clsx";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IDropzoneProps {
  id?: string;
  onDropBase64?: (base64?: string) => void;
}

const Dropzone = ({ id, onDropBase64 }: IDropzoneProps) => {
  const classes = useStyles();

  const [fileName, setFileName] = useState<string | undefined>();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: files => {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = () => {
        if (onDropBase64 && typeof reader.result === "string") {
          const base64 = reader.result.split(",")[1];
          onDropBase64(base64);
        }

        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  });

  const onFileRemove = () => {
    if (onDropBase64) {
      onDropBase64(undefined);
    }
    setFileName(undefined);
  };

  return (
    <>
      <div
        className={clsx(
          classes.container,
          isDragActive ? classes.dragActive : undefined
        )}
        {...getRootProps()}
      >
        <input {...getInputProps({ id })} />
        {isDragActive ? (
          <p>Drop to upload...</p>
        ) : (
          <p>Drag file here, or click to select files</p>
        )}
      </div>

      <List dense>
        {fileName && (
          <ListItem>
            <ListItemText primary={fileName} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={onFileRemove}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        )}
      </List>
    </>
  );
};

const useStyles = makeStyles(({ palette, shape }) => ({
  container: {
    alignItems: "center",
    background: palette.grey[200],
    borderRadius: shape.borderRadius,
    display: "flex",
    height: 150,
    justifyContent: "center"
  },
  dragActive: {
    borderColor: palette.primary.light,
    borderStyle: "solid",
    borderWidth: 1
  }
}));

export default Dropzone;
