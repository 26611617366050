import { PropTypes, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { ReactNode } from "react";
const styles = {
  root: {
    marginLeft: 5
  }
};
interface ILoadingButtonProps {
  loading: boolean;
  children: ReactNode;
  onClick: () => void;
  color?: PropTypes.Color;
  variant?: "text" | "outlined" | "contained";
}
const SpinnerAdornment = withStyles(styles)(() => (
  <CircularProgress className={"spinner"} size={20} />
));
const LoadingButton = ({ children, loading, ...rest }: ILoadingButtonProps) => {
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment />}
    </Button>
  );
};
export default LoadingButton;
