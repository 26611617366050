import { gql } from "@apollo/client";

const ApproveUsers = gql`
  mutation ApproveUsers($userIds: [String!]!) {
    approveUsers(userIds: $userIds) {
      users {
        approved
        id
      }
    }
  }
`;

export default ApproveUsers;
