import { gql } from "@apollo/client";

const CreateBrandMutation = gql`
  mutation CreateBrand(
    $advertiserId: Int
    $bundleBrand: Boolean!
    $logoBanner: String
    $logoIcon: String
    $name: String!
    $trackingLink: String
    $trackingLinkAppend: String
  ) {
    createBrand(
      input: {
        advertiserId: $advertiserId
        bundleBrand: $bundleBrand
        logoBanner: $logoBanner
        logoIcon: $logoIcon
        name: $name
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
      }
    ) {
      brand {
        id
      }
    }
  }
`;

export default CreateBrandMutation;
