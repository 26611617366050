import React, { Attributes, ComponentType, createElement } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useAuth0 } from "../../utils/auth0Provider";

interface IRedirectRouteProps extends RouteProps {
  component: ComponentType<any>;
  redirectTo: string;
  shouldRedirect: boolean;
}

const RedirectRoute = ({
  component,
  redirectTo,
  shouldRedirect,
  ...otherProps
}: IRedirectRouteProps) => {
  const { hasPermission, isAuthenticated } = useAuth0();
  const { update } = useIntercom();

  return (
    <Route
      {...otherProps}
      render={props => {
        if (isAuthenticated && hasPermission("boot:intercom")) {
          update();
        }
        return shouldRedirect ? (
          <Redirect to={redirectTo} />
        ) : (
          createElement(component, props as Attributes)
        );
      }}
    />
  );
};

export default RedirectRoute;
