import { ApolloQueryResult } from "@apollo/client";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  AppBar,
  Breadcrumbs,
  Button,
  Divider,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

import { useAuth0 } from "../../utils/auth0Provider";

import { QualificationType, VerticalType } from "../../generated/globalTypes";
import {
  Offer as IOfferQuery,
  Offer_offer_offer_brand,
  Offer_offer_offer_currentAgreement,
  Offer_offer_offer_defaultAgreement
} from "../../generated/Offer";

import CreativeListContainer from "../../containers/CreativeListContainer";
import SubagreementListContainer from "../../containers/SubagreementListContainer";
import WebhookListContainer from "../../containers/WebhookListContainer";
import { IVerticalMap } from "../../utils/verticalsMap";
import AgreementDetailsCard from "../AgreementDetailsCard";
import AgreementForm from "../AgreementForm";
import AgreementQualificationsCard from "../AgreementQualificationsCard";
import AgreementTiersCard from "../AgreementTiersCard";
import RevsharePercentage from "../RevsharePercentage";

import verticalFormatter from "../../utils/verticalFormatter";

export interface IOfferShowProps {
  accountPoolId?: number;
  active: boolean;
  advertiserAccountRequired: boolean;
  brand: Offer_offer_offer_brand;
  currentAgreement: Offer_offer_offer_currentAgreement | null;
  defaultAgreement: Offer_offer_offer_defaultAgreement | null;
  id: number;
  mobileOptimized: boolean;
  name: string;
  offerRefetch: () => Promise<ApolloQueryResult<IOfferQuery>>;
  overview: string | null;
  playerBonuses: string | null;
  terms: string | null;
  trackingLink: string | null;
  trackingLinkAppend: string | null;
  verticalNames: VerticalType[] | null;
  verticals: IVerticalMap;
  visible: boolean;
}

const OfferShow = ({
  accountPoolId,
  active,
  advertiserAccountRequired,
  brand,
  currentAgreement,
  defaultAgreement,
  id,
  mobileOptimized,
  name,
  offerRefetch,
  overview,
  playerBonuses,
  terms,
  trackingLink,
  trackingLinkAppend,
  verticalNames,
  verticals
}: IOfferShowProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const readSubagreements = hasPermission("read:subagreements:all");
  const createWebhooks = hasPermission("manage:webhooks");
  const readNetworkDetails = hasPermission("read:agreements:network_details");

  const history = useHistory();

  const onBreadcrumbClick = (path: string) => () => {
    history.push(path);
  };
  const [agreementCreateOpen, setAgreementCreateOpen] = useState(false);
  const { tabId: tabIndex } = useParams<{ tabId: string }>();

  const currentMinDeposit =
    currentAgreement &&
    currentAgreement.qualifications.find(
      qualification =>
        qualification.qualificationType === QualificationType.MIN_DEPOSIT
    );

  return (
    <>
      <Paper>
        <Breadcrumbs className={classes.breadcrumbs} separator=">">
          <Typography
            className={classes.breadcrumbLink}
            onClick={onBreadcrumbClick(`/advertisers/${brand.advertiser.id}`)}
          >
            {brand.advertiser.name}
          </Typography>

          <Typography
            className={classes.breadcrumbLink}
            onClick={onBreadcrumbClick(`/brands/${brand.id}/details`)}
          >
            {brand.name}
          </Typography>
        </Breadcrumbs>

        <div className={classes.header}>
          <Typography variant="h4">{name}</Typography>
        </div>

        <AppBar color="default" elevation={0} position="static">
          <Tabs
            onChange={(_, value: string) => {
              history.push(`${value}`);
            }}
            value={tabIndex}
          >
            <Tab label="Details" value={"details"} />
            <Tab label="Creatives" value={"creatives"} />
            {createWebhooks && <Tab value={"pixels"} label="Pixels" />}
            {readSubagreements && (
              <Tab label="Agreements" value={"agreements"} />
            )}
          </Tabs>
        </AppBar>

        <div className={classes.tabContent} hidden={tabIndex !== "details"}>
          <Typography>
            Advertiser Account Required:
            {advertiserAccountRequired ? " Yes" : " No"}
          </Typography>
          <Typography>
            Mobile Optimized: {mobileOptimized ? "Yes" : "No"}
          </Typography>
          <Typography>Overview: {overview}</Typography>
          <Typography>Player Bonuses: {playerBonuses}</Typography>
          <Typography>Terms: {terms}</Typography>
          {hasPermission("manage:offers") && (
            <Typography>
              {`Tracking Link: ${
                trackingLink ? trackingLink + (trackingLinkAppend || "") : "N/A"
              }`}
            </Typography>
          )}
          <Typography>
            Verticals:{" "}
            {verticalNames?.length
              ? verticalNames
                  ?.map(verticalName => {
                    return verticalFormatter(verticalName);
                  })
                  .toString()
              : "N/A"}
          </Typography>

          {currentAgreement && (
            <>
              <Typography
                className={classes.currentAgreementHeader}
                variant="h5"
              >
                Current Agreement
              </Typography>
              <Typography>{`Commission Type: ${currentAgreement.affiliateDetails.commissionType}`}</Typography>
              <Typography>{`Currency: ${currentAgreement.affiliateDetails.currency}`}</Typography>
              {currentAgreement.affiliateDetails.__typename !==
                "AgreementRevshare" &&
                currentAgreement.affiliateDetails.__typename !==
                  "AgreementSpend" && (
                  <>
                    <Typography>{`CPA Action Type: ${currentAgreement.affiliateDetails.actionType}`}</Typography>
                    <Typography>{`CPA Base Amount: ${currentAgreement.affiliateDetails.cpaAmount}`}</Typography>
                  </>
                )}
              {currentAgreement.affiliateDetails.__typename !==
                "AgreementCpa" &&
                (currentAgreement.affiliateDetails.__typename ===
                "AgreementSpend" ? (
                  <Typography>{`Spend Percentage: ${currentAgreement.affiliateDetails.spendPercentage}%`}</Typography>
                ) : (
                  <div className={classes.revshare}>
                    <Typography>Revshare: </Typography>
                    {
                      <RevsharePercentage
                        revsharePercentages={
                          currentAgreement.affiliateDetails.revsharePercentages
                        }
                        verticals={verticals}
                      />
                    }
                  </div>
                ))}
              <Typography>{`Min Deposit: ${currentMinDeposit?.amount ||
                "N/A"}`}</Typography>
            </>
          )}
        </div>

        <div className={classes.tabContent} hidden={tabIndex !== "creatives"}>
          {tabIndex === "creatives" && (
            <CreativeListContainer
              brand={brand}
              offer={{ trackingLink, trackingLinkAppend, id }}
            />
          )}
        </div>

        {createWebhooks && tabIndex === "pixels" && (
          <WebhookListContainer offerId={id} />
        )}

        {readSubagreements && (
          <div
            className={classes.tabContent}
            hidden={tabIndex !== (createWebhooks ? "agreements" : "pixels")}
          >
            <div className={classes.tabHeader}>
              <Typography variant="h5">Default Agreement</Typography>
              <Button
                color="primary"
                onClick={() => setAgreementCreateOpen(true)}
                variant="contained"
              >
                {defaultAgreement ? (
                  <>
                    <EditIcon className={classes.buttonIcon} />
                    Edit Default
                  </>
                ) : (
                  <>
                    <AddIcon className={classes.buttonIcon} />
                    Create Default
                  </>
                )}
              </Button>
            </div>

            {defaultAgreement ? (
              <>
                <Typography>
                  {`Last Updated: ${new Date(
                    defaultAgreement.updatedAt
                  ).toLocaleString()}`}
                </Typography>

                <div className={classes.container}>
                  {readNetworkDetails && defaultAgreement.networkDetails && (
                    <AgreementDetailsCard
                      {...defaultAgreement.networkDetails}
                      verticals={verticals}
                      type="network"
                    />
                  )}
                  <AgreementDetailsCard
                    {...defaultAgreement.affiliateDetails}
                    verticals={verticals}
                    type="partner"
                  />
                  {!!defaultAgreement.qualifications.length && (
                    <AgreementQualificationsCard
                      qualifications={defaultAgreement.qualifications}
                    />
                  )}
                  {readNetworkDetails &&
                    defaultAgreement.networkTiers &&
                    !!defaultAgreement.networkTiers.length && (
                      <AgreementTiersCard
                        commissionType={
                          defaultAgreement.networkDetails?.commissionType!
                        }
                        currency={defaultAgreement.networkDetails?.currency!}
                        networkTiers={defaultAgreement.networkTiers}
                      />
                    )}
                  {!!defaultAgreement.partnerTiers.length && (
                    <AgreementTiersCard
                      commissionType={
                        defaultAgreement.affiliateDetails.commissionType
                      }
                      currency={defaultAgreement.affiliateDetails.currency}
                      partnerTiers={defaultAgreement.partnerTiers}
                    />
                  )}
                </div>

                <Divider />

                <SubagreementListContainer
                  accountPoolId={accountPoolId}
                  defaultAgreement={defaultAgreement}
                  defaultAgreementId={defaultAgreement.id}
                  offerActive={active}
                  offerId={id}
                  verticals={verticals}
                />
              </>
            ) : (
              <Typography variant="body1">
                No default agreement for this offer
              </Typography>
            )}
          </div>
        )}
      </Paper>

      <AgreementForm
        affiliateDetails={
          defaultAgreement ? defaultAgreement.affiliateDetails : undefined
        }
        networkDetails={
          defaultAgreement ? defaultAgreement.networkDetails : undefined
        }
        offerId={id}
        offerRefetch={offerRefetch}
        onClose={() => setAgreementCreateOpen(false)}
        open={agreementCreateOpen}
        qualifications={defaultAgreement?.qualifications}
        networkTiers={defaultAgreement?.networkTiers || undefined}
        partnerTiers={defaultAgreement?.partnerTiers}
        ringfenceRevshare={defaultAgreement?.ringfenceRevshare}
        startAt={defaultAgreement?.startAt || undefined}
        endAt={defaultAgreement?.endAt || undefined}
        type={!!defaultAgreement ? "update" : "create"}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  breadcrumbLink: {
    "&:hover": {
      textDecoration: "underline"
    },
    cursor: "pointer"
  },
  breadcrumbs: {
    paddingLeft: spacing(2),
    paddingTop: spacing(2)
  },
  buttonIcon: { marginRight: spacing() },
  container: { display: "flex" },
  currentAgreementHeader: { padding: spacing(2, 0, 1, 0) },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(2)
  },
  hover: {
    textDecoration: "underline"
  },
  revshare: {
    display: "flex",
    whiteSpace: "pre"
  },
  tabContent: { minWidth: 800, padding: spacing(2) },
  tabHeader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  }
}));

export default OfferShow;
