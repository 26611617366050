import React from "react";
import MaskedInput from "react-text-mask";

interface IInputTextMaskProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  mask?: boolean | Array<string | RegExp>;
}

export const phoneNumberMask = (value: string) => {
  const mask = [
    /[1-9]/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  const valueDiff = value.replace(/[^0-9]/gi, "").length - 10;

  if (valueDiff > 0) {
    mask.unshift(" ");

    const maxDiff = valueDiff > 3 ? 3 : valueDiff;
    for (let i = 0; i < maxDiff - 1; i++) {
      mask.unshift(/\d/);
    }

    mask.unshift(/[1-9]/);
    mask.unshift("+");
  }

  return mask;
};

const InputTextMask = ({ inputRef, mask, ...other }: IInputTextMaskProps) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask as any}
      placeholderChar={"\u2000"}
    />
  );
};

export default InputTextMask;
