import { useQuery } from "@apollo/client";
import React from "react";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import { User as IUserQuery } from "../../generated/User";
import UserQuery from "../../queries/UserQuery";
import { useAuth0 } from "../../utils/auth0Provider";

import Settings from "../../components/Settings";

const SettingsContainer = () => {
  const classes = useStyles();

  const { user } = useAuth0();

  const { loading, data } = useQuery<IUserQuery>(UserQuery, {
    errorPolicy: "all",
    variables: { userId: user ? user.sub : "" }
  });

  if (loading) {
    return <LinearProgress />;
  }

  // TODO: Clean this check for the right data up
  if (!data || !data.user || data.user.error || !data.user.user) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return (
    <Settings
      {...data.user.user}
      paymentDetails={
        data.user.user.paymentDetails?.paymentDetailsImplementation || null
      }
    />
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default SettingsContainer;
