import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField
} from "@material-ui/core";

import { AccountPool_accountPool_accountPool_variables } from "../../generated/AccountPool";
import { CreateAccountPoolVariables } from "../../generated/CreateAccountPool";
import { CreateAccountPoolVariableInput } from "../../generated/globalTypes";
import { UpdateAccountPoolVariables } from "../../generated/UpdateAccountPool";
import CreateAccountPoolMutation from "../../mutations/CreateAccountPoolMutation";
import UpdateAccountPoolMutation from "../../mutations/UpdateAccountPoolMutation";

import ConfirmationAlert from "../ConfirmationAlert";
import InputAdvertiserSearch from "../InputAdvertiserSearch";

interface IAccountPoolVariableState {
  description?: string;
  name?: string;
}

interface IAccountPoolFormProps {
  onClose: () => void;
  open: boolean;
}

interface IAccountPoolCreateProps extends IAccountPoolFormProps {
  accountPoolId?: undefined;
  description?: undefined;
  name?: undefined;
  type: "create";
  variables?: undefined;
}

interface IAccountPoolUpdateProps extends IAccountPoolFormProps {
  accountPoolId: number;
  description: string | null;
  name: string | null;
  type: "update";
  variables: AccountPool_accountPool_accountPool_variables[];
}

const AccountPoolForm = ({
  accountPoolId,
  description,
  name,
  onClose,
  open,
  type,
  variables
}: IAccountPoolCreateProps | IAccountPoolUpdateProps) => {
  const emptyStringValue = type === "update" ? null : undefined;
  const variableNameRegex = RegExp("^[a-z0-9_]*$");
  const initialVariables = variables?.map(variable => ({
    description: variable.description || undefined,
    name: variable.name
  }));

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [advertiserIdState, setAdvertiserId] = useState<number>();
  const [accountPoolDescriptionState, setAccountPoolDescription] = useState(
    description || emptyStringValue
  );
  const [accountPoolNameState, setAccountPoolName] = useState(
    name || emptyStringValue
  );
  const [accountPoolVariablesState, setAccountPoolVariables] = useState<
    IAccountPoolVariableState[]
  >(initialVariables || [{}]);

  const [upsertAccountPool, { loading }] = useMutation(
    type === "create" ? CreateAccountPoolMutation : UpdateAccountPoolMutation
  );

  const handleSelectSearch = (id: number | undefined) => {
    setDirty(true);
    setAdvertiserId(id);
  };

  const setInputState = (setFunction: (value: string) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value);
  };

  const handleAddVariable = () => {
    setAccountPoolVariables(accountPoolVariablesState.concat([{}]));
  };

  const handleRemoveVariable = () => {
    if (variables?.length === accountPoolVariablesState.length) {
      return;
    }
    setAccountPoolVariables(
      accountPoolVariablesState.slice(0, accountPoolVariablesState.length - 1)
    );
  };

  const handleVariableChange = (event: ChangeEvent<HTMLInputElement>) => {
    const idSplit = event.target.id.split("-");

    if (idSplit[0] === "name" && !variableNameRegex.test(event.target.value)) {
      return;
    }
    setDirty(true);
    const newVariables = accountPoolVariablesState.map(variable => ({
      ...variable
    }));
    Object.assign(newVariables[parseInt(idSplit[1], 10)], {
      [idSplit[0]]: event.target.value
    });
    setAccountPoolVariables(newVariables);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const handleDiscard = () => {
    setConfirmOpen(false);
    onCloseReset();
  };

  const onCloseReset = () => {
    setDirty(false);
    setAdvertiserId(undefined);
    setAccountPoolName(name || emptyStringValue);
    setAccountPoolDescription(description || emptyStringValue);
    setAccountPoolVariables(initialVariables || [{}]);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (type === "create") {
      const createVariables: CreateAccountPoolVariables = {
        advertiserId: advertiserIdState!,
        description: accountPoolDescriptionState,
        name: accountPoolNameState!,
        variables: accountPoolVariablesState as CreateAccountPoolVariableInput[]
      };

      upsertAccountPool({
        refetchQueries: ["AccountPools"],
        variables: createVariables
      }).then(() => onCloseReset());
    } else {
      const updateVariables: UpdateAccountPoolVariables = {
        accountPoolId: accountPoolId!,
        description: accountPoolDescriptionState,
        name: accountPoolNameState,
        variables: accountPoolVariablesState as CreateAccountPoolVariableInput[]
      };

      upsertAccountPool({
        refetchQueries: ["AccountPools"],
        variables: updateVariables
      }).then(() => onCloseReset());
    }
  };

  const renderAccountPoolVariables = () => {
    return accountPoolVariablesState.map((variable, index) => (
      <div key={index}>
        <TextField
          className={classes.formControl}
          disabled={variables && index <= variables.length - 1}
          fullWidth
          id={`name-${index}`}
          label={`Variable ${index + 1} Name`}
          onChange={handleVariableChange}
          required
          value={variable.name || ""}
        />

        <TextField
          className={classes.formControl}
          fullWidth
          id={`description-${index}`}
          label={`Variable ${index + 1} Description`}
          onChange={handleVariableChange}
          value={variable.description || ""}
        />
      </div>
    ));
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{`${
            type === "create" ? "Create" : "Update"
          } Account Pool`}</DialogTitle>

          <DialogContent>
            {!variables && (
              <InputAdvertiserSearch
                disabled={false}
                label="Search for advertiser..."
                onSelect={handleSelectSearch}
                className={classes.formControl}
              />
            )}

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Account Pool Name"
              onChange={setInputState(setAccountPoolName)}
              required
              value={accountPoolNameState || ""}
            />

            <TextField
              className={classes.formControl}
              id="accountPoolDescription"
              fullWidth
              label="Account Pool Description"
              onChange={setInputState(setAccountPoolDescription)}
              value={accountPoolDescriptionState || ""}
            />

            {renderAccountPoolVariables()}

            <div className={classes.variableButtonContainer}>
              <Button
                disabled={
                  accountPoolVariablesState.length === 1 ||
                  accountPoolVariablesState.length === variables?.length ||
                  loading
                }
                onClick={handleRemoveVariable}
              >
                Remove Variable
              </Button>
              <Button
                disabled={loading}
                onClick={handleAddVariable}
                variant="contained"
              >
                Add Variable
              </Button>
            </div>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!dirty || loading}
              type="submit"
              variant="contained"
            >
              {type}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={handleDiscard}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  },
  variableButtonContainer: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));

export default AccountPoolForm;
