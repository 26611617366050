import React, { ReactNode } from "react";

import { makeStyles, Menu } from "@material-ui/core";

interface ISidenavPopoverProps {
  anchorEl: HTMLElement | undefined;
  children: ReactNode;
  handleClose: () => void;
  open: boolean;
}

const SidenavPopover = ({
  anchorEl,
  children,
  handleClose,
  open
}: ISidenavPopoverProps) => {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      children={children}
      className={classes.root}
      getContentAnchorEl={null}
      MenuListProps={{
        className: classes.popover,
        onClick: handleClose,
        onMouseLeave: handleClose
      }}
      open={open}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
    />
  );
};

const useStyles = makeStyles(() => ({
  popover: {
    pointerEvents: "auto"
  },
  root: {
    pointerEvents: "none"
  }
}));

export default SidenavPopover;
