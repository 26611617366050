import { gql } from "@apollo/client";

const CreateTransactionMutation = gql`
  mutation CreateTransaction(
    $amount: Float
    $clickId: ID!
    $currency: TransactionCurrency
    $transactionAt: DateTime!
    $transactionType: TransactionType!
  ) {
    createTransaction(
      input: {
        amount: $amount
        clickId: $clickId
        currency: $currency
        transactionAt: $transactionAt
        transactionType: $transactionType
      }
    ) {
      transaction {
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default CreateTransactionMutation;
