import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  TextField
} from "@material-ui/core";

import { CreateAdvertiserVariables } from "../../generated/CreateAdvertiser";
import { UpdateAdvertiserVariables } from "../../generated/UpdateAdvertiser";
import CreateAdvertiserMutation from "../../mutations/CreateAdvertiserMutation";
import UpdateAdvertiserMutation from "../../mutations/UpdateAdvertiserMutation";
import AdvertiserQuery from "../../queries/AdvertiserQuery";

import ConfirmationAlert from "../ConfirmationAlert";
import InputTextMask, { phoneNumberMask } from "../InputTextMask";

interface IAdvertiserFormProps {
  onClose: () => void;
  open: boolean;
}

interface IAdvertiserCreateProps extends IAdvertiserFormProps {
  advertiserId?: undefined;
  email?: undefined;
  name?: undefined;
  phoneNumber?: undefined;
  type: "create";
}

interface IAdvertiserUpdateProps extends IAdvertiserFormProps {
  advertiserId: number;
  email: string | null;
  name: string;
  phoneNumber: string | null;
  type: "update";
}

const AdvertiserForm = ({
  advertiserId,
  email,
  name,
  onClose,
  open,
  phoneNumber,
  type
}: IAdvertiserCreateProps | IAdvertiserUpdateProps) => {
  const emptyStringValue = type === "update" ? null : undefined;

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [emailState, setEmail] = useState(email || emptyStringValue);
  const [nameState, setName] = useState(name || emptyStringValue);
  const [phoneNumberState, setPhoneNumber] = useState(
    phoneNumber || emptyStringValue
  );

  const [upsertAdvertiser, { loading }] = useMutation(
    type === "create" ? CreateAdvertiserMutation : UpdateAdvertiserMutation
  );

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setEmail(email || emptyStringValue);
    setName(name || emptyStringValue);
    setPhoneNumber(phoneNumber || emptyStringValue);
    setDirty(false);
    onClose();
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (type === "create") {
      const variables: CreateAdvertiserVariables = {
        email: emailState,
        name: nameState!,
        phoneNumber: phoneNumberState
      };

      upsertAdvertiser({ variables, refetchQueries: ["Advertisers"] }).then(
        () => {
          setDirty(false);
          onClose();
        }
      );
    } else {
      const variables: UpdateAdvertiserVariables = {
        advertiserId: advertiserId!,
        email: emailState,
        name: nameState,
        phoneNumber: phoneNumberState
      };

      upsertAdvertiser({
        refetchQueries: [
          { query: AdvertiserQuery, variables: { advertiserId } }
        ],
        variables
      }).then(() => {
        setDirty(false);
        onClose();
      });
    }
  };

  const setInputState = (setFunction: (value?: string | null) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value || emptyStringValue);
  };

  const dialogTitle =
    type === "create" ? "Create Advertiser" : `Update ${name}`;
  const dialogText =
    type === "create"
      ? `Add a new advertiser. Advertisers own many
         brands, each with a list of offers for affilates to browse.`
      : `Editing contact information for this advertiser.`;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <TextField
              className={classes.formControl}
              id="email"
              fullWidth
              label="Email"
              onChange={setInputState(setEmail)}
              value={emailState || ""}
            />

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
              <Input
                id="phone-number"
                inputComponent={InputTextMask as any}
                inputProps={{ mask: phoneNumberMask }}
                onChange={setInputState(setPhoneNumber)}
                value={phoneNumberState || ""}
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              {type.toUpperCase()}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default AdvertiserForm;
