import { gql } from "@apollo/client";

const ReactivateCreativeMutation = gql`
  mutation ReactivateCreative($creativeId: Int!) {
    reactivateCreative(creativeId: $creativeId) {
      creative {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default ReactivateCreativeMutation;
