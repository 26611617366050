import React, { useState } from "react";

import { IconButton, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { AccountPool_accountPool_accountPool_variables } from "../../generated/AccountPool";
import { Accounts_accounts_edges_node_variables } from "../../generated/Accounts";
import AccountForm from "../AccountForm";

export interface IAccountListItemProps {
  accountPoolId: number;
  accountPoolName: string;
  accountPoolVariables: AccountPool_accountPool_accountPool_variables[];
  id: number;
  user: { displayId: number | null; id: string; name: string };
  username: string;
  variables: Accounts_accounts_edges_node_variables[];
}

const AccountListItem = ({
  accountPoolId,
  accountPoolName,
  accountPoolVariables,
  id,
  user,
  username,
  variables
}: IAccountListItemProps) => {
  const [accountUpdateOpen, setAccountUpdateOpen] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell>{id}</TableCell>
        <TableCell>{username}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>
          <IconButton onClick={() => setAccountUpdateOpen(true)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <AccountForm
        accountId={id}
        accountPoolId={accountPoolId}
        accountPoolName={accountPoolName}
        accountPoolVariables={accountPoolVariables}
        accountVariables={variables}
        onClose={() => setAccountUpdateOpen(false)}
        open={accountUpdateOpen}
        staticUser={user}
        type="update"
        username={username}
      />
    </>
  );
};

export default AccountListItem;
