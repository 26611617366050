import { gql } from "@apollo/client";

const UpdateMonthlyPaymentSettingsMutation = gql`
  mutation UpdateMonthlyPaymentSettings(
    $month: DateTime!
    $ringfenceCommissionType: Boolean!
    $brandBundle: [Int!]!
  ) {
    updateMonthlyPaymentSettings(
      month: $month
      ringfenceCommissionType: $ringfenceCommissionType
      brandBundle: $brandBundle
    ) {
      monthlyPaymentSetting {
        month
        ringfenceCommissionType
        brandBundle
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default UpdateMonthlyPaymentSettingsMutation;
