import { gql } from "@apollo/client";

const ImportConversions = gql`
  mutation ImportConversions(
    $csvFile: Upload!
    $date: DateTime!
    $override: Boolean
  ) {
    importConversions(csvFile: $csvFile, date: $date, override: $override) {
      clicksUpdated {
        id
      }
      failedRows
      filename
    }
  }
`;

export default ImportConversions;
