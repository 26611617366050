import { gql } from "@apollo/client";

const MonthlyPaymentSettingsQuery = gql`
  query monthlyPaymentSettings($month: DateTime!) {
    monthlyPaymentSettings(month: $month) {
      monthlyPaymentSetting {
        month
        ringfenceCommissionType
        brandBundle
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default MonthlyPaymentSettingsQuery;
