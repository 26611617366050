import { gql } from "@apollo/client";

const InstanceSettingsQuery = gql`
  query InstanceSettings {
    instanceSettings {
      displayName
      favicon
      logo
      ngrFormula
      paymentCurrency
      themePrimaryColor
      themePrimaryTextColor
      themeSecondaryColor
      themeSecondaryTextColor
      title
    }
  }
`;

export default InstanceSettingsQuery;
