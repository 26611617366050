import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Advertisers as IAdvertisersQuery,
  Advertisers_advertisers
} from "../../generated/Advertisers";
import AdvertisersQuery from "../../queries/AdvertisersQuery";

import AdvertiserList from "../../components/AdvertiserList";

const AdvertiserListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { loading, error, data } = useQuery<IAdvertisersQuery>(
    AdvertisersQuery,
    { variables: { after, before } }
  );

  const advertisersData = (() => {
    if (data && data.advertisers) {
      return data.advertisers;
    } else {
      const defaultData: Advertisers_advertisers = {
        __typename: "AdvertisersConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = advertisersData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = advertisersData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <AdvertiserList
        advertisers={advertisersData.edges.map(edge => edge.node)}
        hasNextPage={advertisersData.pageInfo.hasNextPage}
        hasPreviousPage={advertisersData.pageInfo.hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  }
}));

export default AdvertiserListContainer;
