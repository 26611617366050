import { gql } from "@apollo/client";

const AdjustmentsQuery = gql`
  query Adjustments($after: ID, $before: ID, $limit: Int! = 50) {
    adjustments(after: $after, before: $before, limit: $limit) {
      edges {
        cursor
        node {
          id
          adjustmentAt
          amount
          createdAt
          creator {
            id
            name
          }
          currency
          note
          partner {
            id
            displayId
            name
          }
          subagreement {
            id
            offer {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default AdjustmentsQuery;
