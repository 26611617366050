import { SvgIcon } from "@material-ui/core";
import React from "react";

const LogsIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M 4.56,15.67
           C 4.56,15.67 4.56,16.39 4.56,16.39
             4.56,16.39 4.99,16.39 4.99,16.39
             4.99,16.39 5.41,16.39 5.41,16.39
             5.41,16.39 5.41,16.27 5.41,16.27
             5.41,15.98 5.46,15.46 5.52,15.12
             5.52,15.12 5.55,14.95 5.55,14.95
             5.55,14.95 5.06,14.95 5.06,14.95
             5.06,14.95 4.56,14.95 4.56,14.95
             4.56,14.95 4.56,15.67 4.56,15.67 Z
           M 4.56,12.04
           C 4.56,12.04 4.56,12.75 4.56,12.75
             4.56,12.75 5.42,12.75 5.42,12.75
             5.42,12.75 6.29,12.75 6.29,12.75
             6.29,12.75 6.39,12.56 6.39,12.56
             6.62,12.14 6.84,11.80 7.10,11.46
             7.15,11.39 7.19,11.34 7.19,11.33
             7.19,11.33 6.60,11.33 5.87,11.33
             5.87,11.33 4.56,11.33 4.56,11.33
             4.56,11.33 4.56,12.04 4.56,12.04 Z
           M 12.34,14.74
           C 12.34,14.74 12.34,16.73 12.34,16.73
             12.34,16.73 13.37,17.78 13.37,17.78
             13.37,17.78 14.40,18.84 14.40,18.84
             14.40,18.84 14.87,18.33 14.87,18.33
             14.87,18.33 15.34,17.83 15.34,17.83
             15.34,17.83 14.52,16.99 14.52,16.99
             14.52,16.99 13.71,16.16 13.71,16.16
             13.71,16.16 13.71,14.45 13.71,14.45
             13.71,14.45 13.71,12.75 13.71,12.75
             13.71,12.75 13.02,12.75 13.02,12.75
             13.02,12.75 12.34,12.75 12.34,12.75
             12.34,12.75 12.34,14.74 12.34,14.74 Z
           M 12.55,9.98
           C 12.02,10.03 11.56,10.13 11.11,10.28
             10.35,10.54 9.68,10.92 9.05,11.46
             8.88,11.60 8.50,11.99 8.34,12.19
             7.53,13.15 7.01,14.36 6.85,15.62
             6.79,16.14 6.79,16.76 6.85,17.28
             6.94,18.03 7.19,18.83 7.53,19.48
             8.30,20.98 9.57,22.09 11.11,22.62
             11.49,22.75 12.10,22.88 12.47,22.91
             12.50,22.91 9.71,22.92 6.27,22.92
             6.27,22.92 0.00,22.92 0.00,22.92
             0.00,22.92 0.00,22.96 0.00,22.96
             0.00,22.96 0.00,23.00 0.00,23.00
             0.00,23.00 10.00,23.00 10.00,23.00
             10.00,23.00 20.00,23.00 20.00,23.00
             20.00,23.00 20.00,22.96 20.00,22.96
             20.00,22.96 20.00,22.92 20.00,22.92
             20.00,22.92 16.75,22.92 16.75,22.92
             14.89,22.92 13.56,22.91 13.63,22.90
             14.05,22.87 14.56,22.75 15.00,22.60
             16.23,22.17 17.31,21.33 18.07,20.23
             18.48,19.64 18.79,18.98 18.99,18.27
             19.34,17.02 19.32,15.66 18.93,14.42
             18.61,13.42 18.09,12.56 17.35,11.80
             16.43,10.85 15.17,10.21 13.89,10.03
             13.48,9.97 12.90,9.95 12.55,9.98 Z
           M 13.28,11.39
           C 13.57,11.41 13.74,11.43 14.01,11.49
             14.75,11.65 15.45,12.00 16.05,12.50
             16.25,12.67 16.62,13.05 16.77,13.24
             16.90,13.40 17.12,13.74 17.22,13.91
             17.79,14.94 17.99,16.14 17.79,17.32
             17.66,18.14 17.29,18.96 16.79,19.61
             16.38,20.12 15.88,20.55 15.32,20.88
             15.03,21.04 14.81,21.14 14.46,21.25
             13.95,21.42 13.45,21.49 12.89,21.48
             12.19,21.46 11.55,21.30 10.93,20.98
             8.99,20.01 7.90,17.85 8.23,15.65
             8.25,15.53 8.28,15.37 8.29,15.31
             8.53,14.26 9.07,13.31 9.85,12.61
             10.67,11.88 11.66,11.46 12.73,11.39
             12.98,11.38 13.03,11.38 13.28,11.39 Z
           M 4.56,8.41
           C 4.56,8.41 4.56,9.12 4.56,9.12
             4.56,9.12 7.32,9.12 7.32,9.12
             7.32,9.12 10.08,9.12 10.08,9.12
             10.08,9.12 10.22,9.07 10.22,9.07
             10.72,8.87 11.20,8.73 11.62,8.65
             11.81,8.62 12.09,8.57 12.20,8.56
             12.25,8.56 12.31,8.55 12.33,8.55
             12.33,8.55 12.37,8.54 12.37,8.54
             12.37,8.54 12.37,8.12 12.37,8.12
             12.37,8.12 12.37,7.70 12.37,7.70
             12.37,7.70 8.46,7.70 8.46,7.70
             8.46,7.70 4.56,7.70 4.56,7.70
             4.56,7.70 4.56,8.41 4.56,8.41 Z
           M 4.56,4.77
           C 4.56,4.77 4.56,5.48 4.56,5.48
             4.56,5.48 8.46,5.48 8.46,5.48
             8.46,5.48 12.37,5.48 12.37,5.48
             12.37,5.48 12.37,4.77 12.37,4.77
             12.37,4.77 12.37,4.06 12.37,4.06
             12.37,4.06 8.46,4.06 8.46,4.06
             8.46,4.06 4.56,4.06 4.56,4.06
             4.56,4.06 4.56,4.77 4.56,4.77 Z
           M 2.16,0.03
           C 1.85,0.12 1.65,0.22 1.40,0.42
             0.98,0.77 0.72,1.27 0.66,1.85
             0.65,1.94 0.64,4.61 0.65,10.32
             0.65,10.32 0.65,18.65 0.65,18.65
             0.65,18.65 0.69,18.82 0.69,18.82
             0.78,19.24 0.94,19.55 1.22,19.85
             1.53,20.17 1.90,20.37 2.34,20.44
             2.43,20.46 2.80,20.46 4.46,20.46
             5.56,20.46 6.47,20.46 6.47,20.45
             6.47,20.44 6.44,20.39 6.40,20.33
             6.13,19.85 5.85,19.14 5.69,18.55
             5.69,18.55 5.65,18.39 5.65,18.39
             5.65,18.39 4.14,18.39 4.14,18.39
             4.14,18.39 2.63,18.39 2.63,18.39
             2.63,18.39 2.63,10.22 2.63,10.22
             2.63,10.22 2.63,2.04 2.63,2.04
             2.63,2.04 8.50,2.04 8.50,2.04
             8.50,2.04 14.36,2.04 14.36,2.04
             14.36,2.04 14.36,5.33 14.36,5.33
             14.36,5.33 14.36,8.63 14.36,8.63
             14.36,8.63 14.39,8.63 14.39,8.63
             14.41,8.63 14.52,8.66 14.65,8.69
             15.15,8.79 15.70,8.98 16.16,9.20
             16.16,9.20 16.35,9.29 16.35,9.29
             16.35,9.29 16.36,5.68 16.36,5.68
             16.36,3.48 16.36,2.00 16.35,1.90
             16.31,1.38 16.11,0.94 15.77,0.58
             15.50,0.30 15.22,0.13 14.84,0.03
             14.84,0.03 14.71,0.00 14.71,0.00
             14.71,0.00 8.50,0.00 8.50,0.00
             8.50,0.00 2.28,0.00 2.28,0.00
             2.28,0.00 2.16,0.03 2.16,0.03 Z"
      />
    </SvgIcon>
  );
};

export default LogsIcon;
