import { useQuery } from "@apollo/client";
import { Box, Button, Link, Typography } from "@material-ui/core";
import Mail from "@material-ui/icons/Mail";
import { makeStyles } from "@material-ui/styles";
import React from "react";

import Loading from "../Loading";

import { InstanceSettings as IInstanceSettingsQuery } from "../../generated/InstanceSettings";
import InstanceSettingsQuery from "../../queries/InstanceSettingsQuery";

const ErrorPage = () => {
  const classes = useStyles();

  const { data, loading } = useQuery<IInstanceSettingsQuery>(
    InstanceSettingsQuery
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.logoContainer}>
          <img
            className={classes.logo}
            src="/android-chrome-512x512.png"
            alt="platform logo"
          />
        </Box>
        <Box textAlign="center">
          <Typography variant="h3" className={classes.errorTitle}>
            {data?.instanceSettings.displayName || "Intelitics"}
          </Typography>
          <Typography variant="h3" className={classes.errorSubtitle}>
            Oops!, something went wrong
          </Typography>
        </Box>
      </Box>
      <Box className={classes.messageContainer}>
        <Box>
          <Typography>
            Try logging in again from the application and if the problem
            persists please contact the administrator.
          </Typography>
          <Button
            className={classes.callToAction}
            color="primary"
            variant="contained"
            href="/login"
            size="large"
          >
            Log In
          </Button>
        </Box>
      </Box>
      <Box>
        <Box className={classes.footerContainer}>
          <Typography className={classes.footerTitle}>support</Typography>
          <Link
            className={classes.emailContainer}
            href="mailto:support@intelitics.com"
          >
            <Mail />
            <Box component="span">support@intelitics.com</Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  callToAction: {
    marginTop: "1.5rem"
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minHeight: "100vh"
  },
  emailContainer: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap"
  },
  errorSubtitle: {
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: "bold",
    lineHeight: "2rem"
  },
  errorTitle: {
    color: "#fff",
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: "3rem"
  },
  footerContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2rem"
  },
  footerTitle: {
    color: "#4a4a4a",
    fontWeight: "bold"
  },
  header: {
    alignItems: "center",
    backgroundColor: "#2b2b33",
    display: "flex",
    flexDirection: "column",
    height: "33%",
    justifyContent: "center"
  },
  logo: {
    height: "100%",
    width: "100%"
  },
  logoContainer: {
    height: "5rem",
    marginBottom: "2rem",
    width: "5rem"
  },
  messageContainer: {
    backgroundColor: "#e3e5e7",
    display: "flex",
    flexDirection: "column",
    height: "33%",
    justifyContent: "center",
    textAlign: "center"
  }
}));

export default ErrorPage;
