import { gql } from "@apollo/client";

const BrandsQuery = gql`
  query Brands(
    $after: ID
    $before: ID
    $limit: Int! = 50
    $search: String
    $ids: [Int!]
  ) {
    brands(
      after: $after
      before: $before
      limit: $limit
      search: $search
      ids: $ids
    ) {
      edges {
        cursor
        node {
          active
          advertiser {
            id
            name
          }
          id
          logoIcon
          name
          trackingLink
          trackingLinkAppend
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default BrandsQuery;
