import { gql } from "@apollo/client";

const LatestPaymentsStatusQuery = gql`
  query LatestPaymentsStatus($month: DateTime!) {
    getLatestPaymentsStatus(month: $month) {
      status
      month
      updatedAt
    }
  }
`;

export default LatestPaymentsStatusQuery;
