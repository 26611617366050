import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce/lib";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  AccountPools as IAccountPoolsQuery,
  AccountPools_accountPools
} from "../../generated/AccountPools";
import AccountPoolsQuery from "../../queries/AccountPoolsQuery";

import AccountPoolList from "../../components/AccountPoolList";

const AccountPoolListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { data, error, loading, refetch } = useQuery<IAccountPoolsQuery>(
    AccountPoolsQuery,
    { variables: { after, before } }
  );

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value?: string) => {
    debouncedRefetch({ after, before, search: value });
  };

  const accountPoolsData = (() => {
    if (data && data.accountPools) {
      return data.accountPools;
    } else {
      const defaultData: AccountPools_accountPools = {
        __typename: "AccountPoolsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = accountPoolsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = accountPoolsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <AccountPoolList
        accountPools={accountPoolsData.edges.map(edge => edge.node)}
        handleSearch={handleSearch}
        hasNextPage={accountPoolsData.pageInfo.hasNextPage}
        hasPreviousPage={accountPoolsData.pageInfo.hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  }
}));

export default AccountPoolListContainer;
