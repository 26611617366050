import { gql } from "@apollo/client";

const ReactivateOfferMutation = gql`
  mutation ReactivateOffer($offerId: Int!) {
    reactivateOffer(offerId: $offerId) {
      offer {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default ReactivateOfferMutation;
