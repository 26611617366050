import { gql } from "@apollo/client";

const DeactivateBrandMutation = gql`
  mutation DeactivateBrand($brandId: Int!) {
    deactivateBrand(brandId: $brandId) {
      brand {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default DeactivateBrandMutation;
