import { VerticalType } from "../generated/globalTypes";

const verticalFormatter = (vertical: VerticalType) => {
  return vertical
    .split("_")
    .map(substring => {
      return (
        substring.charAt(0).toUpperCase() + substring.slice(1).toLowerCase()
      );
    })
    .join(" ");
};

export default verticalFormatter;
