import createAuth0Client, {
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions
} from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import jwtDecode from "jwt-decode";

type PermissionType =
  | "boot:intercom"
  | "manage:adjustments"
  | "manage:account_pools"
  | "manage:advertisers"
  | "manage:agreements:dates"
  | "manage:agreements:network_details"
  | "manage:brands"
  | "manage:clicks"
  | "manage:creatives"
  | "manage:instance_settings"
  | "manage:offers"
  | "manage:smart_pixels"
  | "manage:subagreements"
  | "manage:trackings"
  | "manage:users"
  | "manage:webhooks"
  | "manage:webhooks:all"
  | "read:adjustments"
  | "read:advertisers"
  | "read:agreements:network_details"
  | "read:clicks"
  | "read:monthly_payments"
  | "read:payment_details"
  | "read:reports:all"
  | "read:subagreements:all"
  | "read:users"
  | "update:offers";

interface IAuth0User {
  "https://intelitics.com/app_metadata": {
    approved: boolean;
  };
  "https://intelitics.com/user_metadata": {
    currency?: string;
  };
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  permissions: string[];
  picture: string;
  sub: string;
  updated_at: string;
}

interface IAuth0Context {
  getTokenSilently: (options?: GetTokenSilentlyOptions) => Promise<any>;
  handleRedirectCallback: () => Promise<void>;
  hasPermission: (permission: PermissionType) => boolean | undefined;
  isAuthenticated: boolean;
  loading: boolean;
  loginWithRedirect: (options?: RedirectLoginOptions) => Promise<void>;
  logout: (options?: LogoutOptions) => void;
  user?: IAuth0User;
}

const Auth0Context = React.createContext<IAuth0Context>({} as IAuth0Context);
export const useAuth0 = () => useContext(Auth0Context);

interface IAuth0ProviderProps extends Auth0ClientOptions {
  children: ReactNode;
  onRedirectCallback?: (appState: any) => void;
}

const DEFAULT_REDIRECT_CALLBACK = (_: any) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: IAuth0ProviderProps) => {
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IAuth0User>();

  useEffect(() => {
    (async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0Client(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const newIsAuthenticated = await auth0FromHook.isAuthenticated();

      if (newIsAuthenticated) {
        const newUser = await auth0FromHook.getUser();

        const token = await auth0FromHook.getTokenSilently();
        const decodedToken = jwtDecode<{ permissions: string[] }>(token);

        setUser({ ...newUser, permissions: decodedToken.permissions } as any);
      }

      setIsAuthenticated(newIsAuthenticated);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client!.handleRedirectCallback();

    const newUser = await auth0Client!.getUser();
    setUser(newUser as any);

    setIsAuthenticated(true);
    setLoading(false);
  };

  const loginWithRedirect = async (options?: RedirectLoginOptions) => {
    try {
      await auth0Client?.loginWithRedirect(options);
    } catch (error) {
      // tslint:disable-next-line: no-console
      console.error(error);
    }

    const newUser = await auth0Client!.getUser();

    const token = await auth0Client!.getTokenSilently();
    const decodedToken = jwtDecode<{ permissions: string[] }>(token);

    setUser({ ...newUser, permissions: decodedToken.permissions } as any);
    setIsAuthenticated(true);
  };

  const hasPermission = (permission: PermissionType) => {
    return (
      user?.["https://intelitics.com/app_metadata"].approved &&
      user?.permissions.includes(permission)
    );
  };

  return (
    <Auth0Context.Provider
      value={{
        getTokenSilently: (options?: GetTokenSilentlyOptions) =>
          auth0Client!.getTokenSilently(options),
        handleRedirectCallback,
        hasPermission,
        isAuthenticated,
        loading,
        loginWithRedirect,
        logout: (options?: LogoutOptions) => auth0Client!.logout(options),
        user
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
