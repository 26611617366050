import React from "react";

import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@material-ui/core";
import clsx from "clsx";

import {
  CommissionType,
  Currency,
  ThresholdType
} from "../../generated/globalTypes";
import {
  Offer_offer_offer_defaultAgreement_networkTiers,
  Offer_offer_offer_defaultAgreement_partnerTiers
} from "../../generated/Offer";
import getCurrencySymbol from "../../utils/getCurrencySymbol";

export interface IAgreementTiersCard {
  commissionType: CommissionType;
  currency: Currency;
  networkTiers?: Offer_offer_offer_defaultAgreement_networkTiers[];
  partnerTiers?: Offer_offer_offer_defaultAgreement_partnerTiers[];
}

const AgreementTiersCard = ({
  commissionType,
  currency,
  networkTiers,
  partnerTiers
}: IAgreementTiersCard) => {
  const classes = useStyles();

  const tiers = networkTiers || partnerTiers;

  const tierLevels: (
    Offer_offer_offer_defaultAgreement_networkTiers[]
    | Offer_offer_offer_defaultAgreement_partnerTiers[])[] = [];

  tiers?.forEach(tier => {
    const tierIndex = tier.level - 1;
    if (!tierLevels[tierIndex]) {
      tierLevels[tierIndex] = [];
    }
    tierLevels[tierIndex].push(tier);
  })

  return (
    <Paper className={classes.paper}>
      <Typography align="center" variant="h6">
        {`${networkTiers ? "Network" : "Partner"} Tiers`}
      </Typography>
      <Table>
        {tierLevels.length ? (
          tierLevels.map((tierLevel, index) => (
            <TableBody className={classes.tier} key={index}>
              <TableRow>
                <TableCell className={classes.tierRow}>
                  <strong>{`Tier ${index + 1}`}</strong>
                </TableCell>
                <TableCell className={classes.tierRow}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tierRow}>
                  Thresholds
                </TableCell>
              </TableRow>

              {tierLevel.map(tier => (
                <TableRow>
                  <TableCell className={clsx(classes.tierRowInset, classes.tierRow)}>
                    {tier.thresholdType.replace(/_/g, " ")}
                  </TableCell>
                  <TableCell className={classes.tierRow}>
                    {`${
                      tier.thresholdType === ThresholdType.NET_REVENUE
                        ? getCurrencySymbol(currency)
                        : ""
                    }${tier.threshold}`}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell className={classes.tierRow}>
                  Commissions
                </TableCell>
              </TableRow>

              {commissionType !== CommissionType.REVSHARE && (
                <TableRow>
                  <TableCell className={clsx(classes.tierRowInset, classes.tierRow)}>CPA</TableCell>
                  <TableCell className={classes.tierRow}>{`${getCurrencySymbol(
                    currency
                  )}${tierLevel[0].cpa}`}</TableCell>
                </TableRow>
              )}
              {commissionType !== CommissionType.CPA && (
                <TableRow>
                  <TableCell className={clsx(classes.tierRowInset, classes.tierRow)}>RS %</TableCell>
                  <TableCell className={classes.tierRow}>
                    {tierLevel[0].revsharePercentages.default}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          ))
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align={"center"}>N/A</TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  paper: {
    margin: spacing(),
    padding: spacing(2)
  },
  tier: {
    borderBottom: "1px solid rgb(224, 224, 224)",
    paddingTop: spacing()
  },
  tierHeader: {
    paddingLeft: spacing()
  },
  tierRow: {
    border: "none",
    padding: spacing(1, 1)
  },
  tierRowInset: {
    paddingLeft: spacing(3)
  }
}));

export default AgreementTiersCard;
