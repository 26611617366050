import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import ClickShow from "../../components/ClickShow";
import { Click as IClickQuery } from "../../generated/Click";
import {
  ClickServiceErrorType,
  TransactionType
} from "../../generated/globalTypes";
import ClickQuery from "../../queries/ClickQuery";

const ClickShowContainer = () => {
  const { clickId } = useParams<{ clickId: string }>();

  const classes = useStyles();

  const { loading, error, data } = useQuery<IClickQuery>(ClickQuery, {
    variables: { clickId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (data?.click?.error?.errorType === ClickServiceErrorType.NOT_FOUND) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Click ID does not exist.
        </Typography>
      </>
    );
  }

  if (error || !data || !data.click || !data.click.click) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  const clickedAt = data.click.click.transactions.find(
    c => c.transactionType === TransactionType.CLICK
  )?.transactionAt;

  return <ClickShow {...data.click.click} clickedAt={clickedAt} />;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default ClickShowContainer;
