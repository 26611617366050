import { useApolloClient, useQuery } from "@apollo/client";
import clsx from "clsx";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Toolbar,
  Typography
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "@material-ui/icons/Menu";

import PartnerViewIdQuery from "../../queries/PartnerViewIdQuery";

import { PartnerViewId as IPartnerViewId } from "../../generated/PartnerViewId";

import Sidenav from "../Sidenav";

import defaultLogo from "./logo.png";

const drawerWidth = 240;

interface IInstanceSettingsList {
  logo: string | null | undefined;
}
const Header = ({ logo }: IInstanceSettingsList) => {
  const [navOpen, setNavOpen] = useState(true);
  const [imageSrc, setImageSrc] = useState(logo);

  const { data } = useQuery<IPartnerViewId>(PartnerViewIdQuery);

  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();

  const handleReturnToAdmin = () => {
    client.writeQuery({
      data: {
        partnerViewCurrency: null,
        partnerViewId: null
      },
      query: PartnerViewIdQuery
    });

    history.push("/");
  };

  const onError = () => {
    setImageSrc(defaultLogo);
  };

  return (
    <>
      <AppBar
        className={clsx(
          data?.partnerViewId ? classes.partnerView : classes.appBar,
          {
            [classes.appBarShift]: navOpen
          }
        )}
        position="fixed"
      >
        <Toolbar>
          <div className={classes.bannerContainer}>
            <IconButton
              aria-label="Open Drawer"
              className={clsx(classes.menuButton, { [classes.hide]: navOpen })}
              color="inherit"
              edge="start"
              onClick={() => setNavOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.imgContainer}>
              <img
                alt="Intelitics Logo"
                height="30"
                src={imageSrc || undefined}
                onError={onError}
              />
            </div>
          </div>

          {data?.partnerViewId && (
            <div className={classes.infoBanner}>
              <Typography
                component={Button}
                onClick={handleReturnToAdmin}
                className={classes.callToAction}
              >
                you are now using partner view. click here to return to admin
                view
              </Typography>
            </div>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        classes={{
          paper: clsx({
            [classes.drawerOpen]: navOpen,
            [classes.drawerClose]: !navOpen
          })
        }}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: navOpen,
          [classes.drawerClose]: !navOpen
        })}
        open
        variant="permanent"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={() => setNavOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <Sidenav navOpen={navOpen} />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles(
  ({ breakpoints, mixins, spacing, transitions, zIndex, palette }) => ({
    appBar: {
      transition: transitions.create(["width", "margin"], {
        duration: transitions.duration.leavingScreen,
        easing: transitions.easing.sharp
      }),
      zIndex: zIndex.drawer + 1
    },
    appBarShift: {
      marginLeft: drawerWidth,
      transition: transitions.create(["width", "margin"], {
        duration: transitions.duration.enteringScreen,
        easing: transitions.easing.sharp
      }),
      width: `calc(100% - ${drawerWidth}px)`
    },
    bannerContainer: {
      display: "flex"
    },
    callToAction: { color: "white" },
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      width: drawerWidth
    },
    drawerClose: {
      overflowX: "hidden",
      transition: transitions.create("width", {
        duration: transitions.duration.leavingScreen,
        easing: transitions.easing.sharp
      }),
      width: spacing(7) + 1,
      [breakpoints.up("sm")]: {
        width: spacing(9) + 1
      }
    },
    drawerOpen: {
      transition: transitions.create("width", {
        duration: transitions.duration.enteringScreen,
        easing: transitions.easing.sharp
      }),
      width: drawerWidth
    },
    hide: {
      display: "none"
    },
    imgContainer: {
      display: "flex",
      margin: "auto"
    },
    infoBanner: {
      display: "flex",
      flex: 1,
      justifyContent: "center"
    },
    menuButton: {
      marginRight: 36
    },
    partnerView: {
      backgroundColor: palette.info.main,
      transition: transitions.create(["width", "margin"], {
        duration: transitions.duration.leavingScreen,
        easing: transitions.easing.sharp
      }),
      zIndex: zIndex.drawer + 1
    },
    toolbar: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      paddingLeft: 8,
      paddingRight: 8,
      ...mixins.toolbar
    }
  })
);

export default Header;
