import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import BrandShow from "../../components/BrandShow";
import { Brand as IBrandQuery } from "../../generated/Brand";
import BrandQuery from "../../queries/BrandQuery";

const BrandShowContainer = () => {
  const { brandId: rawBrandId } = useParams<{ brandId: string }>();
  const brandId = parseInt(rawBrandId as string, 10);

  const classes = useStyles();

  const { loading, error, data } = useQuery<IBrandQuery>(BrandQuery, {
    variables: { brandId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  // TODO: Clean this check for the right data up
  if (error || !data || !data.brand || data.brand.error || !data.brand.brand) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return (
    <BrandShow {...data.brand.brand} advertiser={data.brand.brand.advertiser} />
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default BrandShowContainer;
