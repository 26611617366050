import { gql } from "@apollo/client";

const DeactivateOfferMutation = gql`
  mutation DeactivateOffer($offerId: Int!) {
    deactivateOffer(offerId: $offerId) {
      offer {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default DeactivateOfferMutation;
