import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  Select,
  TextField
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { UserCurrency } from "../../generated/globalTypes";
import { UpdateUserVariables } from "../../generated/UpdateUser";
import UpdateUserMutation from "../../mutations/UpdateUserMutation";
import UserQuery from "../../queries/UserQuery";
import getCurrencySymbol from "../../utils/getCurrencySymbol";
import InputTextMask, { phoneNumberMask } from "../InputTextMask";

import ConfirmationAlert from "../ConfirmationAlert";

interface IPersonalDetailsFormProps {
  company: string | null;
  currency: UserCurrency | null;
  email: string;
  name: string;
  onClose: () => void;
  open: boolean;
  phoneNumber: string | null;
}
const PersonalDetailsForm = ({
  company,
  currency,
  email,
  name,
  onClose,
  open,
  phoneNumber
}: IPersonalDetailsFormProps) => {
  const classes = useStyles();

  const { user } = useAuth0();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [companyState, setCompany] = useState(company);
  const [currencyState, setCurrency] = useState(currency);
  const [emailState, setEmail] = useState(email);
  const [nameState, setName] = useState(name);
  const [phoneNumberState, setPhoneNumber] = useState(phoneNumber);

  const [updateUser, { loading }] = useMutation(UpdateUserMutation);

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    setCompany(company);
    setCurrency(currency);
    setEmail(email);
    setName(name);
    setPhoneNumber(phoneNumber);
    onClose();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables: UpdateUserVariables = { userId: user!.sub };

    if (companyState !== company) {
      variables.company = companyState;
    }
    if (currencyState !== currency) {
      variables.currency = currencyState;
    }
    if (emailState !== email) {
      variables.email = emailState;
    }
    if (nameState !== name) {
      variables.name = nameState;
    }
    if (phoneNumberState !== phoneNumber) {
      variables.phoneNumber = phoneNumberState;
    }

    if (!(Object.entries(variables).length === 0)) {
      updateUser({
        awaitRefetchQueries: true,
        refetchQueries: [
          { query: UserQuery, variables: { userId: user!.sub } }
        ],
        variables
      }).then(() => {
        setDirty(false);
        onClose();
      });
    }
  };

  const setInputState = (
    setFunction: ((value: string) => void) | ((value: UserCurrency) => void)
  ) => (
    event: ChangeEvent<
      HTMLInputElement | { name?: string | undefined; value: unknown }
    >
  ) => {
    setDirty(true);
    setFunction((event.target.value as UserCurrency) || null);
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Personal Details</DialogTitle>

          <DialogContent>
            <TextField
              className={classes.formControl}
              error={!emailState}
              fullWidth
              helperText={!emailState ? "Email is required" : undefined}
              id="email"
              label="Email"
              onChange={setInputState(setEmail)}
              required
              value={emailState || ""}
            />

            <TextField
              className={classes.formControl}
              error={!nameState}
              fullWidth
              helperText={!nameState ? "Name is required" : undefined}
              id="name"
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <TextField
              className={classes.formControl}
              fullWidth
              id="company"
              label="Company"
              onChange={setInputState(setCompany)}
              value={companyState || ""}
            />

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="phone-number">Phone Number</InputLabel>
              <Input
                id="phone-number"
                inputComponent={InputTextMask as any}
                inputProps={{ mask: phoneNumberMask }}
                name="phoneNumber"
                onChange={setInputState(setPhoneNumber)}
                value={phoneNumberState}
              />
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="currency">Currency</InputLabel>
              <Select
                inputProps={{ id: "currency" }}
                native
                onChange={setInputState(setCurrency)}
                value={currencyState || ""}
              >
                <option value="" />
                {Object.keys(UserCurrency).map(userCurrency => (
                  <option key={userCurrency} value={userCurrency}>
                    {`${userCurrency} (${getCurrencySymbol(userCurrency)})`}
                  </option>
                ))}
              </Select>
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: { paddingBottom: spacing(2) }
}));

export default PersonalDetailsForm;
