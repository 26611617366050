import { gql } from "@apollo/client";

const CreateCreativeMutation = gql`
  mutation CreateCreative(
    $image: String
    $languageCode: LanguageCode!
    $name: String!
    $offerId: Int!
    $trackingLink: String
    $trackingLinkAppend: String
    $visible: Boolean! = false
  ) {
    createCreative(
      input: {
        image: $image
        languageCode: $languageCode
        name: $name
        offerId: $offerId
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
        visible: $visible
      }
    ) {
      creative {
        id
      }
    }
  }
`;

export default CreateCreativeMutation;
