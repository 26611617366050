import { gql } from "@apollo/client";

const UpdateAllowedPaymentMethodMutation = gql`
  mutation UpdateAllowedPaymentMethod(
    $allowedPaymentMethodId: ID!
    $active: Boolean!
  ) {
    updateAllowedPaymentMethod(
      allowedPaymentMethodId: $allowedPaymentMethodId
      active: $active
    ) {
      allowedPaymentMethod {
        active
        currency
        id
        paymentMethod
      }
      error {
        errorType
      }
    }
  }
`;

export default UpdateAllowedPaymentMethodMutation;
