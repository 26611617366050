import { gql } from "@apollo/client";

const MarkPaymentsPaidMutation = gql`
  mutation markPaymentsPaid($input: [MonthlyPaymentTotalsPartnerIdMonth!]!) {
    markPaymentsPaid(input: $input) {
      error {
        errorType
        message
      }
    }
  }
`;

export default MarkPaymentsPaidMutation;
