import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  Webhooks as IWebhooksQuery,
  Webhooks_webhooks,
  WebhooksVariables
} from "../../generated/Webhooks";
import WebhooksQuery from "../../queries/WebhooksQuery";

import WebhookList from "../../components/WebhookList";

interface IWebhookListContainerProps {
  offerId: number;
}

const WebhookListContainer = ({ offerId }: IWebhookListContainerProps) => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { data, error, loading } = useQuery<IWebhooksQuery, WebhooksVariables>(
    WebhooksQuery,
    { variables: { after, before, offerId: offerId.toString() } }
  );

  const webhooksData = (() => {
    if (data && data.webhooks) {
      return data.webhooks;
    } else {
      const defaultData: Webhooks_webhooks = {
        __typename: "WebhooksConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = webhooksData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = webhooksData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <WebhookList
        hasNextPage={webhooksData.pageInfo.hasNextPage}
        hasPreviousPage={webhooksData.pageInfo.hasPreviousPage}
        offerId={offerId}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        webhooks={webhooksData.edges.map(edge => edge.node)}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  }
}));

export default WebhookListContainer;
