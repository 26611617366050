import { gql } from "@apollo/client";

const MarkPaymentsRolloverMutation = gql`
  mutation markPaymentsRollover(
    $input: [MonthlyPaymentTotalsPartnerIdMonth!]!
  ) {
    markPaymentsRollover(input: $input) {
      error {
        errorType
        message
      }
    }
  }
`;

export default MarkPaymentsRolloverMutation;
