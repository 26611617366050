import React from "react";

import { makeStyles } from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";

import { SortOrder, UserSortFields } from "../../generated/globalTypes";

interface ISortableTableHeaderProps {
  label: string;
  self: UserSortFields;
  onChangeSort: (attribute: UserSortFields, order: SortOrder) => void;
  sortAttribute: UserSortFields;
  sortOrder: SortOrder;
}

const SortableTableHeader = ({
  label,
  onChangeSort,
  self,
  sortAttribute,
  sortOrder
}: ISortableTableHeaderProps) => {
  const classes = useStyles();

  let icon;

  switch (true) {
    case self === sortAttribute && sortOrder === SortOrder.ASCENDING:
      icon = <ArrowDropUpIcon />;
      break;
    case self === sortAttribute && sortOrder === SortOrder.DESCENDING:
      icon = <ArrowDropDownIcon />;
      break;
    default:
      icon = <UnfoldMoreIcon />;
  }

  return (
    <>
      <span
        className={classes.container}
        onClick={() => onChangeSort(self, sortOrder)}
      >
        {label} {icon}
      </span>
    </>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    alignItems: "center",
    display: "flex"
  }
}));

export default SortableTableHeader;
