import React from "react";

import {
  Button,
  ButtonGroup,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { Adjustments_adjustments_edges_node } from "../../generated/Adjustments";
import AdjustmentForm from "../AdjustmentForm";

interface IAdjustmentListProps {
  adjustments: Adjustments_adjustments_edges_node[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const AdjustmentList = ({
  adjustments,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage
}: IAdjustmentListProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const createAdjustments = hasPermission("manage:adjustments");

  return (
    <>
      <Paper className={classes.paper}>
        <Typography variant="h5">Adjustments</Typography>
        {createAdjustments && <AdjustmentForm />}
        <Typography className={classes.adjustmentHistory} variant="h6">
          Adjustment History
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellPartner}>Partner</TableCell>
              <TableCell className={classes.cellOffer}>Offer</TableCell>
              <TableCell>Adjusted Date</TableCell>
              <TableCell className={classes.cellAmount}>Amount</TableCell>
              <TableCell className={classes.cellCurrency}>Currency</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Submitted Date</TableCell>
              <TableCell className={classes.cellAdminOwnder}>
                Admin Owner
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {adjustments.map(adjustment => (
              <TableRow hover key={adjustment.id}>
                <TableCell>{`${adjustment.partner.name} (${adjustment.partner.displayId})`}</TableCell>
                <TableCell>
                  {adjustment.subagreement?.offer.name || "Not Offer Specific"}
                </TableCell>
                <TableCell>{adjustment.adjustmentAt.split("T")[0]}</TableCell>
                <TableCell align="right">{adjustment.amount}</TableCell>
                <TableCell align="center">{adjustment.currency}</TableCell>
                <TableCell className={classes.cellNotes}>
                  {adjustment.note}
                </TableCell>
                <TableCell>{adjustment.createdAt.split("T")[0]}</TableCell>
                <TableCell>{adjustment.creator.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  adjustmentHistory: {
    borderTop: "1px solid rgb(224, 224, 224)",
    paddingTop: spacing()
  },
  cellAdminOwnder: { width: "10%" },
  cellAmount: { width: "5%" },
  cellCurrency: { width: "5%" },
  cellNotes: {
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  cellOffer: { width: "10%" },
  cellPartner: { width: "20%" },
  paginationTableCell: {
    padding: 0
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default AdjustmentList;
