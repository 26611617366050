import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";

import {
  Button,
  ButtonGroup,
  Checkbox,
  makeStyles,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";

import { MonthlyPaymentStatus } from "../../generated/globalTypes";
import {
  markPaymentsPaid as IMarkPaymentsPaid,
  markPaymentsPaidVariables
} from "../../generated/markPaymentsPaid";
import {
  markPaymentsRollover as IMarkPaymentsRollover,
  markPaymentsRolloverVariables
} from "../../generated/markPaymentsRollover";

import {
  markPaymentsPending as IMarkPaymentsPending,
  markPaymentsPendingVariables
} from "../../generated/markPaymentsPending";
import { monthlyPaymentSummaries_monthlyPaymentSummaries_edges_node } from "../../generated/monthlyPaymentSummaries";
import MarkPaymentsPaidMutation from "../../mutations/MarkPaymentsPaidMutation";
import MarkPaymentsPendingMutation from "../../mutations/MarkPaymentsPending";
import MarkPaymentsRolloverMutation from "../../mutations/MarkPaymentsRollover";

import ConfirmationAlert from "../ConfirmationAlert";
import MonthlyPaymentsListItem from "../MonthlyPaymentLIstsItem";

export interface IMonthlyPaymentsListProps {
  monthClosed: boolean;
  monthLocked: boolean;
  monthlyPayments: monthlyPaymentSummaries_monthlyPaymentSummaries_edges_node[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  refetch: () => void;
  onNextPage: () => void;
  onPreviousPage: () => void;
  statusFilter?: MonthlyPaymentStatus | string;
}

interface IPaymentEntry {
  partnerId: string;
  month: string;
  externalComment?: string | null;
  internalComment?: string | null;
}

const MonthlyPaymentsList = ({
  monthClosed,
  monthLocked,
  monthlyPayments,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage,
  refetch,
  statusFilter
}: IMonthlyPaymentsListProps) => {
  const classes = useStyles();

  const [paymentsSelected, setPaymentsSelected] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [paymentAction, setPaymentAction] = useState<MonthlyPaymentStatus>();

  const [paymentEntries, setPaymentEntries] = useState<IPaymentEntry[]>([]);
  const [markPaymentsPaid, { loading: loadingMarkPaymentsPaid }] = useMutation<
    IMarkPaymentsPaid,
    markPaymentsPaidVariables
  >(MarkPaymentsPaidMutation);

  const [
    markPaymentsRollover,
    { loading: loadingMarkPaymentsRollover }
  ] = useMutation<IMarkPaymentsRollover, markPaymentsRolloverVariables>(
    MarkPaymentsRolloverMutation
  );

  const [
    markPaymentsPending,
    { loading: loadingMarkPaymentsPending }
  ] = useMutation<IMarkPaymentsPending, markPaymentsPendingVariables>(
    MarkPaymentsPendingMutation
  );

  useEffect(() => {
    setPaymentsSelected([]);
    setPaymentAction(undefined);
  }, [monthlyPayments]);

  useEffect(() => {
    if (
      paymentsSelected.length &&
      paymentsSelected.length === monthlyPayments.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [monthlyPayments, paymentsSelected]);

  const onSelectPayment = (id: string) => {
    setPaymentsSelected([...paymentsSelected, id]);
  };

  const onClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onSelectPayment(e.target.value);
    } else {
      setPaymentsSelected(paymentsSelected.filter(p => p !== e.target.value));
    }
  };

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPaymentsSelected(monthlyPayments.map(mp => mp.partnerId));
    } else {
      setPaymentsSelected([]);
    }
  };

  const onSelectPaymentAction = (action: MonthlyPaymentStatus) => {
    setPaymentAction(action);
  };

  const onCloseReset = () => {
    setPaymentAction(undefined);
    setPaymentsSelected([]);
    setPaymentEntries([]);
  };

  const onPaymentEntryUpdate = (partnerId: string, month: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedEntries = [...paymentEntries];

    const entryField =
      event.target.id === "internal-comment"
        ? "internalComment"
        : "externalComment";

    const index = updatedEntries.findIndex(
      entry => entry.partnerId === partnerId
    );

    const newEntry = {
      partnerId,
      ...(entryField === "internalComment" && {
        internalComment: event.target.value === "" ? null : event.target.value
      }),
      ...(entryField === "externalComment" && {
        externalComment: event.target.value === "" ? null : event.target.value
      }),
      month
    };

    if (index >= 0) {
      updatedEntries[index][entryField] =
        event.target.value === "" ? null : event.target.value;
      setPaymentEntries(updatedEntries);
    } else {
      setPaymentEntries([...updatedEntries, newEntry]);
    }
  };

  const onSubmit = async () => {
    const variables:
      | markPaymentsPaidVariables
      | markPaymentsRolloverVariables
      | markPaymentsPendingVariables = {
      input: paymentsSelected.map(selected => {
        const match = paymentEntries.filter(
          entry => entry.partnerId === selected
        );
        return match.length > 0
          ? match[0]
          : { partnerId: selected, month: monthlyPayments[0].month };
      })
    };

    if (paymentAction === MonthlyPaymentStatus.PAID) {
      await markPaymentsPaid({ variables });
      await refetch();
      onCloseReset();
    } else if (paymentAction === MonthlyPaymentStatus.ROLLOVER) {
      await markPaymentsRollover({ variables });
      await refetch();
      onCloseReset();
    } else if (paymentAction === MonthlyPaymentStatus.PENDING) {
      await markPaymentsPending({ variables });
      await refetch();
      onCloseReset();
    }
  };

  const renderPaymentActionSelect = (
    filter?: MonthlyPaymentStatus | string
  ) => {
    let selection;
    switch (filter) {
      case MonthlyPaymentStatus.PENDING:
        selection = (
          <>
            <option value={MonthlyPaymentStatus.PAID}>
              {MonthlyPaymentStatus.PAID}
            </option>
            <option value={MonthlyPaymentStatus.ROLLOVER}>
              {MonthlyPaymentStatus.ROLLOVER}
            </option>
          </>
        );
        break;
      case MonthlyPaymentStatus.PAID:
        selection = (
          <>
            <option value={MonthlyPaymentStatus.PENDING}>
              {MonthlyPaymentStatus.PENDING}
            </option>
            <option value={MonthlyPaymentStatus.ROLLOVER}>
              {MonthlyPaymentStatus.ROLLOVER}
            </option>
          </>
        );
        break;
      case "":
        selection = (
          <>
            <option value={MonthlyPaymentStatus.PENDING}>
              {MonthlyPaymentStatus.PENDING}
            </option>
            <option value={MonthlyPaymentStatus.ROLLOVER}>
              {MonthlyPaymentStatus.ROLLOVER}
            </option>
            <option value={MonthlyPaymentStatus.PAID}>
              {MonthlyPaymentStatus.PAID}
            </option>
          </>
        );
        break;
      default:
        selection = null;
    }
    return (
      <Select
        native
        onChange={e =>
          setPaymentAction(
            (e.target.value as MonthlyPaymentStatus) || undefined
          )
        }
        value={paymentAction || ""}
      >
        <option value={""}>Action</option>
        {paymentsSelected.length && selection}
      </Select>
    );
  };

  const renderSelectedPartnerNames = () => {
    const selectedMonthlyPayments = monthlyPayments.filter(mp =>
      paymentsSelected.includes(mp.partnerId)
    );
    return selectedMonthlyPayments.map(
      mp => ` ${mp.partnerName} (${mp.partnerDisplayId})`
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Typography className={classes.monthlyPaymentsList} variant="h6">
          Payments
        </Typography>
        <Table>
          <TableHead>
            {monthLocked && statusFilter ? (
              <TableRow>
                <TableCell className={classes.tableMenu}>
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                </TableCell>
                <TableCell className={classes.tableMenu}>
                  {renderPaymentActionSelect(statusFilter)}
                </TableCell>
              </TableRow>
            ) : monthLocked ? (
              <TableRow>
                <TableCell className={classes.tableMenu}></TableCell>
                <TableCell className={classes.tableMenu}>
                  {renderPaymentActionSelect(statusFilter)}
                </TableCell>
              </TableRow>
            ) : null}
            <TableRow>
              <TableCell />
              <TableCell>Status</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Updated by</TableCell>
              <TableCell>Updated at</TableCell>
              <TableCell>Payment method </TableCell>
              <TableCell>CPA Cost</TableCell>
              <TableCell>RS Cost</TableCell>
              <TableCell>Adjustments</TableCell>
              <TableCell>Referral Cost</TableCell>
              <TableCell>Rollover</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Partner Total</TableCell>
              {(monthLocked || monthClosed) && (
                <>
                  <TableCell>Internal Comments</TableCell>
                  <TableCell>External Comments</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {monthlyPayments.map(monthlyPayment => (
              <MonthlyPaymentsListItem
                key={monthlyPayment.partnerId}
                monthClosed={monthClosed}
                monthLocked={monthLocked}
                monthlyPayment={monthlyPayment}
                onClickCheckBox={onClickCheckbox}
                onSelectPayment={onSelectPayment}
                onSelectPaymentAction={onSelectPaymentAction}
                selected={paymentsSelected.includes(monthlyPayment.partnerId)}
                onPaymentUpdate={onPaymentEntryUpdate(
                  monthlyPayment.partnerId,
                  monthlyPayment.month
                )}
              />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      {paymentAction && (
        <ConfirmationAlert
          content={`Are you sure you want to mark payment for:${renderSelectedPartnerNames()} as ${paymentAction}?`}
          disabled={
            loadingMarkPaymentsPaid ||
            loadingMarkPaymentsRollover ||
            loadingMarkPaymentsPending
          }
          onNegative={() => setPaymentAction(undefined)}
          onPositive={onSubmit}
          open={!!paymentAction}
          positiveAction="Confirm"
          title="Confirm Update Payment Status"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  button: {
    "&:hover": {
      backgroundColor: "lightgray"
    },
    margin: spacing(0, 1)
  },
  monthlyPaymentsList: {
    borderTop: "1px solid rgb(224, 224, 224)",
    paddingTop: spacing()
  },
  paginationTableCell: {
    padding: 0
  },
  paidBorder: {
    border: "1px solid rgba(92, 195, 132, 0.5)"
  },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  rolloverBorder: {
    border: "1px solid #f50057"
  },
  tableMenu: {
    border: "none",
    paddingBottom: 0,
    paddingTop: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default MonthlyPaymentsList;
