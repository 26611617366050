import { gql } from "@apollo/client";

const CreateTrackingMutation = gql`
  mutation CreateTracking($creativeId: ID!, $userId: ID!) {
    createTracking(input: { creativeId: $creativeId, userId: $userId }) {
      tracking {
        id
      }
      error {
        errorType
        message
      }
    }
  }
`;

export default CreateTrackingMutation;
