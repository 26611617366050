import { gql } from "@apollo/client";

const CreateAccountPoolMutation = gql`
  mutation CreateAccountPool(
    $advertiserId: Int!
    $description: String
    $name: String!
    $variables: [CreateAccountPoolVariableInput!]!
  ) {
    createAccountPool(
      input: {
        advertiserId: $advertiserId
        description: $description
        name: $name
        variables: $variables
      }
    ) {
      accountPool {
        description
        id
        name
        variables {
          name
          description
        }
      }
    }
  }
`;

export default CreateAccountPoolMutation;
