import { useMutation } from "@apollo/client";
import clsx from "clsx";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Checkbox,
  DialogContentText,
  FormControl,
  IconButton,
  Input,
  makeStyles,
  Select,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core";
import AccountBox from "@material-ui/icons/AccountBox";
import CachedIcon from "@material-ui/icons/Cached";
import CloseIcon from "@material-ui/icons/Close";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SaveIcon from "@material-ui/icons/Save";
import SettingsIcon from "@material-ui/icons/Settings";

import { ApproveUser, ApproveUserVariables } from "../../generated/ApproveUser";
import {
  DeactivateUser,
  DeactivateUserVariables
} from "../../generated/DeactivateUser";
import {
  ReactivateUser,
  ReactivateUserVariables
} from "../../generated/ReactivateUser";
import { Roles_roles_roles } from "../../generated/Roles";
import {
  updateInvite,
  updateInviteVariables
} from "../../generated/updateInvite";
import { UpdateUser, UpdateUserVariables } from "../../generated/UpdateUser";
import { Users_users_users_roles } from "../../generated/Users";
import ApproveUserMutation from "../../mutations/ApproveUserMutation";
import DeactivateUserMutation from "../../mutations/DeactivateUserMutation";
import ReactivateUserMutation from "../../mutations/ReactivateUserMutation";
import UpdateInviteMutation from "../../mutations/UpdateInviteMutation";
import UpdateUserMutation from "../../mutations/UpdateUserMutation";
import UsersQuery from "../../queries/UsersQuery";

import ConfirmationAlert from "../ConfirmationAlert";
import InputTextMask, { phoneNumberMask } from "../InputTextMask";
import UserReferrerForm from "../UserReferrerForm";
import VersatileDialog from "../VersatileDialog";

export interface IUserListItemProps {
  active: boolean;
  approved: boolean;
  company: string | null;
  createdAt: string;
  disableButtons: boolean;
  displayId: number | null;
  email: string;
  fadeOut: boolean | null | undefined;
  handleSetApproveUsers: (userId: string, approve: boolean) => void;
  id: string;
  name: string;
  page: number;
  phoneNumber: string | null;
  referrer: {
    displayId: number | null;
    id: string;
    name: string;
  } | null;
  referrerEarningsPercentage: number;
  referrerRevsharePercentage: number;
  roleOptions: Roles_roles_roles[];
  roles: Users_users_users_roles[];
  selectMultiple: boolean;
}

const UserListItem = ({
  active,
  approved,
  company,
  createdAt,
  disableButtons,
  displayId,
  email,
  fadeOut,
  handleSetApproveUsers,
  id,
  name,
  page,
  phoneNumber,
  referrer,
  referrerEarningsPercentage,
  referrerRevsharePercentage,
  roleOptions,
  roles,
  selectMultiple
}: IUserListItemProps) => {
  const classes = useStyles();

  const noRoleValue = "";

  const [deactivateOpen, setDeactivateOpen] = useState(false);
  const [editingItem, setEditingItem] = useState(false);
  const [reactivateOpen, setReactivateOpen] = useState(false);
  const [approve, setApprove] = useState(false);
  const [referrerFormOpen, setReferrerFormOpen] = useState(false);
  const [reinviteDialogOpen, setReinviteDialogOpen] = useState(false);
  const [versatileOpen, setVersatileOpen] = useState(false);

  const [companyState, setCompany] = useState(company);
  const [emailState, setEmail] = useState(email);
  const [nameState, setName] = useState(name);
  const [phoneNumberState, setPhoneNumber] = useState(phoneNumber);
  const [roleState, setRole] = useState(roles[0]?.id || noRoleValue);

  useEffect(() => {
    setApprove(false);
  }, [selectMultiple]);

  const [approveUser, { loading: loadingApproveUser }] = useMutation<
    ApproveUser,
    ApproveUserVariables
  >(ApproveUserMutation);

  const [deactivateUser, { loading: loadingDeactivateUser }] = useMutation<
    DeactivateUser,
    DeactivateUserVariables
  >(DeactivateUserMutation);

  const [reactivateUser, { loading: loadingReactivateUser }] = useMutation<
    ReactivateUser,
    ReactivateUserVariables
  >(ReactivateUserMutation);

  const [updateUser, { loading: loadingUpdateUser }] = useMutation<
    UpdateUser,
    UpdateUserVariables
  >(UpdateUserMutation);

  const [reInvite, { loading: loadingInvite, data: inviteResp }] = useMutation<
    updateInvite,
    updateInviteVariables
  >(UpdateInviteMutation);

  const createdAtDateTime = new Date(createdAt);
  const formattedCreatedAtDate = createdAtDateTime.toLocaleDateString("en-US", {
    timeZone: "UTC"
  });
  const formattedCreatedAtTime = createdAtDateTime.toLocaleTimeString("en-US", {
    timeZone: "UTC"
  });

  const handleActiveClick = () => {
    if (active) {
      setDeactivateOpen(true);
    } else {
      setReactivateOpen(true);
    }
  };

  const handleApprove = () => {
    setApprove(false);
    approveUser({
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: UsersQuery, variables: { approved: false, page } },
        { query: UsersQuery, variables: { approved: true, page } }
      ],
      variables: { userId: id }
    });
  };

  const handleApproveCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    handleSetApproveUsers(id, event.target.checked);
    setApprove(event.target.checked);
  };

  const handleCancel = () => {
    setEmail(email);
    setName(name);
    setPhoneNumber(phoneNumber);
    setRole(roles[0]?.id || noRoleValue);
    setEditingItem(false);
  };

  const handleDeactivate = () => {
    setDeactivateOpen(false);
    deactivateUser({ variables: { userId: id } });
  };

  const handleReactivate = () => {
    setReactivateOpen(false);
    reactivateUser({ variables: { userId: id } });
  };

  const handleReinvite = () => {
    reInvite({ variables: { input: id } }).then(() => {
      setReinviteDialogOpen(false);
      setVersatileOpen(true);
    });
  };

  const handleSubmit = () => {
    const variables: UpdateUserVariables = { userId: id };

    if (companyState !== company) {
      variables.company = companyState;
    }
    if (emailState !== email) {
      variables.email = emailState;
    }
    if (nameState !== name) {
      variables.name = nameState;
    }
    if (phoneNumberState !== phoneNumber) {
      variables.phoneNumber = phoneNumberState;
    }
    if (roleState !== (roles[0]?.id || noRoleValue)) {
      variables.roleId = roleState || null;
    }

    if (Object.entries(variables).length === 1) {
      setEditingItem(false);
    } else {
      updateUser({ awaitRefetchQueries: true, variables }).then(() =>
        setEditingItem(false)
      );
    }
  };

  const tableRowContentsRender = editingItem ? (
    <>
      {approved && <TableCell>{displayId}</TableCell>}
      <TableCell>
        <TextField
          error={!nameState}
          helperText={!nameState ? "Name is required" : undefined}
          id={`name-${id}`}
          onChange={event => setName(event.target.value)}
          required
          value={nameState}
        />
      </TableCell>

      <TableCell>
        <TextField
          error={!emailState}
          helperText={!emailState ? "Email is required" : undefined}
          id={`email-${id}`}
          onChange={event => setEmail(event.target.value)}
          required
          value={emailState}
        />
      </TableCell>

      <TableCell>
        <TextField
          id={`company-${id}`}
          onChange={event => setCompany(event.target.value)}
          value={companyState || ""}
        />
      </TableCell>

      <TableCell>
        <FormControl>
          <Input
            id={`phone-number-${id}`}
            inputComponent={InputTextMask as any}
            inputProps={{ mask: phoneNumberMask }}
            name="phoneNumber"
            onChange={event => setPhoneNumber(event.target.value)}
            value={phoneNumberState}
          />
        </FormControl>
      </TableCell>

      <TableCell>
        {formattedCreatedAtDate}
        <br />
        {formattedCreatedAtTime}
      </TableCell>

      <TableCell>{referrer?.name}</TableCell>

      <TableCell>
        <FormControl className={classes.roleSelect}>
          <Select
            onChange={(event: ChangeEvent<{ value: unknown }>) =>
              setRole(event.target.value as string)
            }
            native
            required
            value={roleState}
          >
            {roleOptions.map(roleOption => (
              <option key={roleOption.id} value={roleOption.id}>
                {roleOption.name}
              </option>
            ))}

            <option value={noRoleValue}>None</option>
          </Select>
        </FormControl>
      </TableCell>

      <TableCell>
        <Checkbox
          checked={active}
          disabled={loadingDeactivateUser || loadingReactivateUser}
          onChange={handleActiveClick}
        />
      </TableCell>

      <TableCell>
        <IconButton disabled={loadingUpdateUser} onClick={handleSubmit}>
          <SaveIcon />
        </IconButton>

        <IconButton disabled={loadingUpdateUser} onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </TableCell>
    </>
  ) : (
    <>
      {approved && <TableCell>{displayId}</TableCell>}
      <TableCell>{name}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{company}</TableCell>
      <TableCell>{phoneNumber}</TableCell>
      <TableCell>
        {formattedCreatedAtDate}
        <br />
        {formattedCreatedAtTime}
      </TableCell>
      <TableCell>{referrer?.name}</TableCell>
      <TableCell>{roles[0]?.name}</TableCell>
      <TableCell>
        <Checkbox
          checked={active}
          disabled={
            loadingDeactivateUser ||
            loadingReactivateUser ||
            selectMultiple ||
            disableButtons
          }
          onChange={handleActiveClick}
        />
      </TableCell>
      <TableCell>
        <IconButton
          disabled={selectMultiple || disableButtons}
          onClick={() => setEditingItem(true)}
        >
          <SettingsIcon />
        </IconButton>

        {approved && (
          <>
            <IconButton onClick={() => setReferrerFormOpen(true)}>
              <PersonAddIcon />
            </IconButton>
            <IconButton onClick={() => setReinviteDialogOpen(true)}>
              <CachedIcon />
            </IconButton>
          </>
        )}

        <IconButton component={Link} to={`/users/${id}`}>
          <AccountBox />
        </IconButton>
      </TableCell>
    </>
  );

  return (
    <>
      <TableRow
        className={clsx(classes.tableRow, {
          [classes.fadeOut]: loadingApproveUser || fadeOut
        })}
      >
        {(!approved || fadeOut || loadingApproveUser) &&
          (selectMultiple ? (
            <TableCell className={classes.approveCheckbox}>
              <Checkbox
                checked={approve}
                disabled={loadingApproveUser || disableButtons}
                color="primary"
                onChange={handleApproveCheckbox}
              />
            </TableCell>
          ) : (
            <TableCell>
              <Button
                color="primary"
                disabled={loadingApproveUser || disableButtons}
                onClick={() => setApprove(true)}
                variant="contained"
              >
                Approve
              </Button>
            </TableCell>
          ))}
        {tableRowContentsRender}
      </TableRow>

      <UserReferrerForm
        onClose={() => setReferrerFormOpen(false)}
        open={referrerFormOpen}
        referrer={referrer || undefined}
        referrerEarningsPercentage={referrerEarningsPercentage}
        referrerRevsharePercentage={referrerRevsharePercentage}
        userId={id}
      />

      <ConfirmationAlert
        content="Deactivating an account will log out all existing sessions and prevent the user from logging in."
        onNegative={() => setDeactivateOpen(false)}
        onPositive={handleDeactivate}
        open={deactivateOpen}
        positiveAction="Deactivate"
        title={`Deativate ${name}'s account?`}
      />

      <ConfirmationAlert
        content="Reactivating an account will allow the user to log in and manage their offers again."
        onNegative={() => setReactivateOpen(false)}
        onPositive={handleReactivate}
        open={reactivateOpen}
        positiveAction="Reactivate"
        title={`Reactivate ${name}'s account?`}
      />

      <ConfirmationAlert
        content={`Are you sure you want to approve ${name}?`}
        onNegative={() => setApprove(false)}
        onPositive={handleApprove}
        open={approve && !selectMultiple}
        positiveAction="Approve"
        title={"Approve Confirm"}
      />

      <ConfirmationAlert
        content={`Are you sure you want to generate another invite link for ${name}?`}
        onNegative={() => setReinviteDialogOpen(false)}
        onPositive={handleReinvite}
        open={reinviteDialogOpen}
        positiveAction="Generate Link"
        title={"Generate Another Link"}
      />

      <VersatileDialog
        onClose={() => setVersatileOpen(false)}
        open={versatileOpen}
        loading={loadingInvite}
        title="New Invite Link"
      >
        {inviteResp?.updateInvite && (
          <DialogContentText>{inviteResp.updateInvite}</DialogContentText>
        )}
      </VersatileDialog>
    </>
  );
};

const useStyles = makeStyles(() => ({
  approveCheckbox: {
    minWidth: 135,
    textAlign: "center"
  },
  fadeOut: {
    opacity: 0.3
  },
  roleSelect: {
    minWidth: 100
  },
  tableRow: {
    transition: "opacity .5s"
  }
}));

export default UserListItem;
