import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles } from "@material-ui/core";

import {
  SubagreementLogs as SubagreementLogsQuery,
  SubagreementLogs_subagreementLogs
} from "../../generated/SubagreementLogs";
import SubLogsQuery from "../../queries/SubagreementLogsQuery";

import SubagreementLogsList from "../../components/SubagreementLogsList";
import { verticalsMap } from "../../utils/verticalsMap";

const SubagreementLogsListContainer = () => {
  const { offerId, userId } = useParams<{ offerId: string; userId: string }>();

  const classes = useStyles();

  const [after, setAfter] = useState<string>();
  const [before, setBefore] = useState<string>();

  const { loading, error, data } = useQuery<SubagreementLogsQuery>(
    SubLogsQuery,
    {
      errorPolicy: "all",
      variables: { after, before, offerId: +offerId!, userId }
    }
  );

  const logData = (() => {
    if (data && data.subagreementLogs) {
      return data.subagreementLogs;
    } else {
      const defaultData: SubagreementLogs_subagreementLogs = {
        __typename: "SubAgreementsLogsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    setAfter(logData.pageInfo.endCursor || undefined);
    setBefore(undefined);
  };

  const onPreviousPage = () => {
    setAfter(undefined);
    setBefore(logData.pageInfo.startCursor || undefined);
  };

  const checkAuthErrors = () => {
    return error?.graphQLErrors.every(err => err.message === "Not Authorised!");
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && !checkAuthErrors() && <div className={classes.errorBar} />}
      <SubagreementLogsList
        offer={offerId}
        hasNextPage={logData.pageInfo.hasNextPage}
        hasPreviousPage={logData.pageInfo.hasPreviousPage}
        logs={logData.edges.map(edge => edge.node)}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        verticals={verticalsMap}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  }
}));

export default SubagreementLogsListContainer;
