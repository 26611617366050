import { gql } from "@apollo/client";

const AccountPoolsQuery = gql`
  query AccountPools(
    $advertiserId: ID
    $after: ID
    $before: ID
    $limit: Int! = 50
    $search: String
  ) {
    accountPools(
      advertiserId: $advertiserId
      after: $after
      before: $before
      limit: $limit
      search: $search
    ) {
      edges {
        cursor
        node {
          description
          id
          name
          variables {
            description
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default AccountPoolsQuery;
