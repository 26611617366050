import { gql } from "@apollo/client";

const MonthlyPaymentsQuery = gql`
  query monthlyPayments(
    $after: ID
    $before: ID
    $limit: Int! = 20
    $month: DateTime!
    $userId: ID
  ) {
    monthlyPayments(
      month: $month
      after: $after
      before: $before
      limit: $limit
      userId: $userId
    ) {
      edges {
        cursor
        node {
          id
          adjustmentsTotal
          cpaTotal
          month
          revshareTotal
          partnerId
          partnerDisplayId
          partnerName
          subagreement {
            id
            affiliateDetails {
              commissionType
              currency
              ... on AgreementClawback {
                actionType
                cpaAmount
                revsharePercentages
              }
              ... on AgreementCpa {
                actionType
                cpaAmount
              }
            }
            offer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default MonthlyPaymentsQuery;
