import { gql } from "@apollo/client";

const DeleteWebhookMutation = gql`
  mutation DeleteWebhook($webhookId: ID!) {
    deleteWebhook(webhookId: $webhookId) {
      success
    }
  }
`;

export default DeleteWebhookMutation;
