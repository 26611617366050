import { useLazyQuery, useMutation } from "@apollo/client";
import React, { FormEvent, useCallback, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";

import { Click as IClickQuery } from "../../generated/Click";
import {
  CreateTransaction,
  CreateTransactionVariables
} from "../../generated/CreateTransaction";
import {
  TransactionCurrency,
  TransactionType
} from "../../generated/globalTypes";
import CreateTransactionMutation from "../../mutations/CreateTransactionMutation";
import ClickQuery from "../../queries/ClickQuery";

import ConfirmationAlert from "../ConfirmationAlert";
import ProgressAlert from "../ProgressAlert";

interface IConversionFormProps {
  clickId: string;
  onClose: () => void;
  open: boolean;
  playerId: string | null;
}

const ConversionForm = ({
  clickId,
  onClose,
  open,
  playerId
}: IConversionFormProps) => {
  const emptyStringValue = undefined;

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [progressAlertOpen, setProgressAlertOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [conversionType, setConversionType] = useState<TransactionType>();
  const [conversionDate, setConversionDate] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [currency, setCurrency] = useState<TransactionCurrency>();

  const [addingTransaction, setAddingTransaction] = useState(false);
  const [addingTransactionText, setAddingTransactionText] = useState<string>();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const [clickQuery, { data: clickQueryData, stopPolling }] = useLazyQuery<
    IClickQuery
  >(ClickQuery, {
    fetchPolicy: "network-only",
    pollInterval: 1000,
    variables: { clickId }
  });

  const [createTransaction, { data: createTransactionData }] = useMutation<
    CreateTransaction,
    CreateTransactionVariables
  >(CreateTransactionMutation);

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

  const onCloseReset = useCallback(() => {
    setAddingTransaction(false);
    setAddingTransactionText(emptyStringValue);
    setConversionType(emptyStringValue);
    setConversionDate(emptyStringValue);
    setAmount(emptyStringValue);
    setCurrency(emptyStringValue);
    setDirty(false);
    setTimeoutId(undefined);
  }, [emptyStringValue]);

  useEffect(() => {
    if (addingTransaction && !timeoutId) {
      const timeout1Id = setTimeout(() => {
        setAddingTransactionText(transactionWaitingText10);

        const timeout2Id = setTimeout(() => {
          setAddingTransactionText(transactionWaitingText30);
          setProgressAlertOpen(false);
          setConfirmOpen(true);
        }, 30000);

        setTimeoutId(timeout2Id);
      }, 10000);

      setTimeoutId(timeout1Id);
      setProgressAlertOpen(true);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [addingTransaction, emptyStringValue, timeoutId]);

  useEffect(() => {
    if (
      addingTransaction &&
      clickQueryData?.click?.click?.transactions.find(
        transaction =>
          transaction.id ===
          createTransactionData?.createTransaction?.transaction?.id
      )
    ) {
      stopPolling!();
      setProgressAlertOpen(false);
      onClose();
    }
  }, [
    addingTransaction,
    clickQueryData,
    createTransactionData,
    onClose,
    stopPolling
  ]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables: CreateTransactionVariables = {
      amount,
      clickId,
      currency,
      transactionAt: conversionDate,
      transactionType: conversionType!
    };

    setAddingTransaction(true);

    createTransaction({
      variables
    }).then(() => {
      clickQuery();
    });
  };

  const dialogTitle = "Add Conversion";
  const dialogText =
    'Note: If you need to add/change revshare, just add the player revenue and the Revenue Calculator will handle the rest. If you need to add/change a CPA payout, just add a deposit and flag it as "qualified"';
  const transactionWaitingText10 =
    "Please wait one moment, we are adding the conversion…";
  const transactionWaitingText30 =
    "This conversion is taking longer than normal, please check this page again after a few minutes before trying to add this conversion again. If problems persist, please contact support.";

  return (
    <>
      <Dialog onClose={handleClose} onExited={onCloseReset} open={open}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent className={classes.formInputs}>
            <DialogContentText>{dialogText}</DialogContentText>

            <FormControl className={classes.input} required>
              <InputLabel id="conversionType">Conversion Type</InputLabel>
              <Select
                labelId="conversionType"
                id="conversionTypeSelect"
                onChange={event => {
                  setDirty(true);
                  setConversionType(event.target.value as TransactionType);
                }}
                required
                value={conversionType || ""}
              >
                <MenuItem value={TransactionType.INSTALL}>Install</MenuItem>
                <MenuItem value={TransactionType.REGISTRATION}>
                  Registration
                </MenuItem>
                <MenuItem value={TransactionType.DEPOSIT}>Deposit</MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.input}>
              <InputLabel htmlFor="date" shrink={true}>
                Date
              </InputLabel>
              <Input
                className={classes.input}
                id="date"
                onChange={event => {
                  setDirty(true);
                  setConversionDate(event.target.value);
                }}
                required
                type="date"
                value={conversionDate || ""}
              />
            </FormControl>

            {conversionType === TransactionType.DEPOSIT && (
              <>
                <TextField
                  className={classes.input}
                  id="amount"
                  label="Amount"
                  onChange={event => {
                    setDirty(true);
                    setAmount(
                      event.target.value
                        ? parseFloat(event.target.value)
                        : emptyStringValue
                    );
                  }}
                  required
                  type="number"
                  value={amount === undefined ? "" : amount}
                />

                <FormControl className={classes.input} required>
                  <InputLabel id="currency">Currency</InputLabel>
                  <Select
                    labelId="currency"
                    id="currency"
                    onChange={event => {
                      setDirty(true);
                      setCurrency(event.target.value as TransactionCurrency);
                    }}
                    required
                    value={currency || ""}
                  >
                    <MenuItem value={TransactionCurrency.USD}>
                      US Dollars ($)
                    </MenuItem>
                    <MenuItem value={TransactionCurrency.EUR}>
                      Euros (€)
                    </MenuItem>
                    <MenuItem value={TransactionCurrency.GBP}>
                      British Pounds (£)
                    </MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={addingTransaction}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content={
          addingTransaction
            ? transactionWaitingText30
            : "Closing this form will lose any unsaved progress."
        }
        onExited={onClose}
        onNegative={addingTransaction ? undefined : () => setConfirmOpen(false)}
        onPositive={async () => {
          setConfirmOpen(false);
        }}
        open={confirmOpen}
        positiveAction={addingTransaction ? "OK" : "Discard"}
        title={addingTransaction ? "Adding Transaction" : "Unsaved Changes"}
      />

      <ProgressAlert
        message={progressAlertOpen ? addingTransactionText : emptyStringValue}
        open={progressAlertOpen}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  form: {
    padding: spacing(2)
  },
  formInputs: {
    display: "flex",
    flexDirection: "column"
  },
  input: {
    margin: spacing(1, 0)
  }
}));

export default ConversionForm;
