import React from "react";

import { Typography } from "@material-ui/core";

import { PaymentMethod, UserCurrency } from "../../generated/globalTypes";
import getPaymentMethodDisplay from "../../utils/getPaymentMethodDisplay";

interface IPaymentDetailsCardProps {
  bankName?: string;
  bankAccountNumber?: string | null;
  bankAddress1?: string | null;
  bankAddress2?: string | null;
  bankCity?: string | null;
  bankState?: string | null;
  bankCountry?: string | null;
  bankPostalCode?: string | null;
  beneficiaryAddress1?: string | null;
  beneficiaryAddress2?: string | null;
  beneficiaryCity?: string | null;
  beneficiaryName?: string | null;
  beneficiaryState?: string | null;
  beneficiaryCountry?: string | null;
  beneficiaryPostalCode?: string | null;
  branchCode?: string | null;
  companyRegistrationNumber?: string | null;
  currency?: UserCurrency | null;
  ecoPayzEmail?: string | null;
  ibanNumber?: string | null;
  netellerEmail?: string | null;
  netellerId?: string | null;
  netellerName?: string | null;
  payeeName: string;
  paymentMethod: PaymentMethod;
  paypalEmail?: string | null;
  paypalName?: string | null;
  playerAccountHolderName?: string | null;
  playerAccountNumber?: string | null;
  recipientName?: string | null;
  recipientAddress1?: string | null;
  recipientAddress2?: string | null;
  recipientCity?: string | null;
  recipientState?: string | null;
  recipientCountry?: string | null;
  recipientPostalCode?: string | null;
  reference?: string | null;
  routingNumber?: string | null;
  skrillEmail?: string | null;
  skrillAccountHolderName?: string | null;
  skrillAccountNumber?: string | null;
  skrillAddress1?: string | null;
  skrillAddress2?: string | null;
  skrillCity?: string | null;
  skrillState?: string | null;
  skrillCountry?: string | null;
  skrillPostalCode?: string | null;
  sortCode?: string | null;
  swiftBicCode?: string | null;
  taxCountry?: string | null;
  vat?: string | null;
  walletAddress?: string | null;
}
const PaymentDetailsCard = ({
  bankName,
  bankAccountNumber,
  bankAddress1,
  bankAddress2,
  bankCity,
  bankState,
  bankCountry,
  bankPostalCode,
  beneficiaryAddress1,
  beneficiaryAddress2,
  beneficiaryCity,
  beneficiaryName,
  beneficiaryState,
  beneficiaryCountry,
  beneficiaryPostalCode,
  branchCode,
  companyRegistrationNumber,
  currency,
  ecoPayzEmail,
  ibanNumber,
  netellerEmail,
  netellerId,
  netellerName,
  payeeName,
  paymentMethod,
  paypalEmail,
  paypalName,
  playerAccountHolderName,
  playerAccountNumber,
  recipientName,
  recipientAddress1,
  recipientAddress2,
  recipientCity,
  recipientState,
  recipientCountry,
  recipientPostalCode,
  reference,
  routingNumber,
  skrillEmail,
  skrillAccountHolderName,
  skrillAccountNumber,
  skrillAddress1,
  skrillAddress2,
  skrillCity,
  skrillState,
  skrillCountry,
  skrillPostalCode,
  sortCode,
  swiftBicCode,
  taxCountry,
  vat,
  walletAddress
}: IPaymentDetailsCardProps) => {
  const renderPaymentMethodDetails = () => {
    if (paymentMethod === PaymentMethod.BANKWIRE) {
      return (
        <>
          <Typography>{`Beneficiary Name: ${beneficiaryName}`}</Typography>
          <Typography>{`Beneficiary Address 1: ${beneficiaryAddress1 ||
            "N/A"}`}</Typography>
          <Typography>{`Beneficiary Address 2: ${beneficiaryAddress2 ||
            "N/A"}`}</Typography>
          <Typography>{`Beneficiary City: ${beneficiaryCity ||
            "N/A"}`}</Typography>
          <Typography>{`Beneficiary State/Province: ${beneficiaryState ||
            "N/A"}`}</Typography>
          <Typography>{`Beneficiary Country: ${beneficiaryCountry ||
            "N/A"}`}</Typography>
          <Typography>{`Beneficiary Postal Code: ${beneficiaryPostalCode ||
            "N/A"}`}</Typography>
          <Typography>{`Bank Name: ${bankName}`}</Typography>
          <Typography>{`Bank Address 1: ${bankAddress1}`}</Typography>
          <Typography>{`Bank Address 2: ${bankAddress2 || "N/A"}`}</Typography>
          <Typography>{`Bank City: ${bankCity}`}</Typography>
          <Typography>{`Bank State/Province: ${bankState ||
            "N/A"}`}</Typography>
          <Typography>{`Bank Country: ${bankCountry}`}</Typography>
          <Typography>{`Bank Postal Code: ${bankPostalCode}`}</Typography>
          <Typography>{`Account Number: ${bankAccountNumber ||
            "N/A"}`}</Typography>
          <Typography>{`Routing Number: ${routingNumber || "N/A"}`}</Typography>
          <Typography>{`Branch Code: ${branchCode || "N/A"}`}</Typography>
          <Typography>{`Sort Code: ${sortCode || "N/A"}`}</Typography>
          <Typography>{`Swift/BIC: ${swiftBicCode}`}</Typography>
          <Typography>{`IBAN: ${ibanNumber}`}</Typography>
          <Typography>{`Reference: ${reference || "N/A"}`}</Typography>
        </>
      );
    } else if (paymentMethod === PaymentMethod.BTCTRANSFER) {
      return <Typography>{`Wallet Address: ${walletAddress}`}</Typography>;
    } else if (paymentMethod === PaymentMethod.CHECK) {
      return (
        <>
          <Typography>{`Recipient Name: ${recipientName}`}</Typography>
          <Typography>{`Recipient Address 1: ${recipientAddress1}`}</Typography>
          <Typography>{`Recipient Address 2: ${recipientAddress2 ||
            "N/A"}`}</Typography>
          <Typography>{`Recipient City: ${recipientCity}`}</Typography>
          <Typography>{`Recipient State: ${recipientState ||
            "N/A"}`}</Typography>
          <Typography>{`Recipient Country: ${recipientCountry}`}</Typography>
          <Typography>{`Recipient Postal Code: ${recipientPostalCode}`}</Typography>
        </>
      );
    } else if (paymentMethod === PaymentMethod.ECOPAYZ) {
      return <Typography>{`Email Address: ${ecoPayzEmail}`}</Typography>;
    } else if (paymentMethod === PaymentMethod.NETELLER) {
      return (
        <>
          <Typography>{`Email Address: ${netellerEmail}`}</Typography>
          <Typography>{`Account ID: ${netellerId || "N/A"}`}</Typography>
          <Typography>{`Name: ${netellerName || "N/A"}`}</Typography>
        </>
      );
    } else if (paymentMethod === PaymentMethod.PAYPAL) {
      return (
        <>
          <Typography>{`Email Address: ${paypalEmail}`}</Typography>
          <Typography>{`Name: ${paypalName || "N/A"}`}</Typography>
        </>
      );
    } else if (paymentMethod === PaymentMethod.PLAYERACCOUNT) {
      return (
        <>
          <Typography>{`Name: ${playerAccountHolderName}`}</Typography>
          <Typography>{`Account Number: ${playerAccountNumber ||
            "N/A"}`}</Typography>
        </>
      );
    } else if (paymentMethod === PaymentMethod.SKRILL) {
      return (
        <>
          <Typography>{`Email Address: ${skrillEmail}`}</Typography>
          <Typography>{`Account Number: ${skrillAccountNumber ||
            "N/A"}`}</Typography>
          <Typography>{`Name: ${skrillAccountHolderName || "N/A"}`}</Typography>
          <Typography>{`Address 1: ${skrillAddress1 || "N/A"}`}</Typography>
          <Typography>{`Address 2: ${skrillAddress2 || "N/A"}`}</Typography>
          <Typography>{`City: ${skrillCity || "N/A"}`}</Typography>
          <Typography>{`State: ${skrillState || "N/A"}`}</Typography>
          <Typography>{`Country: ${skrillCountry || "N/A"}`}</Typography>
          <Typography>{`Postal Code: ${skrillPostalCode || "N/A"}`}</Typography>
        </>
      );
    }
  };

  return (
    <>
      <Typography>{`Payee Name: ${payeeName}`}</Typography>
      <Typography>{`Company Registration Number: ${companyRegistrationNumber ||
        "N/A"}`}</Typography>
      <Typography>{`Payment Method: ${getPaymentMethodDisplay(
        paymentMethod
      )}`}</Typography>
      <Typography>{`Currency: ${currency}`}</Typography>
      {renderPaymentMethodDetails()}
      {taxCountry && (
        <Typography>{`Tax Country: ${taxCountry || "N/A"}`}</Typography>
      )}
      {vat && <Typography>{`VAT: ${vat || "N/A"}`}</Typography>}
    </>
  );
};

export default PaymentDetailsCard;
