import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import AdvertiserShow from "../../components/AdvertiserShow";
import { Advertiser as IAdvertiserQuery } from "../../generated/Advertiser";
import AdvertiserQuery from "../../queries/AdvertiserQuery";

const AdvertiserShowContainer = () => {
  const { advertiserId: rawAdvertiserId } = useParams<{
    advertiserId: string;
  }>();
  const advertiserId = parseInt(rawAdvertiserId as string, 10);

  const classes = useStyles();

  const { loading, error, data } = useQuery<IAdvertiserQuery>(AdvertiserQuery, {
    variables: { advertiserId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  // TODO: Clean this check for the right data up
  if (
    error ||
    !data ||
    !data.advertiser ||
    data.advertiser.error ||
    !data.advertiser.advertiser
  ) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return <AdvertiserShow {...data.advertiser.advertiser} />;
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default AdvertiserShowContainer;
