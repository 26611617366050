import { gql } from "@apollo/client";

const BulkUserExportQuery = gql`
  query BulkUserExportPayload {
    bulkUserExport {
      downloadLink
    }
  }
`;

export default BulkUserExportQuery;
