import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import UserShow from "../../components/UserShow";

import { User as IUser } from "../../generated/User";

import UserQuery from "../../queries/UserQuery";

const UserShowContainer = () => {
  const { userId } = useParams<{ userId: string }>();

  const classes = useStyles();

  const { loading, error, data } = useQuery<IUser>(UserQuery, {
    variables: { userId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  if (error || !data || !data.user || data.user.error || !data.user.user) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return (
    <>
      {loading && <LinearProgress />}
      <UserShow
        {...data.user.user}
        paymentDetails={
          data.user.user.paymentDetails?.paymentDetailsImplementation || null
        }
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default UserShowContainer;
