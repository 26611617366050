import React from "react";

import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Popover,
  Typography
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";

import { VerticalType } from "../../generated/globalTypes";
import { IVerticalMap } from "../../utils/verticalsMap";

export interface IRevsharePercentage {
  revsharePercentages: { [key in VerticalType]?: number };
  verticals: IVerticalMap;
}

const RevsharePercentage = ({
  revsharePercentages,
  verticals
}: IRevsharePercentage) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return Object.keys(revsharePercentages).length > 1 ? (
    <>
      <Typography className={classes.nowrap}>
        {`${revsharePercentages.default}%`}
        <IconButton
          className={classes.icon}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <ListIcon />
        </IconButton>
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top"
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List dense>
          {Object.entries(verticals)
            .filter(v => revsharePercentages![v[1]])
            .map(v => (
              <ListItem key={v[1]}>
                <ListItemText
                  primary={`${v[0]} ${revsharePercentages![v[1]]}%`}
                />
              </ListItem>
            ))}
        </List>
      </Popover>
    </>
  ) : (
    <Typography>{`${revsharePercentages.default}%`}</Typography>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    borderRadius: 0,
    margin: spacing(0, 1),
    padding: 0
  },
  nowrap: {
    whiteSpace: "nowrap"
  },
  popover: {
    pointerEvents: "none"
  }
}));

export default RevsharePercentage;
