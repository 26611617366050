import { gql } from "@apollo/client";

const UserQuery = gql`
  query User($userId: String!) {
    user(userId: $userId) {
      error {
        errorType
      }
      user {
        approved
        company
        currency
        customAttributes
        displayId
        email
        id
        name
        paymentDetails {
          id
          paymentDetailsImplementation {
            payeeName
            companyRegistrationNumber
            currency
            paymentMethod
            taxCountry
            vat
            ... on PaymentDetailsBankWire {
              bankName
              bankAccountNumber
              bankAddress1
              bankAddress2
              bankCity
              bankState
              bankCountry
              bankPostalCode
              beneficiaryName
              beneficiaryAddress1
              beneficiaryAddress2
              beneficiaryCity
              beneficiaryState
              beneficiaryCountry
              beneficiaryPostalCode
              routingNumber
              branchCode
              sortCode
              swiftBicCode
              ibanNumber
              reference
            }
            ... on PaymentDetailsBtcTransfer {
              walletAddress
            }
            ... on PaymentDetailsCheck {
              recipientName
              recipientAddress1
              recipientAddress2
              recipientCity
              recipientState
              recipientCountry
              recipientPostalCode
            }
            ... on PaymentDetailsEcoPayz {
              ecoPayzEmail
            }
            ... on PaymentDetailsNeteller {
              netellerId
              netellerEmail
              netellerName
            }
            ... on PaymentDetailsPaypal {
              paypalEmail
              paypalName
            }
            ... on PaymentDetailsPlayerAccount {
              playerAccountHolderName
              playerAccountNumber
            }
            ... on PaymentDetailsSkrill {
              skrillEmail
              skrillAccountNumber
              skrillAccountHolderName
              skrillAddress1
              skrillAddress2
              skrillCity
              skrillState
              skrillCountry
              skrillPostalCode
            }
          }
        }
        phoneNumber
      }
    }
  }
`;

export default UserQuery;
