import { gql } from "@apollo/client";

const RolesQuery = gql`
  query Roles($page: Int) {
    roles(page: $page) {
      roles {
        id
        name
      }
    }
  }
`;

export default RolesQuery;
