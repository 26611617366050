import { useApolloClient } from "@apollo/client";
import { Button, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";

import { UserCurrency } from "../../generated/globalTypes";
import { User_user_user_paymentDetails_paymentDetailsImplementation as IPaymentDetails } from "../../generated/User";

import PaymentDetailsCard from "../PaymentDetailsCard";

import PartnerViewIdQuery from "../../queries/PartnerViewIdQuery";

export interface IUserProperties {
  id: string;
  customAttributes: {};
  currency: UserCurrency | null;
  displayId: number | null;
  email: string;
  name: string;
  paymentDetails: IPaymentDetails | null;
}

const UserShow = ({
  id,
  customAttributes,
  currency,
  displayId,
  email,
  name,
  paymentDetails
}: IUserProperties) => {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();

  const attributeEntries: Array<[string, string]> = Object.entries(
    customAttributes
  );

  const handlePartnerOfferView = () => {
    client.writeQuery({
      data: {
        partnerViewCurrency: currency,
        partnerViewId: id
      },
      query: PartnerViewIdQuery
    });

    history.push("/offers");
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h4">User Information</Typography>
        </div>

        <div className={classes.personal}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6">Personal</Typography>
          </div>
          <Typography>{`Email: ${email}`}</Typography>
          <Typography>{`Name: ${name} (${displayId})`}</Typography>
          {attributeEntries.map(([key, value], index) => {
            const attributeKey = key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");

            return (
              <Typography
                key={index}
              >{`${attributeKey[0].toUpperCase()}${attributeKey.slice(
                1
              )}: ${value.toLowerCase()}`}</Typography>
            );
          })}
        </div>

        <div className={classes.sectionHeader}>
          <Typography variant="h6">Payment Details</Typography>
        </div>
        {paymentDetails ? (
          <PaymentDetailsCard {...paymentDetails} />
        ) : (
          <Typography>N/A</Typography>
        )}
        <div className={classes.partnerView}>
          <Button
            color="primary"
            variant="contained"
            onClick={handlePartnerOfferView}
          >
            View Offers as User
          </Button>
        </div>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  header: { paddingBottom: spacing() },
  paper: { padding: spacing(3) },
  partnerView: {
    display: "flex",
    justifyContent: "flex-end"
  },
  personal: { paddingBottom: spacing(2) },
  sectionHeader: { alignItems: "center", display: "flex" }
}));

export default UserShow;
