import React, { ReactNode } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress
} from "@material-ui/core";

interface IVersatileDialogProps {
  onClose: () => void;
  open: boolean;
  loading?: boolean;
  title: string;
  children?: ReactNode;
}

const VersatileDialog = ({
  onClose,
  open,
  loading,
  title,
  children
}: IVersatileDialogProps) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {loading && <LinearProgress />}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default VersatileDialog;
