import { gql } from "@apollo/client";

const DeactivateUserMutation = gql`
  mutation DeactivateUser($userId: String!) {
    deactivateUser(userId: $userId) {
      user {
        active
        id
      }
      error {
        errorType
      }
    }
  }
`;

export default DeactivateUserMutation;
