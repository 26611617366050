import { gql } from "@apollo/client";

const OffersQuery = gql`
  query Offers(
    $after: ID
    $before: ID
    $brandId: Int
    $limit: Int! = 50
    $search: String
    $userId: String
  ) {
    offers(
      after: $after
      before: $before
      brandId: $brandId
      limit: $limit
      search: $search
      userId: $userId
    ) {
      edges {
        cursor
        node {
          accountPool {
            id
            name
          }
          active
          advertiserAccountRequired
          brand {
            advertiser {
              id
              name
            }
            id
            name
            trackingLink
            trackingLinkAppend
          }
          id
          mobileOptimized
          name
          overview
          playerBonuses
          terms
          trackingLink
          trackingLinkAppend
          vertical
          verticals
          visible
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default OffersQuery;
