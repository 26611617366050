import { useMutation } from "@apollo/client";
import React, { MouseEvent, useState } from "react";

import {
  Checkbox,
  IconButton,
  makeStyles,
  TableCell,
  TableRow
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";

import { useAuth0 } from "../../utils/auth0Provider";

import { DeactivateCreative } from "../../generated/DeactivateCreative";
import { LanguageCode } from "../../generated/globalTypes";
import { ReactivateCreative } from "../../generated/ReactivateCreative";
import DeactivateCreativeMutation from "../../mutations/DeactivateCreativeMutation";
import ReactivateCreativeMutation from "../../mutations/ReactivateCreative";
import { Language } from "../../utils/language";

import { Offer_offer_offer_brand } from "../../generated/Offer";
import { DestinationLinkOffer } from "../../utils/destinationLinkPreview/types";
import ConfirmationAlert from "../ConfirmationAlert";
import CreativeForm from "../CreativeForm";
import TrackingGenerator from "../TrackingGenerator";

interface ICreativeListItemProps {
  active: boolean;
  id: number;
  imageHeight: number | null;
  imageUrl: string | null;
  imageWidth: number | null;
  languageCode: LanguageCode;
  name: string;
  offer: DestinationLinkOffer;
  brand: Offer_offer_offer_brand;
  trackingLink: string | null;
  trackingLinkAppend: string | null;
  visible: boolean;
}

const CreativeListItem = ({
  active,
  id,
  imageHeight,
  imageUrl,
  imageWidth,
  languageCode,
  name,
  offer,
  brand,
  trackingLink,
  trackingLinkAppend,
  visible
}: ICreativeListItemProps) => {
  const classes = useStyles();
  const { hasPermission } = useAuth0();
  const updateCreatives = hasPermission("manage:creatives");
  const offerId = offer.id;
  const [activeConfirmationOpen, setActiveConfirmationOpen] = useState(false);
  const [trackingLinkOpen, setTrackingLinkOpen] = useState(false);
  const [updateCreativeOpen, setUpdateCreativeOpen] = useState(false);

  const [deactivateCreative, { loading: deactivateLoading }] = useMutation<
    DeactivateCreative
  >(DeactivateCreativeMutation, {
    variables: { creativeId: id }
  });

  const [reactivateCreative, { loading: reactivateLoading }] = useMutation<
    ReactivateCreative
  >(ReactivateCreativeMutation, { variables: { creativeId: id } });

  const handleClose = () => {
    setTrackingLinkOpen(false);
  };

  const handleLinkClick = () => {
    setTrackingLinkOpen(true);
  };

  const onActiveClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActiveConfirmationOpen(true);
  };

  const onConfirmationNegative = () => {
    setActiveConfirmationOpen(false);
  };

  const onConfirmationPositive = () => {
    setActiveConfirmationOpen(false);
    active ? deactivateCreative() : reactivateCreative();
  };

  const activeConfirmationContent = active
    ? "Deactivating a creative will prevent further click tracking."
    : "Reactivating a creative will reactivate the parent offer and brand.";
  const activeConfirmationPositiveAction = active ? "Deactivate" : "Reactivate";
  const activeConfirmationTitle = active
    ? `Deactivate Creative: ${name}`
    : `Reactivate Creative: ${name}`;

  return (
    <>
      <TableRow>
        <TableCell className={classes.cellId}>{id}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{Language[languageCode]}</TableCell>
        <TableCell>
          {imageUrl ? <img alt="" height={60} src={imageUrl} /> : "None"}
        </TableCell>
        <TableCell>{imageWidth && `${imageWidth}px`}</TableCell>
        <TableCell>{imageHeight && `${imageHeight}px`}</TableCell>
        <TableCell>
          <Checkbox
            checked={active}
            disabled={
              !updateCreatives || deactivateLoading || reactivateLoading
            }
            onClick={onActiveClick}
          />
        </TableCell>
        {updateCreatives && (
          <TableCell>
            <IconButton onClick={() => setUpdateCreativeOpen(true)}>
              <EditIcon />
            </IconButton>
          </TableCell>
        )}
        <TableCell>
          <IconButton onClick={handleLinkClick}>
            <LinkIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      {updateCreatives && (
        <>
          <ConfirmationAlert
            content={activeConfirmationContent}
            onNegative={onConfirmationNegative}
            onPositive={onConfirmationPositive}
            open={activeConfirmationOpen}
            positiveAction={activeConfirmationPositiveAction}
            title={activeConfirmationTitle}
          />

          <CreativeForm
            creativeId={id}
            imageUrl={imageUrl}
            languageCode={languageCode}
            name={name}
            offer={offer}
            brand={brand}
            onClose={() => setUpdateCreativeOpen(false)}
            open={updateCreativeOpen}
            trackingLink={trackingLink}
            trackingLinkAppend={trackingLinkAppend}
            type="update"
            visible={visible}
          />
        </>
      )}

      <TrackingGenerator
        creativeId={id}
        creativeImageHeight={imageHeight}
        creativeImageUrl={imageUrl}
        creativeImageWidth={imageWidth}
        offerId={offerId}
        onClose={handleClose}
        open={trackingLinkOpen}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  cellId: { textAlign: "right" }
}));

export default CreativeListItem;
