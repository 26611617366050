import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { CreateTracking } from "../../generated/CreateTracking";
import CreateTrackingMutation from "../../mutations/CreateTrackingMutation";
import PartnersSearchBox from "../PartnersSearchBox";

interface ITrackingGeneratorProps {
  creativeId: number;
  creativeImageHeight?: number | null;
  creativeImageUrl?: string | null;
  creativeImageWidth?: number | null;
  offerId: number;
  onClose: () => void;
  open: boolean;
}

const TrackingGenerator = ({
  creativeId,
  creativeImageHeight,
  creativeImageUrl,
  creativeImageWidth,
  offerId,
  onClose,
  open,
}: ITrackingGeneratorProps) => {
  const classes = useStyles();

  const { hasPermission, user } = useAuth0();
  const createAllTracking = hasPermission("manage:trackings");

  const [marketingType, setMarketingType] = useState<string>();
  const [userIdState, setUserId] = useState(
    createAllTracking ? undefined : user?.sub,
  );
  const [subId1, setSubId1] = useState<string | undefined>("");
  const [subId2, setSubId2] = useState<string | undefined>("");
  const [subId3, setSubId3] = useState<string | undefined>("");
  const [subId4, setSubId4] = useState<string | undefined>("");
  const [subId5, setSubId5] = useState<string | undefined>("");

  const [
    createTracking,
    { data: trackingData, loading: createTrackingLoading },
  ] = useMutation<CreateTracking>(CreateTrackingMutation);

  const handleSelect = (setFunction: (value?: string) => void) => (
    event: ChangeEvent<{ value: unknown }>,
  ) => {
    setFunction(event.target.value as string);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (userIdState && marketingType) {
      createTracking({
        variables: { creativeId, userId: userIdState },
      });
    }
  };

  const buildTrackingLink = (trackingId: string) => {
    const subIds = [subId1, subId2, subId3, subId4, subId5];
    let rawLink = `${process.env.REACT_APP_TRACKING_URI}/track/${trackingId}`;

    if (marketingType) {
      rawLink += `?type=${marketingType}`;

      subIds.forEach((subId, index) => {
        if (subId) {
          rawLink += `&s${index + 1}=${subId}`;
        }
      });
    }

    if (creativeImageUrl) {
      let imageLink = `<a href="${rawLink}">`;
      imageLink += `<img src="${creativeImageUrl}" width="${creativeImageWidth}" height="${creativeImageHeight}" alt="" />`;
      imageLink += "</a>";
      return imageLink;
    }

    return rawLink;
  };
  return (
    <Dialog onClose={onClose} open={open}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Tracking Link Generator</DialogTitle>

        <DialogContent>
          {createAllTracking && (
            <PartnersSearchBox
              offerId={offerId}
              setUserId={setUserId}
              createAllTracking={createAllTracking}
            />
          )}

          <FormControl className={classes.formControl} fullWidth required>
            <InputLabel id="marketing-type-select-label">
              Marketing Type
            </InputLabel>
            <Select
              id="marketing-type-select"
              labelId="marketing-type-select-label"
              onChange={handleSelect(setMarketingType)}
              required
              value={marketingType || ""}
            >
              <MenuItem value="audio">Audio</MenuItem>
              <MenuItem value="cpv">CPV</MenuItem>
              <MenuItem value="display">Display</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="facebook">Facebook</MenuItem>
              <MenuItem value="influencer">Influencer</MenuItem>
              <MenuItem value="instagram">Instagram</MenuItem>
              <MenuItem value="native">Native</MenuItem>
              <MenuItem value="offline">Offline</MenuItem>
              <MenuItem value="podcast">Podcast</MenuItem>
              <MenuItem value="pop">POP</MenuItem>
              <MenuItem value="ppc">PPC</MenuItem>
              <MenuItem value="push">Push</MenuItem>
              <MenuItem value="seo">SEO</MenuItem>
              <MenuItem value="snapchat">Snapchat</MenuItem>
              <MenuItem value="social">Social</MenuItem>
            </Select>
          </FormControl>

          <TextField
            className={classes.formControl}
            id="subId1"
            fullWidth
            label="SubID 1"
            onChange={handleSelect(setSubId1)}
            value={subId1 || ""}
          />

          <TextField
            className={classes.formControl}
            id="subId2"
            fullWidth
            label="SubID 2"
            onChange={handleSelect(setSubId2)}
            value={subId2 || ""}
          />

          <TextField
            className={classes.formControl}
            id="subId3"
            fullWidth
            label="SubID 3"
            onChange={handleSelect(setSubId3)}
            value={subId3 || ""}
          />

          <TextField
            className={classes.formControl}
            id="subId4"
            fullWidth
            label="SubID 4"
            onChange={handleSelect(setSubId4)}
            value={subId4 || ""}
          />

          <TextField
            className={classes.formControl}
            id="subId5"
            fullWidth
            label="SubID 5"
            onChange={handleSelect(setSubId5)}
            value={subId5 || ""}
          />

          {createTrackingLoading && <CircularProgress />}
          {trackingData &&
            trackingData.createTracking &&
            trackingData.createTracking.error && (
              <DialogContentText color="error">
                {trackingData.createTracking.error.message}
              </DialogContentText>
            )}
          {trackingData &&
            trackingData.createTracking &&
            trackingData.createTracking.tracking && (
              <DialogContentText>
                {buildTrackingLink(trackingData.createTracking.tracking.id)}
              </DialogContentText>
            )}
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button
            color="primary"
            disabled={createTrackingLoading}
            type="submit"
            variant="contained"
          >
            Generate Link
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: { paddingBottom: spacing(2) },
}));

export default TrackingGenerator;
