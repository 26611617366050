import { useQuery } from "@apollo/client";
import { Checkbox, TextField } from "@material-ui/core";
import React from "react";
import { useDebouncedCallback } from "use-debounce";

import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import { Autocomplete } from "@material-ui/lab";

import BrandsQuery from "../../queries/BrandsQuery";

import {
  Brands as IBrands,
  Brands_brands_edges_node as IBrandNode
} from "../../generated/Brands";

interface IMultiBrandSearchProps {
  bundledBrands: number[];
  onSelect: (brands: number[]) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InputMultiBrandSearch = ({
  bundledBrands,
  onSelect
}: IMultiBrandSearchProps) => {
  const { data, loading, refetch } = useQuery<IBrands>(BrandsQuery, {
    variables: { limit: 500 }
  });

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value: string) => {
    debouncedRefetch({ search: value || undefined });
  };

  const options = data?.brands?.edges.map(edge => edge.node) || [];

  const defaultBrands = options.filter(option =>
    bundledBrands.includes(option.id)
  );

  return (
    <Autocomplete
      multiple
      loading={loading}
      options={options}
      disableCloseOnSelect
      value={defaultBrands}
      getOptionLabel={option => option.name}
      onInputChange={(_, search) => handleSearch(search)}
      getOptionSelected={(option, value) => option.id === value.id}
      onChange={(event, value: IBrandNode[]) => {
        const brandIds = value.map(entry => {
          return entry.id;
        });

        onSelect(brandIds);
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 1 }}
            checked={selected}
          />
          {option.name}
        </React.Fragment>
      )}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search for brands..."
          fullWidth
        />
      )}
      style={{ paddingTop: 10 }}
    />
  );
};

export default InputMultiBrandSearch;
