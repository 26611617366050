import { useMutation, useQuery } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  makeStyles,
  Select,
  TextField
} from "@material-ui/core";

import { useAuth0 } from "../../utils/auth0Provider";

import { AllowedPaymentMethods as IAllowedPaymentMethods } from "../../generated/AllowedPaymentMethods";
import { PaymentMethod, UserCurrency } from "../../generated/globalTypes";
import {
  UpdatePaymentDetails,
  UpdatePaymentDetailsVariables
} from "../../generated/UpdatePaymentDetails";
import UpdatePaymentDetailsMutation from "../../mutations/UpdatePaymentDetailsMutation";
import AllowedPaymentMethodsQuery from "../../queries/AllowedPaymentMethodsQuery";
import UserQuery from "../../queries/UserQuery";
import getPaymentMethodDisplay from "../../utils/getPaymentMethodDisplay";

import ConfirmationAlert from "../ConfirmationAlert";

interface IPaymentDetailsFormProps {
  bankName?: string;
  bankAccountNumber?: string | null;
  bankAddress1?: string | null;
  bankAddress2?: string | null;
  bankCity?: string | null;
  bankState?: string | null;
  bankCountry?: string | null;
  bankPostalCode?: string | null;
  beneficiaryAddress1?: string | null;
  beneficiaryAddress2?: string | null;
  beneficiaryCity?: string | null;
  beneficiaryName?: string | null;
  beneficiaryState?: string | null;
  beneficiaryCountry?: string | null;
  beneficiaryPostalCode?: string | null;
  branchCode?: string | null;
  companyRegistrationNumber?: string | null;
  currency?: UserCurrency | null;
  ecoPayzEmail?: string | null;
  ibanNumber?: string | null;
  netellerEmail?: string | null;
  netellerId?: string | null;
  netellerName?: string | null;
  onClose: () => void;
  open: boolean;
  payeeName?: string | null;
  paymentMethod?: PaymentMethod | null;
  paypalEmail?: string | null;
  paypalName?: string | null;
  playerAccountHolderName?: string | null;
  playerAccountNumber?: string | null;
  recipientName?: string | null;
  recipientAddress1?: string | null;
  recipientAddress2?: string | null;
  recipientCity?: string | null;
  recipientState?: string | null;
  recipientCountry?: string | null;
  recipientPostalCode?: string | null;
  reference?: string | null;
  routingNumber?: string | null;
  skrillEmail?: string | null;
  skrillAccountHolderName?: string | null;
  skrillAccountNumber?: string | null;
  skrillAddress1?: string | null;
  skrillAddress2?: string | null;
  skrillCity?: string | null;
  skrillState?: string | null;
  skrillCountry?: string | null;
  skrillPostalCode?: string | null;
  sortCode?: string | null;
  swiftBicCode?: string | null;
  taxCountry?: string | null;
  vat?: string | null;
  walletAddress?: string | null;
}

const PaymentDetailsForm = ({
  bankName,
  bankAccountNumber,
  bankAddress1,
  bankAddress2,
  bankCity,
  bankState,
  bankCountry,
  bankPostalCode,
  beneficiaryAddress1,
  beneficiaryAddress2,
  beneficiaryCity,
  beneficiaryName,
  beneficiaryState,
  beneficiaryCountry,
  beneficiaryPostalCode,
  branchCode,
  companyRegistrationNumber,
  currency,
  ecoPayzEmail,
  ibanNumber,
  netellerEmail,
  netellerId,
  netellerName,
  onClose,
  open,
  payeeName,
  paymentMethod,
  paypalEmail,
  paypalName,
  playerAccountHolderName,
  playerAccountNumber,
  recipientName,
  recipientAddress1,
  recipientAddress2,
  recipientCity,
  recipientState,
  recipientCountry,
  recipientPostalCode,
  reference,
  routingNumber,
  skrillEmail,
  skrillAccountHolderName,
  skrillAccountNumber,
  skrillAddress1,
  skrillAddress2,
  skrillCity,
  skrillState,
  skrillCountry,
  skrillPostalCode,
  sortCode,
  swiftBicCode,
  taxCountry,
  vat,
  walletAddress
}: IPaymentDetailsFormProps) => {
  const resetFieldValue = null;
  const emptyFieldValue = undefined;

  const classes = useStyles();

  const { user } = useAuth0();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [payeeNameState, setPayeeName] = useState(payeeName);
  const [
    companyRegistrationNumberState,
    setCompanyRegistrationNumber
  ] = useState(companyRegistrationNumber);

  const [paymentCurrencyState, setPaymentCurrency] = useState<
    UserCurrency | undefined
  >(currency || undefined);
  const [paymentMethodState, setPaymentMethod] = useState(paymentMethod);

  const [accountHolderNameState, setAccountHolderName] = useState(
    beneficiaryName ||
      recipientName ||
      netellerName ||
      playerAccountHolderName ||
      paypalName ||
      skrillAccountHolderName
  );
  const [accountHolderAddress1State, setAccountHolderAddress1] = useState(
    beneficiaryAddress1 || recipientAddress1
  );
  const [accountHolderAddress2State, setAccountHolderAddress2] = useState(
    beneficiaryAddress2 || recipientAddress2
  );
  const [accountHolderCityState, setAccountHolderCity] = useState(
    beneficiaryCity || recipientCity
  );
  const [accountHolderStateState, setAccountHolderState] = useState(
    beneficiaryState || recipientState
  );
  const [accountHolderCountryState, setAccountHolderCountry] = useState(
    beneficiaryCountry || recipientCountry
  );
  const [accountHolderPostalCodeState, setAccountHolderPostalCode] = useState(
    beneficiaryPostalCode || recipientPostalCode
  );

  const [accountEmailState, setAccountEmail] = useState(
    ecoPayzEmail || netellerEmail || paypalEmail || skrillEmail
  );
  const [accountIdState, setAccountId] = useState(bankName || netellerId);
  const [accountNumberState, setAccountNumber] = useState(
    bankAccountNumber ||
      walletAddress ||
      playerAccountNumber ||
      skrillAccountNumber
  );
  const [accountAddress1State, setAccountAddress1] = useState(
    bankAddress1 || skrillAddress1
  );
  const [accountAddress2State, setAccountAddress2] = useState(
    bankAddress2 || skrillAddress2
  );
  const [accountCityState, setAccountCity] = useState(bankCity || skrillCity);
  const [accountStateState, setAccountState] = useState(
    bankState || skrillState
  );
  const [accountCountryState, setAccountCountry] = useState(
    bankCountry || skrillCountry
  );
  const [accountPostalCodeState, setAccountPostalCode] = useState(
    bankPostalCode || skrillPostalCode
  );

  const [routingNumberState, setRoutingNumber] = useState(routingNumber);
  const [branchCodeState, setBranchCode] = useState(branchCode);
  const [sortCodeState, setSortCode] = useState(sortCode);
  const [swiftBicCodeState, setSwiftBicCode] = useState(swiftBicCode);
  const [ibanNumberState, setIbanNumber] = useState(ibanNumber);
  const [referenceState, setReference] = useState(reference);

  const [registeredForTax, setRegisteredForTax] = useState(
    taxCountry || vat ? true : false
  );
  const [taxCountryState, setTaxCounry] = useState(taxCountry);
  const [vatState, setVat] = useState(vat);

  const { data } = useQuery<IAllowedPaymentMethods>(AllowedPaymentMethodsQuery);

  const allowedPaymentMethodsData = data?.allowedPaymentMethods || [];

  const currencyDropdownEntries = Array.from(
    new Set(
      allowedPaymentMethodsData
        .filter(entry => entry.active === true)
        .map(result => result.currency)
        .sort()
    )
  );

  const filteredPaymentMethods = (selectedCurrency: string | undefined) => {
    const filteredMethods = allowedPaymentMethodsData
      .filter(
        allowedMethod =>
          allowedMethod.active === true &&
          allowedMethod.currency === selectedCurrency
      )
      .map(resulted => resulted.paymentMethod);
    return filteredMethods;
  };

  const [updatePaymentDetails, { loading }] = useMutation<
    UpdatePaymentDetails,
    UpdatePaymentDetailsVariables
  >(UpdatePaymentDetailsMutation, { errorPolicy: "all" });

  const initializePaymentFields = () => {
    setAccountHolderName(
      beneficiaryName ||
        recipientName ||
        netellerName ||
        playerAccountHolderName ||
        paypalName ||
        skrillAccountHolderName
    );
    setAccountHolderAddress1(beneficiaryAddress1 || recipientAddress1);
    setAccountHolderAddress2(beneficiaryAddress2 || recipientAddress2);
    setAccountHolderCity(beneficiaryCity || recipientCity);
    setAccountHolderState(beneficiaryState || recipientState);
    setAccountHolderCountry(beneficiaryCountry || recipientCountry);
    setAccountHolderPostalCode(beneficiaryPostalCode || recipientPostalCode);
    setAccountEmail(
      ecoPayzEmail || netellerEmail || paypalEmail || skrillEmail
    );
    setAccountId(bankName || netellerId);
    setAccountNumber(
      bankAccountNumber ||
        walletAddress ||
        playerAccountNumber ||
        skrillAccountNumber
    );
    setAccountAddress1(bankAddress1 || skrillAddress1);
    setAccountAddress2(bankAddress2 || skrillAddress2);
    setAccountCity(bankCity || skrillCity);
    setAccountState(bankState || skrillState);
    setAccountCountry(bankCountry || skrillCountry);
    setAccountPostalCode(bankPostalCode || skrillPostalCode);
    setRoutingNumber(routingNumber);
    setBranchCode(branchCode);
    setCompanyRegistrationNumber(companyRegistrationNumber);
    setSortCode(sortCode);
    setSwiftBicCode(swiftBicCode);
    setIbanNumber(ibanNumber);
  };

  const emptyPaymentFields = () => {
    setAccountHolderName(
      beneficiaryName ||
        recipientName ||
        netellerName ||
        playerAccountHolderName ||
        paypalName ||
        skrillAccountHolderName
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountHolderAddress1(
      beneficiaryAddress1 || recipientAddress1
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountHolderAddress2(
      beneficiaryAddress2 || recipientAddress2
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountHolderCity(
      beneficiaryCity || recipientCity ? resetFieldValue : emptyFieldValue
    );
    setAccountHolderState(
      beneficiaryState || recipientState ? resetFieldValue : emptyFieldValue
    );
    setAccountHolderCountry(
      beneficiaryCountry || recipientCountry ? resetFieldValue : emptyFieldValue
    );
    setAccountHolderPostalCode(
      beneficiaryPostalCode || recipientPostalCode
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountEmail(
      ecoPayzEmail || netellerEmail || paypalEmail || skrillEmail
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountId(bankName || netellerId ? resetFieldValue : emptyFieldValue);
    setAccountNumber(
      bankAccountNumber ||
        walletAddress ||
        playerAccountNumber ||
        skrillAccountNumber
        ? resetFieldValue
        : emptyFieldValue
    );
    setAccountAddress1(
      bankAddress1 || skrillAddress1 ? resetFieldValue : emptyFieldValue
    );
    setAccountAddress2(
      bankAddress2 || skrillAddress2 ? resetFieldValue : emptyFieldValue
    );
    setAccountCity(bankCity || skrillCity ? resetFieldValue : emptyFieldValue);
    setAccountState(
      bankState || skrillState ? resetFieldValue : emptyFieldValue
    );
    setAccountCountry(
      bankCountry || skrillCountry ? resetFieldValue : emptyFieldValue
    );
    setAccountPostalCode(
      bankPostalCode || skrillPostalCode ? resetFieldValue : emptyFieldValue
    );
    setRoutingNumber(routingNumber ? resetFieldValue : emptyFieldValue);
    setBranchCode(branchCode ? resetFieldValue : emptyFieldValue);
    setCompanyRegistrationNumber(
      companyRegistrationNumber ? resetFieldValue : emptyFieldValue
    );
    setSortCode(sortCode ? resetFieldValue : emptyFieldValue);
    setSwiftBicCode(swiftBicCode ? resetFieldValue : emptyFieldValue);
    setIbanNumber(ibanNumber ? resetFieldValue : emptyFieldValue);
  };

  const setInputState = (setFunction: (value: string) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction((event.target.value as UserCurrency) || resetFieldValue);
  };

  const handleSelectPaymentCurrency = (
    selectedPaymentCurrency: UserCurrency
  ) => {
    setDirty(true);
    setPaymentCurrency(selectedPaymentCurrency);
  };

  const handleSelectPaymentMethod = (selectedPaymentMethod: PaymentMethod) => {
    setDirty(true);
    setPaymentMethod(selectedPaymentMethod);
    if (selectedPaymentMethod === paymentMethod) {
      initializePaymentFields();
    } else {
      emptyPaymentFields();
    }
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const onCloseReset = () => {
    setDirty(false);
    initializePaymentFields();
    setPayeeName(payeeName);
    setPaymentMethod(paymentMethod);
    onClose();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    const variables: UpdatePaymentDetailsVariables = {
      accountAddress1: accountAddress1State,
      accountAddress2: accountAddress2State,
      accountCity: accountCityState,
      accountCountry: accountCountryState,
      accountEmail: accountEmailState,
      accountHolderAddress1: accountHolderAddress1State,
      accountHolderAddress2: accountHolderAddress2State,
      accountHolderCity: accountHolderCityState,
      accountHolderCountry: accountHolderCountryState,
      accountHolderName: accountHolderNameState,
      accountHolderPostalCode: accountHolderPostalCodeState,
      accountHolderState: accountHolderStateState,
      accountId: accountIdState,
      accountNumber: accountNumberState,
      accountPostalCode: accountPostalCodeState,
      accountState: accountStateState,
      branchCode: branchCodeState,
      companyRegistrationNumber: companyRegistrationNumberState,
      currency: paymentCurrencyState,
      ibanNumber: ibanNumberState,
      payeeName: payeeNameState!,
      paymentMethod: paymentMethodState!,
      reference: referenceState,
      routingNumber: routingNumberState,
      sortCode: sortCodeState,
      swiftBicCode: swiftBicCodeState,
      taxCountry: registeredForTax ? taxCountryState : null,
      userId: user!.sub,
      vat: registeredForTax ? vatState : null
    };

    updatePaymentDetails({
      awaitRefetchQueries: true,
      refetchQueries: [{ query: UserQuery, variables: { userId: user!.sub } }],
      variables
    }).then(() => {
      setDirty(false);
      onClose();
    });
  };

  const renderPaymentFields = () => {
    return (
      <>
        {(paymentMethodState === PaymentMethod.ECOPAYZ ||
          paymentMethodState === PaymentMethod.NETELLER ||
          paymentMethodState === PaymentMethod.PAYPAL ||
          paymentMethodState === PaymentMethod.SKRILL) && (
          <TextField
            className={classes.formControl}
            id="account-email"
            fullWidth
            label="Email Address"
            onChange={setInputState(setAccountEmail)}
            required
            type="email"
            value={accountEmailState || ""}
          />
        )}

        {(paymentMethodState === PaymentMethod.BANKWIRE ||
          paymentMethodState === PaymentMethod.CHECK ||
          paymentMethodState === PaymentMethod.NETELLER ||
          paymentMethodState === PaymentMethod.PAYPAL ||
          paymentMethodState === PaymentMethod.PLAYERACCOUNT ||
          paymentMethodState === PaymentMethod.SKRILL) && (
          <TextField
            className={classes.formControl}
            id="account-holder-name"
            fullWidth
            label={`${
              paymentMethodState === PaymentMethod.BANKWIRE
                ? "Beneficiary "
                : ""
            }${
              paymentMethodState === PaymentMethod.CHECK ? "Recipient " : ""
            }Name`}
            onChange={setInputState(setAccountHolderName)}
            required={
              paymentMethodState === PaymentMethod.BANKWIRE ||
              paymentMethodState === PaymentMethod.CHECK ||
              paymentMethodState === PaymentMethod.PLAYERACCOUNT
            }
            value={accountHolderNameState || ""}
          />
        )}

        {(paymentMethodState === PaymentMethod.BANKWIRE ||
          paymentMethodState === PaymentMethod.CHECK) && (
          <>
            <TextField
              className={classes.formControl}
              id="account-holder-address-1"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } Address 1`}
              onChange={setInputState(setAccountHolderAddress1)}
              required={paymentMethodState === PaymentMethod.CHECK}
              value={accountHolderAddress1State || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-holder-address-2"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } Address 2`}
              onChange={setInputState(setAccountHolderAddress2)}
              value={accountHolderAddress2State || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-holder-city"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } City`}
              onChange={setInputState(setAccountHolderCity)}
              required={paymentMethodState === PaymentMethod.CHECK}
              value={accountHolderCityState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-holder-state"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } State/Province`}
              onChange={setInputState(setAccountHolderState)}
              value={accountHolderStateState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-holder-country"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } Country`}
              onChange={setInputState(setAccountHolderCountry)}
              required={paymentMethodState === PaymentMethod.CHECK}
              value={accountHolderCountryState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-holder-postal-code"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE
                  ? "Beneficiary"
                  : "Recipient"
              } Postal Code`}
              onChange={setInputState(setAccountHolderPostalCode)}
              required={paymentMethodState === PaymentMethod.CHECK}
              value={accountHolderPostalCodeState || ""}
            />
          </>
        )}

        {(paymentMethodState === PaymentMethod.BANKWIRE ||
          paymentMethodState === PaymentMethod.NETELLER) && (
          <TextField
            className={classes.formControl}
            id="account-id"
            fullWidth
            label={
              paymentMethodState === PaymentMethod.BANKWIRE
                ? "Bank Name"
                : "Neteller ID"
            }
            onChange={setInputState(setAccountId)}
            required={paymentMethodState === PaymentMethod.BANKWIRE}
            value={accountIdState || ""}
          />
        )}

        {(paymentMethodState === PaymentMethod.BANKWIRE ||
          paymentMethodState === PaymentMethod.SKRILL) && (
          <>
            <TextField
              className={classes.formControl}
              id="account-address-1"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }Address 1`}
              onChange={setInputState(setAccountAddress1)}
              required={paymentMethodState === PaymentMethod.BANKWIRE}
              value={accountAddress1State || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-address-2"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }Address 2`}
              onChange={setInputState(setAccountAddress2)}
              value={accountAddress2State || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-city"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }City`}
              onChange={setInputState(setAccountCity)}
              required={paymentMethodState === PaymentMethod.BANKWIRE}
              value={accountCityState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-state"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }State/Province`}
              onChange={setInputState(setAccountState)}
              value={accountStateState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-country"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }Country`}
              onChange={setInputState(setAccountCountry)}
              required={paymentMethodState === PaymentMethod.BANKWIRE}
              value={accountCountryState || ""}
            />

            <TextField
              className={classes.formControl}
              id="account-postal-code"
              fullWidth
              label={`${
                paymentMethodState === PaymentMethod.BANKWIRE ? "Bank " : ""
              }Postal Code`}
              onChange={setInputState(setAccountPostalCode)}
              required={paymentMethodState === PaymentMethod.BANKWIRE}
              value={accountPostalCodeState || ""}
            />
          </>
        )}

        {(paymentMethodState === PaymentMethod.BANKWIRE ||
          paymentMethodState === PaymentMethod.BTCTRANSFER ||
          paymentMethodState === PaymentMethod.PLAYERACCOUNT ||
          paymentMethodState === PaymentMethod.SKRILL) && (
          <TextField
            className={classes.formControl}
            id="account-number"
            fullWidth
            label={`${
              paymentMethodState === PaymentMethod.BTCTRANSFER
                ? "Wallet Address"
                : "Account Number"
            }`}
            onChange={setInputState(setAccountNumber)}
            required={paymentMethodState === PaymentMethod.BTCTRANSFER}
            value={accountNumberState || ""}
          />
        )}

        {paymentMethodState === PaymentMethod.BANKWIRE && (
          <>
            <TextField
              className={classes.formControl}
              id="routing-number"
              fullWidth
              label="Routing Number"
              onChange={setInputState(setRoutingNumber)}
              type="number"
              value={routingNumberState || ""}
            />

            <TextField
              className={classes.formControl}
              id="branch-code"
              fullWidth
              label="Branch Code"
              onChange={setInputState(setBranchCode)}
              value={branchCodeState || ""}
            />

            <TextField
              className={classes.formControl}
              id="sort-code"
              fullWidth
              label="Sort Code"
              onChange={setInputState(setSortCode)}
              value={sortCodeState || ""}
            />

            <TextField
              className={classes.formControl}
              id="swift-bic"
              fullWidth
              label="Swift/BIC"
              onChange={setInputState(setSwiftBicCode)}
              required
              value={swiftBicCodeState || ""}
            />

            <TextField
              className={classes.formControl}
              id="iban-number"
              fullWidth
              label="IBAN"
              onChange={setInputState(setIbanNumber)}
              required
              value={ibanNumberState || ""}
            />

            <TextField
              className={classes.formControl}
              id="reference"
              fullWidth
              label="Reference"
              onChange={setInputState(setReference)}
              value={referenceState || ""}
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Payment Details</DialogTitle>

          <DialogContent>
            <TextField
              autoFocus
              className={classes.formControl}
              id="payeeName"
              fullWidth
              label="Name"
              onChange={setInputState(setPayeeName)}
              required
              value={payeeNameState || ""}
            />

            <TextField
              className={classes.formControl}
              id="company-registration-number"
              fullWidth
              label="Company Registration Number"
              onChange={setInputState(setCompanyRegistrationNumber)}
              value={companyRegistrationNumberState || ""}
              inputProps={{ maxLength: 100 }}
            />

            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel htmlFor="payment-currency">
                Payment Currency
              </InputLabel>
              <Select
                inputProps={{ id: "payment-currency" }}
                native
                onChange={event => {
                  handleSelectPaymentCurrency(
                    event.target.value as UserCurrency
                  );
                }}
                required
                value={paymentCurrencyState || ""}
              >
                <option value="" />

                {currencyDropdownEntries.map((currencyEntry, index) => (
                  <option key={index} value={currencyEntry}>
                    {currencyEntry}
                  </option>
                ))}
              </Select>
            </FormControl>

            {paymentCurrencyState && (
              <FormControl className={classes.formControl} fullWidth required>
                <InputLabel htmlFor="payment-method">Payment Method</InputLabel>
                <Select
                  inputProps={{ id: "payment-method" }}
                  native
                  onChange={event => {
                    handleSelectPaymentMethod(
                      event.target.value as PaymentMethod
                    );
                  }}
                  required
                  value={paymentMethodState || ""}
                >
                  <option value="" />
                  {filteredPaymentMethods(paymentCurrencyState).map(
                    (allowedMethod, index) => (
                      <option key={index} value={allowedMethod}>
                        {getPaymentMethodDisplay(allowedMethod)}
                      </option>
                    )
                  )}
                </Select>
              </FormControl>
            )}

            {paymentMethodState && renderPaymentFields()}

            <FormControlLabel
              control={
                <Checkbox
                  checked={registeredForTax}
                  onChange={() => setRegisteredForTax(!registeredForTax)}
                />
              }
              label="Registered for Tax"
            />

            {registeredForTax && (
              <>
                <TextField
                  className={classes.formControl}
                  fullWidth
                  id="tax-country"
                  label="Tax Country"
                  onChange={setInputState(setTaxCounry)}
                  value={taxCountryState || ""}
                />

                <TextField
                  className={classes.formControl}
                  fullWidth
                  id="vat"
                  label="VAT"
                  onChange={setInputState(setVat)}
                  value={vatState || ""}
                />
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default PaymentDetailsForm;
