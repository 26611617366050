import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  makeStyles,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { CreateCreativeVariables } from "../../generated/CreateCreative";
import { LanguageCode } from "../../generated/globalTypes";
import { UpdateCreativeVariables } from "../../generated/UpdateCreative";
import CreateCreativeMutation from "../../mutations/CreateCreativeMutation";
import UpdateCreativeMutation from "../../mutations/UpdateCreativeMutation";
import { Language } from "../../utils/language";

import { Offer_offer_offer_brand } from "../../generated/Offer";
import { DestinationLinkOffer } from "../../utils/destinationLinkPreview/types";
import ConfirmationAlert from "../ConfirmationAlert";
import DestinationLink from "../DestinationLink";
import Dropzone from "../Dropzone";

interface ICreativeFormProps {
  onClose: () => void;
  open: boolean;
}

interface ICreativeCreateProps extends ICreativeFormProps {
  creativeId?: undefined;
  imageUrl?: undefined;
  languageCode?: undefined;
  name?: undefined;
  offer?: DestinationLinkOffer;
  brand?: Offer_offer_offer_brand;
  offerId: number;
  trackingLink?: undefined;
  trackingLinkAppend?: undefined;
  type: "create";
  visible?: undefined;
}

interface ICreativeUpdateProps extends ICreativeFormProps {
  creativeId: number;
  imageUrl: string | null;
  languageCode: LanguageCode;
  name: string;
  offer: DestinationLinkOffer;
  brand: Offer_offer_offer_brand;
  trackingLink: string | null;
  trackingLinkAppend: string | null;
  type: "update";
  visible: boolean;
}

const CreativeForm = ({
  creativeId,
  imageUrl,
  languageCode,
  name,
  offer,
  brand,
  onClose,
  open,
  trackingLink,
  trackingLinkAppend,
  type,
  visible
}: ICreativeCreateProps | ICreativeUpdateProps) => {
  const emptyStringValue = type === "update" ? null : undefined;
  const classes = useStyles();
  const offerId = offer?.id;
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [imageState, setImage] = useState<string | null>();
  const [languageCodeState, setLanguageCode] = useState(
    languageCode || emptyStringValue
  );
  const [nameState, setName] = useState(name || emptyStringValue);
  const [trackingLinkState, setTrackingLink] = useState(
    trackingLink || emptyStringValue
  );
  const [trackingLinkAppendState, setTrackingLinkAppend] = useState(
    trackingLinkAppend || emptyStringValue
  );
  const [visibleState, setVisible] = useState(visible || false);

  const [upsertBrand, { loading }] = useMutation(
    type === "create" ? CreateCreativeMutation : UpdateCreativeMutation
  );

  useEffect(() => {
    onCloseResetUploads();
    setLanguageCode(languageCode || emptyStringValue);
    setName(name || emptyStringValue);
    setTrackingLink(trackingLink || emptyStringValue);
    setTrackingLinkAppend(trackingLinkAppend || emptyStringValue);
    setVisible(visible || false);
  }, [
    emptyStringValue,
    languageCode,
    name,
    open,
    trackingLink,
    trackingLinkAppend,
    visible
  ]);

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const handleAddImage = (base64?: string) => {
    setDirty(true);
    setImage(base64);
  };

  const handleRemoveImage = () => {
    setDirty(true);
    setImage(null);
  };

  const onCloseReset = () => {
    setDirty(false);
    onClose();
  };

  const onCloseResetUploads = () => {
    setImage(undefined);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (type === "create") {
      const variables: CreateCreativeVariables = {
        image: imageState,
        languageCode: languageCodeState!,
        name: nameState!,
        offerId: offerId!,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState,
        visible: visibleState
      };

      upsertBrand({ refetchQueries: ["Creatives"], variables }).then(() =>
        onCloseReset()
      );
    } else {
      const variables: UpdateCreativeVariables = {
        creativeId: creativeId!,
        image: imageState,
        languageCode: languageCodeState,
        name: nameState,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState,
        visible: visibleState
      };

      upsertBrand({ refetchQueries: ["Creatives"], variables }).then(() =>
        onCloseReset()
      );
    }
  };
  const setInputState = (setFunction: (value?: string | null) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value || emptyStringValue);
  };
  const dialogTitle = type === "create" ? "Create Creative" : `Update ${name}`;
  const dialogText =
    type === "create"
      ? `Add a new creative to an offer. Creatives are advertisement materials with
         a unique click tracking link.`
      : `Editing creative information.`;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <FormControl className={classes.formControl} fullWidth required>
              <InputLabel htmlFor="language-code">Language</InputLabel>
              <Select
                inputProps={{ id: "language-code" }}
                native
                onChange={event =>
                  setLanguageCode(event.target.value as LanguageCode)
                }
                required
                value={languageCodeState}
              >
                {type === "create" && <option value="" />}
                {Object.keys(Language).map(code => (
                  <option key={code} value={code}>
                    {`${(Language as any)[code]} (${code})`}
                  </option>
                ))}
              </Select>
            </FormControl>
            <DestinationLink
              brand={brand}
              offer={offer}
              trackingLink={trackingLink}
              trackingLinkAppend={trackingLinkAppend}
              type={type}
              onCloseReset={onCloseReset}
              trackingLinkState={trackingLinkState}
              trackingLinkAppendState={trackingLinkAppendState}
              setTrackingLink={setTrackingLink}
              setTrackingLinkAppend={setTrackingLinkAppend}
              setInputState={setInputState}
            />
            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="subtitle1">Image</Typography>
              <Dropzone id="image" onDropBase64={handleAddImage} />
              {imageUrl && imageState === undefined && (
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        alt="Creative"
                        height={75}
                        src={imageUrl}
                        width={75}
                      />
                    </ListItemAvatar>

                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={handleRemoveImage}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={visibleState}
                    onChange={() => setVisible(!visibleState)}
                  />
                }
                label="Visible"
              />
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              {type.toUpperCase()}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: { paddingBottom: spacing(2) }
}));

export default CreativeForm;
