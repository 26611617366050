import { useApolloClient, useQuery } from "@apollo/client";
import React, { useCallback, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

import URI from "urijs";

import { makeStyles } from "@material-ui/core";

import { ReportType } from "../../generated/globalTypes";
import { ReportUrl } from "../../generated/ReportUrl";
import PartnerIdQuery from "../../queries/PartnerViewIdQuery";
import ReportFiltersQuery from "../../queries/ReportFiltersQuery";
import ReportUrlQuery from "../../queries/ReportUrlQuery";
import { useAuth0 } from "../../utils/auth0Provider";

import { PartnerViewId as IPartnerViewId } from "../../generated/PartnerViewId";
import { ReportFilters as IReportFilters } from "../../generated/ReportFilters";

const reportMapping: { [key: string]: ReportType } = {
  "admin-campaign": ReportType.ADMIN_CAMPAIGN,
  "admin-click-drilldown": ReportType.ADMIN_CLICK_DRILLDOWN,
  "admin-cohort": ReportType.ADMIN_COHORT,
  "admin-creative": ReportType.ADMIN_CREATIVE,
  "admin-daily": ReportType.ADMIN_DAILY,
  "admin-dashboard": ReportType.ADMIN_DASHBOARD,
  "admin-detailed-breakdown": ReportType.ADMIN_DETAILED_BREAKDOWN,
  "admin-monthly": ReportType.ADMIN_MONTHLY,
  "admin-offer": ReportType.ADMIN_OFFER,
  "admin-partner": ReportType.ADMIN_PARTNER,
  "admin-player": ReportType.ADMIN_PLAYER,
  "admin-subid": ReportType.ADMIN_SUBID,
  "admin-subid-dropdown": ReportType.ADMIN_SUBID_DROPDOWN,

  "partner-click-drilldown": ReportType.PARTNER_CLICK_DRILLDOWN,
  "partner-creative": ReportType.PARTNER_CREATIVE,
  "partner-daily": ReportType.PARTNER_DAILY,
  "partner-dashboard": ReportType.PARTNER_DASHBOARD,
  "partner-offer": ReportType.PARTNER_OFFER,
  "partner-player": ReportType.PARTNER_PLAYER,
  "partner-subid": ReportType.PARTNER_SUBID,
  "partner-subid-dropdown": ReportType.PARTNER_SUBID_DROPDOWN
};

const Report = () => {
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();

  const { data: queryFilters } = useQuery<IReportFilters>(ReportFiltersQuery);

  if (!location.search && queryFilters?.reportFilters) {
    history.replace({ search: queryFilters?.reportFilters || "" });
  }

  const listernerCallback = useCallback(
    (event: MessageEvent) => {
      if (event.data.type === "workbook:loaded") {
        const uri = new URI(window.location.href);
        const filters = uri.query(true);

        if (Object.keys(filters).length > 0) {
          document.querySelector("iframe")?.contentWindow?.postMessage(
            {
              type: "workbook:variables:update",
              variables: filters
            },
            event.origin
          );
        }
      }

      if (event.data.type !== "workbook:variables:onchange") {
        return;
      }

      const listenerUri = new URI(window.location.href);

      const [filterKey, filterValue] = Object.entries(
        event.data.workbook.variables
      ).flat() as string[];

      if (filterValue === "min:,max:" || !filterValue) {
        listenerUri.removeQuery(filterKey);
      } else {
        if (listenerUri.hasQuery(filterKey)) {
          listenerUri.setQuery(filterKey, filterValue);
        } else {
          listenerUri.addQuery(filterKey, filterValue);
        }
      }

      history.replace({ search: decodeURIComponent(listenerUri.query()) });

      client.writeQuery({
        data: {
          reportFilters: decodeURIComponent(listenerUri.query())
        },
        query: ReportFiltersQuery
      });
    },
    [client, history]
  );

  useEffect(() => {
    window.addEventListener("message", listernerCallback);

    return () => window.removeEventListener("message", listernerCallback);
  }, [listernerCallback]);

  const { reportName } = useParams<{ reportName: string }>();

  const classes = useStyles();

  const { user } = useAuth0();

  const { hasPermission } = useAuth0();

  const { data: partner, loading: partnerLoading } = useQuery<IPartnerViewId>(
    PartnerIdQuery
  );

  const defaultDashboard =
    partner?.partnerViewId || !hasPermission("read:reports:all")
      ? "partner-dashboard"
      : "admin-dashboard";

  const { data } = useQuery<ReportUrl>(ReportUrlQuery, {
    fetchPolicy: "network-only",
    skip: partnerLoading,
    variables: {
      currency:
        partner?.partnerViewCurrency ||
        user?.["https://intelitics.com/user_metadata"]?.currency,
      type: reportMapping[reportName || defaultDashboard],
      userId: partner?.partnerViewId
    }
  });

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  });

  return (
    <>
      <div className={classes.container}>
        {data && data.reportUrl && (
          <iframe
            className={classes.iframe}
            frameBorder="0"
            height="100%"
            src={data.reportUrl}
            title="Reporting Dashboard"
            width="100%"
          />
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 64px)"
  },
  iframe: {
    flex: 1
  }
});

export default Report;
