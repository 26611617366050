export enum Language {
  AB = "Abkhazian",
  AA = "Afar",
  AF = "Afrikaans",
  AK = "Akan",
  SQ = "Albanian",
  AM = "Amharic",
  AR = "Arabic",
  AN = "Aragonese",
  HY = "Armenian",
  AS = "Assamese",
  AV = "Avaric",
  AE = "Avestan",
  AY = "Aymara",
  AZ = "Azerbaijani",
  BM = "Bambara",
  BA = "Bashkir",
  EU = "Basque",
  BE = "Belarusian",
  BN = "Bengali",
  BH = "Bihari languages",
  BI = "Bislama",
  BS = "Bosnian",
  BR = "Breton",
  BG = "Bulgarian",
  MY = "Burmese",
  CA = "Catalan",
  CH = "Chamorro",
  CE = "Chechen",
  NY = "Chichewa",
  ZH = "Chinese",
  CV = "Chuvash",
  KW = "Cornish",
  CO = "Corsican",
  CR = "Cree",
  HR = "Croatian",
  CS = "Czech",
  DA = "Danish",
  DV = "Divehi",
  NL = "Dutch",
  DZ = "Dzongkha",
  EN = "English",
  ET = "Estonian",
  EE = "Ewe",
  FO = "Faroese",
  FJ = "Fijian",
  FI = "Finnish",
  FR = "French",
  FF = "Fulah",
  GL = "Galician",
  KA = "Georgian",
  DE = "German",
  EL = "Greek",
  GN = "Guarani",
  GU = "Gujarati",
  HT = "Haitian",
  HA = "Hausa",
  HE = "Hebrew",
  HZ = "Herero",
  HI = "Hindi",
  HO = "Hiri Motu",
  HU = "Hungarian",
  ID = "Indonesian",
  GA = "Irish",
  IG = "Igbo",
  IK = "Inupiaq",
  IS = "Icelandic",
  IT = "Italian",
  IU = "Inuktitut",
  JA = "Japanese",
  JV = "Javanese",
  KL = "Kalaallisut",
  KN = "Kannada",
  KR = "Kanuri",
  KS = "Kashmiri",
  KK = "Kazakh",
  KM = "Central Khmer",
  KI = "Kikuyu",
  RW = "Kinyarwanda",
  KY = "Kirghiz",
  KV = "Komi",
  KG = "Kongo",
  KO = "Korean",
  KU = "Kurdish",
  KJ = "Kuanyama",
  LA = "Latin",
  LB = "Luxembourgish",
  LG = "Ganda",
  LI = "Limburgan",
  LN = "Lingala",
  LO = "Lao",
  LT = "Lithuanian",
  LU = "Luba-Katanga",
  LV = "Latvian",
  GV = "Manx",
  MK = "Macedonian",
  MG = "Malagasy",
  MS = "Malay",
  ML = "Malayalam",
  MT = "Maltese",
  MI = "Maori",
  MR = "Marathi",
  MH = "Marshallese",
  MN = "Mongolian",
  NA = "Nauru",
  NV = "Navajo",
  ND = "North Ndebele",
  NE = "Nepali",
  NG = "Ndonga",
  NB = "Norwegian Bokmål",
  NN = "Norwegian Nynorsk",
  NO = "Norwegian",
  II = "Sichuan Yi",
  NR = "South Ndebele",
  OC = "Occitan",
  OJ = "Ojibwa",
  CU = "Church Slavic",
  OM = "Oromo",
  OR = "Oriya",
  OS = "Ossetian",
  PA = "Punjabi",
  PI = "Pali",
  FA = "Persian",
  PL = "Polish",
  PS = "Pasht",
  PT = "Portuguese",
  QU = "Quechua",
  RM = "Romansh",
  RN = "Rundi",
  RO = "Romanian",
  RU = "Russian",
  SA = "Sanskrit",
  SC = "Sardinian",
  SD = "Sindhi",
  SE = "Northern Sami",
  SM = "Samoan",
  SG = "Sango",
  SR = "Serbian",
  GD = "Gaelic",
  SN = "Shona",
  SI = "Sinhala",
  SK = "Slovak",
  SL = "Slovenian",
  SO = "Somali",
  ST = "Southern Sotho",
  ES = "Spanish",
  SU = "Sundanese",
  SW = "Swahili",
  SS = "Swati",
  SV = "Swedish",
  TA = "Tamil",
  TE = "Telugu",
  TG = "Tajik",
  TH = "Thai",
  TI = "Tigrinya",
  BO = "Tibetan",
  TK = "Turkmen",
  TL = "Tagalog",
  TN = "Tswana",
  TO = "Tonga",
  TR = "Turkish",
  TS = "Tsonga",
  TT = "Tatar",
  TW = "Twi",
  TY = "Tahitian",
  UG = "Uighur",
  UK = "Ukrainian",
  UR = "Urdu",
  UZ = "Uzbek",
  VE = "Venda",
  VI = "Vietnamese",
  WA = "Walloon",
  CY = "Welsh",
  WO = "Wolof",
  FY = "Western Frisian",
  XH = "Xhosa",
  YI = "Yiddish",
  YO = "Yoruba",
  ZA = "Zhuang",
  ZU = "Zulu"
}
