import { gql } from "@apollo/client";

const UpdateOfferMutation = gql`
  mutation UpdateOffer(
    $accountPoolId: Int
    $advertiserAccountRequired: Boolean
    $mobileOptimized: Boolean
    $name: String
    $offerId: Int!
    $overview: String
    $playerBonuses: String
    $terms: String
    $trackingLink: String
    $trackingLinkAppend: String
    $verticals: [VerticalType!]
    $visible: Boolean
  ) {
    updateOffer(
      offerId: $offerId
      input: {
        accountPoolId: $accountPoolId
        advertiserAccountRequired: $advertiserAccountRequired
        mobileOptimized: $mobileOptimized
        name: $name
        overview: $overview
        playerBonuses: $playerBonuses
        terms: $terms
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
        verticals: $verticals
        visible: $visible
      }
    ) {
      error {
        errorType
      }
    }
  }
`;

export default UpdateOfferMutation;
