import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useAuth0 } from "../../utils/auth0Provider";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { Brand_brand_brand_advertiser } from "../../generated/Brand";
import { CreateBrandVariables } from "../../generated/CreateBrand";
import { UpdateBrandVariables } from "../../generated/UpdateBrand";
import CreateBrandMutation from "../../mutations/CreateBrandMutation";
import UpdateBrandMutation from "../../mutations/UpdateBrandMutation";
import AdvertiserQuery from "../../queries/AdvertiserQuery";
import BrandQuery from "../../queries/BrandQuery";
import BrandsQuery from "../../queries/BrandsQuery";

import ConfirmationAlert from "../ConfirmationAlert";
import DestinationLink from "../DestinationLink";
import Dropzone from "../Dropzone";
import InputAdvertiserSearch from "../InputAdvertiserSearch";

interface IBrandFormProps {
  advertiser?: Brand_brand_brand_advertiser;
  onClose: () => void;
  open: boolean;
}

interface IBrandCreateProps extends IBrandFormProps {
  brandId?: undefined;
  logoBanner?: undefined;
  logoIcon?: undefined;
  name?: undefined;
  trackingLink?: undefined;
  trackingLinkAppend?: undefined;
  type: "create";
}

interface IBrandUpdateProps extends IBrandFormProps {
  brandId: number;
  logoBanner: string | null;
  logoIcon: string | null;
  name: string;
  trackingLink: string | null;
  trackingLinkAppend: string | null;
  type: "update";
}

const BrandForm = ({
  advertiser,
  brandId,
  logoBanner,
  logoIcon,
  name,
  onClose,
  open,
  trackingLink,
  trackingLinkAppend,
  type
}: IBrandCreateProps | IBrandUpdateProps) => {
  const bundleTitle =
    "If brands are bundled together, total revshare amounts for each brand are added up together then ringfenced. Any brand that is not included in the bundle will be treated independently and will be ringfenced before added to revshare totals for other brands. This setting can be changed in the Monthly Payments Settings";
  const emptyStringValue = type === "update" ? null : undefined;

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [advertiserIdState, setAdvertiserId] = useState(advertiser?.id);
  const [nameState, setName] = useState(name || emptyStringValue);
  const [trackingLinkState, setTrackingLink] = useState(
    trackingLink || emptyStringValue
  );
  const [trackingLinkAppendState, setTrackingLinkAppend] = useState(
    trackingLinkAppend || emptyStringValue
  );

  const [bundleBrand, setBundleBrand] = useState<boolean>(false);

  const [logoIconState, setLogoIcon] = useState<string | null>();
  const [logoBannerState, setLogoBanner] = useState<string | null>();

  const { hasPermission } = useAuth0();
  const canReadAdvertisers = hasPermission("read:advertisers");

  const [upsertBrand, { loading }] = useMutation(
    type === "create" ? CreateBrandMutation : UpdateBrandMutation
  );

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset();
    }
  };

  const handleAddLogo = (setFunction: (value?: string | null) => void) => (
    base64?: string
  ) => {
    setDirty(true);
    setFunction(base64);
  };

  const handleRemoveLogo = (
    setFunction: (value?: string | null) => void
  ) => () => {
    setDirty(true);
    setFunction(null);
  };

  const onCloseReset = () => {
    onCloseResetUploads();
    setAdvertiserId(advertiser?.id);
    setName(name || emptyStringValue);
    setTrackingLink(trackingLink || emptyStringValue);
    setTrackingLink(trackingLinkAppend || emptyStringValue);
    setDirty(false);
    onClose();
  };

  const onCloseResetUploads = () => {
    setLogoBanner(undefined);
    setLogoIcon(undefined);
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (type === "create") {
      const variables: CreateBrandVariables = {
        bundleBrand,
        logoBanner: logoBannerState,
        logoIcon: logoIconState,
        name: nameState!,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState
      };
      if (advertiserIdState) {
        variables.advertiserId = advertiserIdState;
      }

      const reQuery = canReadAdvertisers
        ? {
            query: AdvertiserQuery,
            variables: { advertiserId: advertiserIdState }
          }
        : "";

      upsertBrand({
        refetchQueries: [
          reQuery,
          {
            query: BrandsQuery
          }
        ],
        variables
      }).then(() => {
        setDirty(false);
        onCloseResetUploads();
        onClose();
      });
    } else {
      const variables: UpdateBrandVariables = {
        brandId: brandId!,
        logoBanner: logoBannerState,
        logoIcon: logoIconState,
        name: nameState,
        trackingLink: trackingLinkState,
        trackingLinkAppend: trackingLinkAppendState
      };

      upsertBrand({
        refetchQueries: [{ query: BrandQuery, variables: { brandId } }],
        variables
      }).then(() => {
        setDirty(false);
        onCloseResetUploads();
        onClose();
      });
    }
  };

  const setInputState = (setFunction: (value?: string | null) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value || emptyStringValue);
  };

  const onSearchSelect = (id: number | undefined) => {
    setDirty(true);
    setAdvertiserId(id);
  };

  const dialogTitle = type === "create" ? "Create Brand" : `Update ${name}`;
  const dialogText =
    type === "create"
      ? `Add a new brand to an advertiser. Brands are an organizational
         category to group offers.`
      : `Editing master information for this brand.`;

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{dialogTitle}</DialogTitle>

          <DialogContent>
            <DialogContentText>{dialogText}</DialogContentText>

            {canReadAdvertisers && (
              <InputAdvertiserSearch
                className={classes.formControl}
                defaultValue={advertiser}
                disabled={!!advertiser}
                label={
                  advertiserIdState ? "Advertiser" : "Search for advertiser..."
                }
                onSelect={onSearchSelect}
              />
            )}

            <TextField
              autoFocus
              className={classes.formControl}
              id="name"
              fullWidth
              label="Name"
              onChange={setInputState(setName)}
              required
              value={nameState || ""}
            />

            <DestinationLink
              trackingLink={trackingLink}
              trackingLinkAppend={trackingLinkAppend}
              type={type}
              trackingLinkState={trackingLinkState}
              trackingLinkAppendState={trackingLinkAppendState}
              setTrackingLink={setTrackingLink}
              setTrackingLinkAppend={setTrackingLinkAppend}
              setInputState={setInputState}
            />

            {type === "create" ? (
              <Tooltip title={bundleTitle} placement="right">
                <FormControlLabel
                  control={<Switch checked={bundleBrand} />}
                  onChange={() => setBundleBrand(!bundleBrand)}
                  label="Add new brand to payments bundle"
                />
              </Tooltip>
            ) : null}

            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="subtitle1">Logo Icon</Typography>
              <Dropzone
                id="logo-icon"
                onDropBase64={handleAddLogo(setLogoIcon)}
              />
              {logoIcon && logoIconState === undefined && (
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        alt="Logo Icon"
                        height={75}
                        src={logoIcon}
                        width={75}
                      />
                    </ListItemAvatar>

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={handleRemoveLogo(setLogoIcon)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}
            </FormControl>

            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="subtitle1">Logo Banner</Typography>
              <Dropzone
                id="logo-banner"
                onDropBase64={handleAddLogo(setLogoBanner)}
              />
              {logoBanner && logoBannerState === undefined && (
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <img
                        alt="Logo banner"
                        height={75}
                        src={logoBanner}
                        width={400}
                      />
                    </ListItemAvatar>

                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={handleRemoveLogo(setLogoBanner)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              )}
            </FormControl>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              color="primary"
              disabled={loading}
              type="submit"
              variant="contained"
            >
              {type.toUpperCase()}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={() => {
          setConfirmOpen(false);
          onCloseReset();
        }}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    paddingBottom: spacing(2)
  }
}));

export default BrandForm;
