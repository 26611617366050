import { PaymentMethod } from "../generated/globalTypes";

const getPaymentMethodDisplay = (paymentMethod: string) => {
  switch (paymentMethod) {
    case PaymentMethod.BANKWIRE:
      return "Bank Wire";
    case PaymentMethod.BTCTRANSFER:
      return "BTC Transfer";
    case PaymentMethod.CHECK:
      return "Check";
    case PaymentMethod.ECOPAYZ:
      return "ecoPayz";
    case PaymentMethod.NETELLER:
      return "Neteller";
    case PaymentMethod.PAYPAL:
      return "Paypal";
    case PaymentMethod.PLAYERACCOUNT:
      return "Player Account";
    case PaymentMethod.SKRILL:
      return "Skrill";
  }
};

export default getPaymentMethodDisplay;
