import { gql } from "@apollo/client";

const UpdateCreativeMutation = gql`
  mutation UpdateCreative(
    $creativeId: Int!
    $image: String
    $languageCode: LanguageCode
    $name: String
    $trackingLink: String
    $trackingLinkAppend: String
    $visible: Boolean
  ) {
    updateCreative(
      creativeId: $creativeId
      input: {
        image: $image
        languageCode: $languageCode
        name: $name
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
        visible: $visible
      }
    ) {
      creative {
        id
      }
    }
  }
`;

export default UpdateCreativeMutation;
