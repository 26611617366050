import { gql } from "@apollo/client";

const SubagreementLogsQuery = gql`
  query SubagreementLogs(
    $after: ID
    $before: ID
    $offerId: Int!
    $userId: ID!
    $limit: Int! = 50
  ) {
    subagreementLogs(
      after: $after
      before: $before
      offerId: $offerId
      userId: $userId
      limit: $limit
    ) {
      edges {
        cursor
        node {
          id
          createdAt
          fromSubagreement {
            id
            networkDetails {
              commissionType
              currency
              ... on AgreementClawback {
                actionType
                cpaAmount
                revsharePercentages
              }
              ... on AgreementCpa {
                actionType
                cpaAmount
              }
              ... on AgreementHybrid {
                actionType
                cpaAmount
                revsharePercentages
              }
              ... on AgreementRevshare {
                revsharePercentages
              }
            }
            affiliateDetails {
              commissionType
              currency
              ... on AgreementClawback {
                actionType
                cpaAmount
                revsharePercentages
              }
              ... on AgreementCpa {
                actionType
                cpaAmount
              }
              ... on AgreementHybrid {
                actionType
                cpaAmount
                revsharePercentages
              }
              ... on AgreementRevshare {
                revsharePercentages
              }
              ... on AgreementSpend {
                spendAccessToken
                spendPercentage
              }
            }
            qualifications {
              id
              amount
              qualificationType
            }
          }
          toSubagreement {
            id
          }
        }
      }
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
    }
  }
`;

export default SubagreementLogsQuery;
