import { useQuery } from "@apollo/client";
import React from "react";

import { LinearProgress, makeStyles, Typography } from "@material-ui/core";

import InstanceSettingsList from "../../components/InstanceSettingsList";
import { AllowedPaymentMethods } from "../../generated/AllowedPaymentMethods";
import { InstanceSettings as IInstanceSettingsQuery } from "../../generated/InstanceSettings";
import AllowedPaymentMethodsQuery from "../../queries/AllowedPaymentMethodsQuery";
import InstanceSettingsQuery from "../../queries/InstanceSettingsQuery";

const InstanceSettingsListContainer = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery<IInstanceSettingsQuery>(
    InstanceSettingsQuery
  );

  const {
    loading: allowedPaymentMethodsLoading,
    error: allowedPaymentMethodsError,
    data: allowedPaymentMethodsData
  } = useQuery<AllowedPaymentMethods>(AllowedPaymentMethodsQuery);

  if (loading || allowedPaymentMethodsLoading) {
    return <LinearProgress />;
  }

  if (
    error ||
    allowedPaymentMethodsError ||
    !data?.instanceSettings ||
    !allowedPaymentMethodsData?.allowedPaymentMethods
  ) {
    return (
      <>
        <div className={classes.errorBar} />
        <Typography
          className={classes.errorText}
          color="error"
          variant="subtitle1"
        >
          Something went wrong; please try again!
        </Typography>
      </>
    );
  }

  return (
    <InstanceSettingsList
      allowedPaymentMethods={allowedPaymentMethodsData.allowedPaymentMethods}
      settings={data.instanceSettings}
    />
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: 4
  },
  errorText: {
    padding: spacing()
  }
}));

export default InstanceSettingsListContainer;
