import { gql } from "@apollo/client";

const LockMonthMutation = gql`
  mutation LockMonthlyPayments($month: DateTime!) {
    lockMonthlyPayments(month: $month) {
      data {
        status
      }
      error {
        errorType
        message
      }
    }
  }
`;
export default LockMonthMutation;
