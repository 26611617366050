import { gql } from "@apollo/client";

const RemoveTransactionMutation = gql`
  mutation RemoveTransaction($transactionId: String!) {
    removeTransaction(transactionId: $transactionId) {
      error {
        errorType
        message
      }
      transaction {
        id
        removed
      }
    }
  }
`;

export default RemoveTransactionMutation;
