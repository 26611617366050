import { gql } from "@apollo/client";

const UpdateInstanceSettingsMutation = gql`
  mutation UpdateInstanceSettings(
    $displayName: String
    $favicon: String
    $logo: String
    $paymentCurrency: Currency
    $themePrimaryColor: String
    $themePrimaryTextColor: String
    $themeSecondaryColor: String
    $themeSecondaryTextColor: String
    $title: String
  ) {
    updateInstanceSettings(
      input: {
        displayName: $displayName
        favicon: $favicon
        logo: $logo
        paymentCurrency: $paymentCurrency
        themePrimaryColor: $themePrimaryColor
        themePrimaryTextColor: $themePrimaryTextColor
        themeSecondaryColor: $themeSecondaryColor
        themeSecondaryTextColor: $themeSecondaryTextColor
        title: $title
      }
    ) {
      displayName
      favicon
      logo
      ngrFormula
      paymentCurrency
      themePrimaryColor
      themePrimaryTextColor
      themeSecondaryColor
      themeSecondaryTextColor
      title
    }
  }
`;

export default UpdateInstanceSettingsMutation;
