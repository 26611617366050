import { useMutation } from "@apollo/client";
import React, { ChangeEvent, FormEvent, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Tooltip
} from "@material-ui/core";

import { AccountPool_accountPool_accountPool_variables } from "../../generated/AccountPool";
import { Accounts_accounts_edges_node_variables } from "../../generated/Accounts";
import {
  CreateAccount,
  CreateAccountVariables
} from "../../generated/CreateAccount";
import { CreateAccountVariableInput } from "../../generated/globalTypes";
import {
  UpdateAccount,
  UpdateAccountVariables
} from "../../generated/UpdateAccount";
import CreateAccountMutation from "../../mutations/CreateAccountMutation";
import UpdateAccountMutation from "../../mutations/UpdateAccountMutation";
import AccountsQuery from "../../queries/AccountsQuery";

import ConfirmationAlert from "../ConfirmationAlert";
import InputUserSearch from "../InputUserSearch";

interface IAccountVariable
  extends AccountPool_accountPool_accountPool_variables {
  value?: string;
}

export interface IAccountFormProps {
  accountId?: number;
  accountPoolId: number;
  accountPoolName: string;
  accountPoolVariables: AccountPool_accountPool_accountPool_variables[];
  accountVariables?: Accounts_accounts_edges_node_variables[];
  onClose: () => void;
  open: boolean;
  staticUser?: { displayId: number | null; id: string; name: string };
  type: "create" | "update";
  username?: string;
}

const AccountForm = ({
  accountId,
  accountPoolId,
  accountPoolName,
  accountPoolVariables,
  accountVariables,
  onClose,
  open,
  staticUser,
  type,
  username
}: IAccountFormProps) => {
  const emptyStringValue = "";
  const initialVariables = accountPoolVariables.map(accountPoolVariable => {
    if (type === "update") {
      const existingVariable = accountVariables?.find(
        accountVariable => accountVariable.name === accountPoolVariable.name
      );

      if (existingVariable) {
        return {
          ...accountPoolVariable,
          value: existingVariable.value
        };
      }
    }

    return {
      ...accountPoolVariable,
      value: undefined
    };
  });

  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [userIdState, setUserId] = useState<string>();
  const [usernameState, setUsername] = useState<string>(
    username || emptyStringValue
  );
  const [variablesState, setVariables] = useState<IAccountVariable[]>(
    initialVariables
  );

  const [upsertAccount, { loading }] = useMutation<
    CreateAccount | UpdateAccount,
    CreateAccountVariables | UpdateAccountVariables
  >(type === "create" ? CreateAccountMutation : UpdateAccountMutation);

  const setInputState = (setFunction: (value: string) => void) => (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setDirty(true);
    setFunction(event.target.value);
  };

  const handleVariableChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDirty(true);
    const variableIndex = parseInt(event.target.id.split("-")[0], 10);
    const newVariables = variablesState!.map(variable => ({ ...variable }));
    newVariables[variableIndex].value = event.target.value;
    setVariables(newVariables);
  };

  const handleClose = () => {
    if (dirty) {
      setConfirmOpen(true);
    } else {
      onCloseReset(true);
    }
  };

  const handleDiscard = () => {
    setConfirmOpen(false);
    onCloseReset(true);
  };

  const onCloseReset = (updated: boolean) => {
    setDirty(false);
    setUserId(emptyStringValue);
    setUsername(username || emptyStringValue);
    onClose();
    if (updated) {
      setVariables(initialVariables);
    }
  };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    let variables: CreateAccountVariables | UpdateAccountVariables;

    if (type === "create") {
      variables = {
        accountPoolId,
        userId: userIdState!,
        username: usernameState,
        variables: variablesState!
          .filter(variable => variable.value)
          .map(
            ({ __typename, description, ...nameAndValue }) => nameAndValue
          ) as CreateAccountVariableInput[]
      };
    } else {
      variables = {
        accountId: accountId!,
        username: usernameState,
        variables: variablesState!
          .filter(variable => variable.value)
          .map(
            ({ __typename, description, ...nameAndValue }) => nameAndValue
          ) as CreateAccountVariableInput[]
      };
    }

    upsertAccount({
      refetchQueries: [{ query: AccountsQuery, variables: { accountPoolId } }],
      variables
    }).then(() => onCloseReset(false));
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <form onSubmit={onSubmit}>
          <DialogTitle>{`${
            type === "create" ? "Create" : "Update"
          } account for ${accountPoolName}`}</DialogTitle>

          <DialogContent>
            <InputUserSearch
              className={classes.formControl}
              onSelect={setUserId}
              staticUser={staticUser}
              required
            />

            <TextField
              className={classes.formControl}
              id="username"
              fullWidth
              label="User Name"
              onChange={setInputState(setUsername)}
              required
              value={usernameState || ""}
            />

            {variablesState.map((variable, index) => (
              <Tooltip
                arrow
                key={`${index}-${variable.name}`}
                title={variable.description || "no description"}
              >
                <TextField
                  className={classes.formControl}
                  id={`${index}-${variable.name}`}
                  fullWidth
                  label={variable.name}
                  onChange={handleVariableChange}
                  value={variable.value || ""}
                />
              </Tooltip>
            ))}
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              disabled={loading}
              color="primary"
              type="submit"
              variant="contained"
            >
              {type === "create" ? "Create" : "Update"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationAlert
        content="Closing this form will lose any unsaved progress."
        onNegative={() => setConfirmOpen(false)}
        onPositive={handleDiscard}
        open={confirmOpen}
        positiveAction="Discard"
        title="Unsaved Changes"
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  formControl: {
    marginBottom: spacing(2)
  }
}));

export default AccountForm;
