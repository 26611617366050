import { gql } from "@apollo/client";

const UpdateAccountMutation = gql`
  mutation UpdateAccount(
    $accountId: Int!
    $username: String
    $variables: [CreateAccountVariableInput!]
  ) {
    updateAccount(
      accountId: $accountId
      input: { username: $username, variables: $variables }
    ) {
      account {
        id
        username
        variables {
          name
          value
        }
      }
      error {
        errorType
      }
    }
  }
`;

export default UpdateAccountMutation;
