import React, { ReactNode } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";

interface IConfirmationAlertProps {
  content: string;
  children?: ReactNode;
  disabled?: boolean;
  onExited?: () => void;
  onNegative?: () => void;
  onPositive: () => void;
  open: boolean;
  positiveAction: string;
  title: string;
}

const ConfirmationAlert = ({
  content,
  children,
  disabled,
  onExited,
  onNegative,
  onPositive,
  open,
  positiveAction,
  title
}: IConfirmationAlertProps) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onNegative} onExited={onExited} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.dialogContent}>
          {content}
        </DialogContentText>
        {children}
      </DialogContent>

      <DialogActions>
        {onNegative && (
          <Button disabled={disabled} onClick={onNegative}>
            Cancel
          </Button>
        )}
        <Button color="secondary" disabled={disabled} onClick={onPositive}>
          {positiveAction}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialogContent: {
    wordWrap: "break-word"
  }
}));

export default ConfirmationAlert;
