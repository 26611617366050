import { useQuery } from "@apollo/client";
import React, { ChangeEvent, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  LinearProgress,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";

import { Click as IClickQuery } from "../../generated/Click";
import ClickQuery from "../../queries/ClickQuery";

const ConversionManagement = () => {
  const classes = useStyles();

  const history = useHistory();

  const [clickId, setClickId] = useState<string>("");

  const [invalidId, setInvalidId] = useState<boolean>(false);

  const [getQuery, setGetQuery] = useState<boolean>(false);

  const onChangeSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setClickId(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!clickId) {
      return;
    }
    setGetQuery(true);
  };

  const { loading } = useQuery<IClickQuery>(ClickQuery, {
    onCompleted: () => history.push(`/tech/conversion-management/${clickId}`),
    onError: () => {
      setInvalidId(true);
      setGetQuery(false);
    },
    skip: !getQuery,
    variables: { clickId }
  });

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <Typography variant="h5">Conversion Management</Typography>
      </div>
      <form
        className={classes.searchContainer}
        noValidate
        onSubmit={handleSubmit}
      >
        <TextField
          className={classes.searchInput}
          error={invalidId}
          helperText={
            invalidId ? "Invalid click ID" : "Please enter a click ID"
          }
          id="click-search"
          label="Click Id#"
          onChange={onChangeSearch}
          type="search"
          value={clickId}
          variant="outlined"
        />
        <Button
          className={classes.searchButton}
          color="primary"
          type="submit"
          variant="contained"
        >
          Get Conversions
        </Button>
      </form>
    </Paper>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paper: {
    minHeight: "100%",
    padding: spacing(3, 3, 0, 3)
  },
  searchButton: {
    marginTop: spacing(),
    maxWidth: "fit-content"
  },
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: spacing(3)
  },
  searchInput: {
    maxWidth: 375,
    paddingBottom: spacing()
  }
}));

export default ConversionManagement;
