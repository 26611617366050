import React, { useEffect } from "react";

import { useAuth0 } from "../../utils/auth0Provider";

import { Redirect, useLocation } from "react-router-dom";

const Auth0Redirect = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({
        action: location.pathname === "/signup" ? "signUp" : "login"
      });
    }
  }, [isAuthenticated, location.pathname, loginWithRedirect]);

  return isAuthenticated ? <Redirect to="/dashboard" /> : <div />;
};

export default Auth0Redirect;
