import { gql } from "@apollo/client";

const UpdateBrandMutation = gql`
  mutation UpdateBrand(
    $brandId: Int!
    $logoBanner: String
    $logoIcon: String
    $name: String
    $trackingLink: String
    $trackingLinkAppend: String
  ) {
    updateBrand(
      brandId: $brandId
      input: {
        logoBanner: $logoBanner
        logoIcon: $logoIcon
        name: $name
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
      }
    ) {
      error {
        errorType
      }
    }
  }
`;

export default UpdateBrandMutation;
