import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import { useAuth0 } from "../../utils/auth0Provider";

import { Brands_brands_edges_node } from "../../generated/Brands";
import BrandForm from "../BrandForm";
import BrandListItem from "../BrandListItem";

export interface IBrandListProps {
  brands: Brands_brands_edges_node[];
  handleSearch: (value?: string) => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  onNextPage: () => void;
  onPreviousPage: () => void;
}

const BrandList = ({
  brands,
  handleSearch,
  hasNextPage,
  hasPreviousPage,
  onNextPage,
  onPreviousPage
}: IBrandListProps) => {
  const classes = useStyles();

  const { hasPermission } = useAuth0();
  const createBrands = hasPermission("manage:brands");

  const [brandFormOpen, setBrandFormOpen] = useState(false);
  const [brandSearch, setBrandSearch] = useState("");

  const handleBrandSearch = (value: string) => {
    setBrandSearch(value);
    handleSearch(value || undefined);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography variant="h5">Brands</Typography>

          <TextField
            id="brands-search"
            onChange={event => handleBrandSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            value={brandSearch}
          />

          {createBrands && (
            <Button
              color="primary"
              onClick={() => setBrandFormOpen(true)}
              variant="contained"
            >
              <AddIcon className={classes.buttonIcon} />
              Brand
            </Button>
          )}
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellId}>ID</TableCell>
              <TableCell className={classes.cellLogo} />
              <TableCell>Name</TableCell>
              {createBrands && <TableCell>Advertiser</TableCell>}
              <TableCell className={classes.cellAction}>Active</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {brands.map(brand => (
              <BrandListItem key={brand.id} {...brand} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell className={classes.paginationTableCell} colSpan={1000}>
                <Toolbar className={classes.toolbar}>
                  <ButtonGroup>
                    <Button
                      disabled={!hasPreviousPage}
                      onClick={onPreviousPage}
                    >
                      {"<"}
                    </Button>
                    <Button disabled={!hasNextPage} onClick={onNextPage}>
                      {">"}
                    </Button>
                  </ButtonGroup>
                </Toolbar>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>

      {createBrands && (
        <BrandForm
          onClose={() => setBrandFormOpen(false)}
          open={brandFormOpen}
          type="create"
        />
      )}
    </>
  );
};

const useStyles = makeStyles(({ spacing }) => ({
  buttonIcon: {
    marginRight: spacing()
  },
  cellAction: { width: "5%" },
  cellId: {
    textAlign: "right",
    width: "5%"
  },
  cellLogo: {
    textAlign: "center",
    width: "10%"
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing()
  },
  paginationTableCell: { padding: 0 },
  paper: {
    padding: spacing(3),
    paddingBottom: 0
  },
  toolbar: {
    justifyContent: "flex-end",
    minHeight: 52
  }
}));

export default BrandList;
