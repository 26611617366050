import { gql } from "@apollo/client";

const AccountPoolQuery = gql`
  query AccountPool($accountPoolId: Int!) {
    accountPool(accountPoolId: $accountPoolId) {
      accountPool {
        description
        id
        name
        variables {
          description
          name
        }
      }
      error {
        errorType
      }
    }
  }
`;

export default AccountPoolQuery;
