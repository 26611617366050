import clsx from "clsx";
import React, { useState } from "react";

import { Card, CardContent, Fade, makeStyles } from "@material-ui/core";

import logo1 from "./logo1.png";
import logo2 from "./logo2.png";

const Layout = () => {
  const classes = useStyles();

  const [logoLoaded, setLogoLoaded] = useState(false);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <Fade in={logoLoaded} timeout={500}>
            <div className={classes.loadingBarContainer}>
              <div className={clsx(classes.loadingBar, classes.loadingBar1)} />
              <div className={clsx(classes.loadingBar, classes.loadingBar2)} />
              <div className={clsx(classes.loadingBar, classes.loadingBar3)} />
              <img
                alt=""
                className={classes.logo1}
                height={45}
                src={logo1}
                width={10}
              />
              <img
                alt="Intelitics"
                className={classes.logo2}
                height={48}
                onLoad={() => setLogoLoaded(true)}
                src={logo2}
                width={231}
              />
            </div>
          </Fade>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles(({ palette }) => ({
  "@keyframes loadingBar1": {
    from: { height: 15 },
    to: { height: 20 }
  },
  "@keyframes loadingBar2": {
    from: { height: 30 },
    to: { height: 36 }
  },
  "@keyframes loadingBar3": {
    from: { height: 25 },
    to: { height: 30 }
  },
  card: {
    backgroundColor: palette.grey[800]
  },
  container: {
    alignItems: "center",
    backgroundColor: palette.grey[900],
    display: "flex",
    flex: 1,
    justifyContent: "center",
    minHeight: "100vh"
  },
  loadingBar: {
    animationDirection: "alternate",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
    backgroundColor: "#5DB37E",
    borderRadius: 3,
    margin: "1px 2px",
    width: 10
  },
  loadingBar1: {
    animationDuration: ".65s",
    animationName: "$loadingBar1"
  },
  loadingBar2: {
    animationDuration: ".7s",
    animationName: "$loadingBar2"
  },
  loadingBar3: {
    animationDuration: ".8s",
    animationName: "$loadingBar3"
  },
  loadingBarContainer: {
    alignItems: "flex-end",
    display: "flex",
    height: 50
  },
  logo1: {
    margin: "1px 2px"
  },
  logo2: {
    marginLeft: 4
  }
}));

export default Layout;
