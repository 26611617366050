import { gql } from "@apollo/client";

const BrandQuery = gql`
  query Brand($brandId: Int!) {
    brand(brandId: $brandId) {
      brand {
        active
        advertiser {
          id
          name
        }
        id
        logoBanner
        logoIcon
        name
        trackingLink
        trackingLinkAppend
      }
      error {
        errorType
      }
    }
  }
`;

export default BrandQuery;
