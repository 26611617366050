import { gql } from "@apollo/client";

const CreateAccountMutation = gql`
  mutation CreateAccount(
    $accountPoolId: Int!
    $userId: ID!
    $username: String!
    $variables: [CreateAccountVariableInput!]!
  ) {
    createAccount(
      input: {
        accountPoolId: $accountPoolId
        userId: $userId
        username: $username
        variables: $variables
      }
    ) {
      account {
        id
        username
        variables {
          name
          value
        }
      }
    }
  }
`;

export default CreateAccountMutation;
