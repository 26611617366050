import { useQuery } from "@apollo/client";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce/lib";

import { LinearProgress, makeStyles } from "@material-ui/core";

import { Brands as IBrandsQuery, Brands_brands } from "../../generated/Brands";
import BrandsQuery from "../../queries/BrandsQuery";

import BrandList from "../../components/BrandList";

const BrandListContainer = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(useLocation().search);

  const classes = useStyles();

  const after = queryParams.get("after") || undefined;
  const before = queryParams.get("before") || undefined;

  const { loading, error, data, refetch } = useQuery<IBrandsQuery>(
    BrandsQuery,
    {
      variables: { after, before }
    }
  );

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value?: string) => {
    debouncedRefetch({ after, before, search: value });
  };

  const brandsData = (() => {
    if (data && data.brands) {
      return data.brands;
    } else {
      const defaultData: Brands_brands = {
        __typename: "BrandsConnection",
        edges: [],
        pageInfo: {
          __typename: "PageInfo",
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null
        }
      };
      return defaultData;
    }
  })();

  const onNextPage = () => {
    const newAfter = brandsData.pageInfo.endCursor || undefined;

    if (newAfter) {
      history.push({
        pathname: history.location.pathname,
        search: `?after=${newAfter}`
      });
    }
  };

  const onPreviousPage = () => {
    const newBefore = brandsData.pageInfo.startCursor || undefined;

    if (newBefore) {
      history.push({
        pathname: history.location.pathname,
        search: `?before=${newBefore}`
      });
    }
  };

  return (
    <>
      {loading && <LinearProgress />}
      {error && <div className={classes.errorBar} />}
      <BrandList
        brands={brandsData.edges.map(edge => edge.node)}
        handleSearch={handleSearch}
        hasNextPage={brandsData.pageInfo.hasNextPage}
        hasPreviousPage={brandsData.pageInfo.hasPreviousPage}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
      />
    </>
  );
};

const useStyles = makeStyles(({ palette, spacing }) => ({
  errorBar: {
    backgroundColor: palette.error.main,
    height: spacing()
  }
}));

export default BrandListContainer;
