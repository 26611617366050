import { gql } from "@apollo/client";

const CreateOfferMutation = gql`
  mutation CreateOffer(
    $accountPoolId: Int
    $advertiserAccountRequired: Boolean! = false
    $brandId: Int!
    $mobileOptimized: Boolean! = false
    $name: String!
    $overview: String
    $playerBonuses: String
    $terms: String
    $trackingLink: String
    $trackingLinkAppend: String
    $verticals: [VerticalType!]
    $visible: Boolean! = false
  ) {
    createOffer(
      input: {
        accountPoolId: $accountPoolId
        advertiserAccountRequired: $advertiserAccountRequired
        brandId: $brandId
        mobileOptimized: $mobileOptimized
        name: $name
        overview: $overview
        playerBonuses: $playerBonuses
        terms: $terms
        trackingLink: $trackingLink
        trackingLinkAppend: $trackingLinkAppend
        verticals: $verticals
        visible: $visible
      }
    ) {
      offer {
        id
      }
    }
  }
`;

export default CreateOfferMutation;
