import { gql } from "@apollo/client";

const CloseMonthMutation = gql`
  mutation CloseMonthlyPayments($month: DateTime!) {
    closeMonthlyPayments(month: $month) {
      data {
        status
      }
      error {
        errorType
        message
      }
    }
  }
`;
export default CloseMonthMutation;
