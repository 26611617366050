import { gql } from "@apollo/client";

const PartnerViewIdQuery = gql`
  query PartnerViewId {
    partnerViewId @client
    partnerViewCurrency @client
  }
`;

export default PartnerViewIdQuery;
