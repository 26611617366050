import { gql } from "@apollo/client";

const MarkPaymentsPendingMutation = gql`
  mutation markPaymentsPending($input: [MonthlyPaymentTotalsPartnerIdMonth!]!) {
    markPaymentsPending(input: $input) {
      error {
        errorType
        message
      }
    }
  }
`;

export default MarkPaymentsPendingMutation;
