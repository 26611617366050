import { gql } from "@apollo/client";

const CreateUserMutation = gql`
  mutation CreateUser(
    $company: String
    $currency: UserCurrency
    $email: String!
    $name: String!
    $phoneNumber: String
    $roleId: String!
  ) {
    createUser(
      input: {
        company: $company
        currency: $currency
        email: $email
        name: $name
        phoneNumber: $phoneNumber
        roleId: $roleId
      }
    ) {
      user {
        name
        email
        company
        phoneNumber
        currency
        inviteUrl
        roles {
          name
        }
      }
    }
  }
`;

export default CreateUserMutation;
