import { useQuery } from "@apollo/client";
import React from "react";
import { useDebouncedCallback } from "use-debounce";

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { Users, Users_users_users } from "../../generated/Users";
import UsersQuery from "../../queries/UsersQuery";

interface IInputUserSearch {
  onSelect: (userId?: string) => void;
  staticUser?: {
    id: string;
    displayId: number | null;
    name: string;
  };
  className?: string;
  required?: boolean;
}

const InputUserSearch = ({
  onSelect,
  staticUser,
  className,
  required = false
}: IInputUserSearch) => {
  const { data, loading, refetch } = useQuery<Users>(UsersQuery, {
    skip: !!staticUser
  });

  const [debouncedRefetch] = useDebouncedCallback(refetch, 300);

  const handleSearch = (value: string) => {
    if (staticUser) {
      return;
    }
    debouncedRefetch({
      search: value || undefined
    });
  };

  const handleSelect = (user?: Users_users_users) => {
    if (user) {
      onSelect(user.id);
    } else {
      onSelect(undefined);
      handleSearch("");
    }
  };

  const options = data && data.users ? data.users.users : [];

  return (
    <Autocomplete
      className={className}
      disabled={!!staticUser}
      defaultValue={staticUser as Users_users_users}
      getOptionLabel={option => option.name}
      loading={loading}
      onChange={(_, user) => handleSelect(user ? user : undefined)}
      onInputChange={(_, search) => handleSearch(search)}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={staticUser ? "" : "Search for partner..."}
          required={required}
        />
      )}
      renderOption={option => (
        <span>{`${option.name} (${option.displayId})`}</span>
      )}
    />
  );
};

export default InputUserSearch;
